export const IMAGE_PATH = "/assets/images";
export const NO_IMG_AVAILABLE = `${IMAGE_PATH}/no-img-available.png`;
export const ERROR_PAGE_LOGO = `${IMAGE_PATH}/error-page-logo.svg`;
export const PAGE_NOT_AVAILABLE_LOGO = `${IMAGE_PATH}/page-not-available.svg`;
export const NO_IMAGE = `${IMAGE_PATH}/no_image.svg`;
export const SEARCH_NOT_FOUND = `${IMAGE_PATH}/search-not-found.webp`;
export const ERROR_DEVICE_NOT_SUPPORT = `${IMAGE_PATH}/error-cases-device-not-support.webp`;
export const DEEPLE_PAY_LOADING = `${IMAGE_PATH}/deeple-pay-loading.gif`;
export const PAYMENT_SUCCESS = "https://storage.googleapis.com/kanda-ai-core/static/status/success.gif";
export const THAI_QR_PAYMENT_LOGO = `${IMAGE_PATH}/thai-qr-payment.svg`;
export const PHOTO = `${IMAGE_PATH}/photo.png`;
export const MOBILE_BANKING_SUCCESS = `${IMAGE_PATH}/mobile-banking-success.svg`;
export const DEEPLE_PAY = `${IMAGE_PATH}/deeple-pay.svg`;
export const SUPPORT_MOBILE_DEVICE = `${IMAGE_PATH}/support-mobile-devices.svg`;
export const CHECK_IMAGE = `${IMAGE_PATH}/check.svg`;
export const SUCCESS = `${IMAGE_PATH}/success.gif`;
export const FAIL = `${IMAGE_PATH}/fail.gif`;
export const ERROR_ORDER = `${IMAGE_PATH}/error-order.gif`;
