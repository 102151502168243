import { FC, useState, useEffect } from "react";

import Icon from "components/Icon";

import { IconName, IconSize } from "types/icon";

import { SearchBox, IconWrapper } from "./styled";

import { COLORS } from "constants/color";

type SearchInputPropTypes = {
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
  onClear?: () => void;
  autoFocus?: boolean;
  alwayShowClear?: boolean;
};

const SearchInput: FC<SearchInputPropTypes> = ({
  onChange,
  value = "",
  placeholder,
  onClear,
  autoFocus,
  alwayShowClear,
}) => {
  const [text, setText] = useState(value);
  const handleChange = (value: string) => {
    setText(value);
    onChange(value);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    handleChange("");
  };
  const showClearIcon = alwayShowClear || text;

  useEffect(() => {
    if (autoFocus) {
      const inputElement = document.getElementById("input");
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [autoFocus]);

  return (
    <SearchBox>
      <IconWrapper>
        <Icon name={IconName.SEARCH} size={IconSize.SMALL} />
      </IconWrapper>
      <input
        id="input"
        value={text}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e.target.value)}
        placeholder={placeholder}
      />
      {showClearIcon && (
        <IconWrapper onClick={handleClear}>
          <Icon name={IconName.CLEAR} size={IconSize.MEDIUM} color={COLORS.Gallery} />
        </IconWrapper>
      )}
    </SearchBox>
  );
};

SearchInput.displayName = "SearchInput";

export default SearchInput;
