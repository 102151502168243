import { AuthPlatform } from "types";

export interface ProviderAuthenticationInfo {
  header: CustomHeader;
  platform: AuthPlatform;
  token: string;
}

export enum CustomHeader {
  DEEPLE_TOKEN = "authorization",
  FACEBOOK_TOKEN = "x-facebook-token",
  LINE_TOKEN = "x-line-token",
  PROJECT_ID = "x-project-id",
}

export interface DefaultQueryString {
  readonly platform?: string;
  readonly projectId?: string;
  readonly token?: string;
}

export interface LocationQueryString extends DefaultQueryString {
  readonly "liff.state"?: string;
}
