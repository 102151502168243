import common from "./common/en";
import product from "./product/en";
import cart from "./cart/en";
import category from "./category/en";
import home from "./home/en";
import promotion from "./promotion/en";
import topSelling from "./top-selling/en";
import error from "./error/en";
import addCreditCard from "./add-credit-card/en";
import payment from "./payment/en";

const en = {
  home,
  common,
  product,
  cart,
  category,
  promotion,
  topSelling,
  error,
  addCreditCard,
  payment,
};

export default en;
