const error = {
  description: {
    default: "ไม่พบหน้าที่คุณค้นหา",
    badRequest: "รองรับการใช้บริการบนสมาร์ทโฟนหรือแท็บเล็ตเท่านั้น",
    notFound: "ไม่พบหน้าที่คุณต้องการ",
  },
  subDescription: {
    pleaseContactAdmin: "หากต้องการสั่งซื้อสินค้า กรุณาติดต่อแอดมิน",
  },
  button: {
    back: {
      label: "กลับ",
    },
    retry: {
      label: "ลองใหม่",
    },
  },
};

export default error;
