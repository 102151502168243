import i18next from "translations/i18n";

import { Language } from "types/language";

export const setLanguage = (language?: Language | null) => {
  const defaultLanguage = Language.TH;
  i18next.changeLanguage(language || defaultLanguage);
};

export const getDefaultLanguage = () => i18next.language;
