import styled from "styled-components";

import DefualtTypography from "components/Typography";

interface StickyWrapperPropsType {
  show: boolean;
  offsetTop?: number | null;
}

export const HeaderWrapper = styled.div<{ shadow: boolean }>`
  position: sticky;
  width: 100vw;
  height: fit-content;
  z-index: 100;
  box-shadow: ${({ shadow, theme }) => (shadow ? theme.COLORS.DeepleBoxShadow : "")};
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
`;

export const Typography = styled(DefualtTypography)`
  margin: 12px 0;
`;

export const StickyWrapper = styled.div<StickyWrapperPropsType>`
  position: fixed;
  padding: 0 16px;
  width: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.COLORS.LightWhite};
  z-index: 2;
  top: ${({ offsetTop }) => (offsetTop ? `${offsetTop}px` : 0)};
  height: auto;
  transform: ${({ show }) => (show ? "none" : "translateY(-50px)")};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  transform-origin: top;
  transition: transform 0.2s ease-in-out;
  scroll-behavior: smooth;
`;

export const SearchWrapper = styled.div`
  position: sticky;
  top: 0;
  padding: 16px;
  z-index: 10;
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
`;
