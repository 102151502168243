import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const InvertTrashIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 100 100", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        className="cls-1"
        d="M45.8,8.9H34.06A9.15,9.15,0,0,0,25,0H22.27a8.43,8.43,0,0,0-8.35,8.49A1.51,1.51,0,0,0,14,8.9H2.46a2.31,2.31,0,1,0-.26,4.61H3v23A11.93,11.93,0,0,0,15.23,48h17.3A11.93,11.93,0,0,0,44.81,36.46v-23h.73A2.31,2.31,0,1,0,45.8,8.9Zm-27-.41h0a3.68,3.68,0,0,1,3.44-3.88H25A4.36,4.36,0,0,1,29.15,8.9H18.74A3,3,0,0,0,18.83,8.49Zm1.37,26a2.46,2.46,0,0,1-4.91,0V21.3a2.46,2.46,0,0,1,4.91,0Zm12.28,0a2.46,2.46,0,0,1-4.91,0V21.3a2.46,2.46,0,0,1,4.91,0Z"
        transform="translate(-0.02)"
      />
    </SVG>
  );
};

export default InvertTrashIcon;
