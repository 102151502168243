import styled from "styled-components";

import DefaultTypography from "components/Typography";

interface StickyWrapperPropsType {
  padding?: string;
}

interface SliderPropsType {
  active: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  height: auto;
  padding: 0;
  margin-bottom: 24px;
`;

export const BreadcrumbWrapper = styled.div<StickyWrapperPropsType>`
  width: 100%;
  display: flex;
  z-index: 10;
  top: 0;
  padding: ${({ padding }) => padding || "0px"};
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0px;
  border: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SliderWrapper = styled.div<SliderPropsType>`
  padding: 8px 10px;
  text-align: center;
  border-bottom: ${({ active, theme }) => (active ? `2px solid ${theme.COLORS.Primary}` : "none")};
`;

export const Slider = styled.div<SliderPropsType>`
  max-width: 120px;
  min-width: 40px;
  padding: 0 2px;
`;

export const SliderText = styled((props) => {
  const { active, ...rest } = props;

  return <DefaultTypography {...rest} active={active.toString()} />;
})<SliderPropsType>`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ active, theme }) => (active ? theme.COLORS.Primary : theme.COLORS.DarkMed)};
`;

export const Title = styled(DefaultTypography)`
  margin-left: 24px;
  margin-bottom: 16px;
`;
