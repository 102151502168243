const addCreditCard = {
  form: {
    title: "Debit / Credit card",
    subTitle: "webview.deeple.ai",
    description: {
      text: "Your card will be charged to make sure it’s valid. That’s amount will automatically be refunded.",
      condition: "By adding your debit / credit card, you agree to the ",
      termOfService: "Terms of service",
    },
    label: {
      cvv: "CVV",
      validDate: "Valid till",
      cardNumber: "Card number",
      cardHolderName: "Cardholder name",
      save: "CONTINUE - ฿{{ totalPrice }}",
    },
    placeHolder: {
      cardHolderName: "Firstname Lastname",
    },
    error: {
      cvv: {
        required: "CVV number is required",
      },
      validDate: {
        inValid: "The expiry date must be a future date",
        required: "Expire date is required",
      },
      cardNumber: {
        inValid: "The card number is invalid.",
        required: "Card number is required",
      },
      cardHolderName: {
        required: "Card holder name is required field",
      },
    },
    tooltip: {
      cvv: "CVV is a 3-digit number on the back of your card.",
      validDate: "You can find it on the front of your card.",
    },
  },
};

export default addCreditCard;
