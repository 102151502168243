export enum Platform {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINE = "LINE",
}

export enum AuthPlatform {
  FACEBOOK = "FACEBOOK",
  LINE = "LINE",
  DEEPLE = "DEEPLE",
}
