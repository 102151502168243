import { FC } from "react";

import { IconSize, SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const WarningIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, ...rest } = props;

  return (
    <SVG className={size} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12C21.6 17.3019 17.3019 21.6 12 21.6C6.69806 21.6 2.39999 17.3019 2.39999 12C2.39999 6.69806 6.69806 2.39999 12 2.39999C17.3019 2.39999 21.6 6.69806 21.6 12ZM13.2 16.8C13.2 17.4627 12.6627 18 12 18C11.3373 18 10.8 17.4627 10.8 16.8C10.8 16.1373 11.3373 15.6 12 15.6C12.6627 15.6 13.2 16.1373 13.2 16.8ZM12 5.99999C11.3373 5.99999 10.8 6.53725 10.8 7.19999V12C10.8 12.6627 11.3373 13.2 12 13.2C12.6627 13.2 13.2 12.6627 13.2 12V7.19999C13.2 6.53725 12.6627 5.99999 12 5.99999Z"
      />
    </SVG>
  );
};

WarningIcon.defaultProps = {
  size: IconSize.DEFAULT,
  color: COLORS.Primary,
  viewBox: "0 0 24 24",
};

export default WarningIcon;
