const PALETTE = {
  ALABASTER: "#f7f7f7",
  ALTO: "#e0e0e0",
  BLACK: "#000000",
  BOTTICELLI: "#cad2e4",
  BRIGHT_SUN: "#fcc943",
  CATALINA_BLUE: "#0b3979",
  CATSKILL_WHITE: "#f4f7fa",
  CATSKILL: "#e4e9f4",
  CHATHAMS_BLUE: "#113566",
  CINNABAR: "#e8553a",
  COD_GRAY: "#1c1c1c",
  CURIOUS_BLUE: "#1ba5e1",
  DARK_BLUE: "#5686EE",
  DARK_MED: "#898989",
  DENIM: "#1583c7",
  DODGER_BLUE: "#4374fc",
  DOVE_GRAY: "#707070",
  FUN_BLUE: "#1e4598",
  GALLERY: "#eaeaea",
  GOSSAMER: "#0ab36d",
  GRAY: "#868686",
  GREEN_JADE: "#00c566",
  GRENADIER: "#d04402",
  JADE: "#00af79",
  LA_PALMA: "#138f2d",
  LIGHT_DENIM: "#1279be",
  MINSK: "#4e2e7f",
  NUDE_DARK_BLUE: "#E6EDFD",
  NUDE_GREEN: "#D9F7E8",
  NUDE_ORANGE: "#FFEFDF",
  NUDE_RED: "#FFE3E3",
  RED_VIOLET: "#eb198d",
  RED: "#FE4040",
  ROSE_WHITE: "#fffaf9",
  SAN_MARINO: "#4267B2",
  SAZERAC: "#fff3e1",
  SELECTIVE_YELLOW: "#f7b500",
  SILVER: "#bebebe",
  SOLID_PINK: "#7e2f36",
  SUNGLOW: "#fec43b",
  SUNSHADE: "#ff9425",
  TANGO: "#f46f22",
  TOLOPEA: "#140133",
  WHITE_LILAC: "#f5f7fb",
  WHITE: "#ffffff",
  WILD_WATERMELON: "#ff5472",
};

export const COLORS = {
  Primary: PALETTE.COD_GRAY,
  PrimaryNude: PALETTE.ALABASTER,
  Button: PALETTE.COD_GRAY,
  Accent: PALETTE.CINNABAR,
  SystemTag: PALETTE.JADE,
  PromotionCoupon: PALETTE.TOLOPEA,
  Dark: PALETTE.BLACK,
  DarkGray: PALETTE.DOVE_GRAY,
  DarkMed: PALETTE.DARK_MED,
  DarkLight: PALETTE.SILVER,
  LightGray: PALETTE.ALTO,
  LightLight: PALETTE.ALABASTER,
  LightWhite: PALETTE.WHITE,
  Panel: PALETTE.WHITE,
  PannerActive: PALETTE.ALABASTER,
  PanelGray: PALETTE.CATSKILL_WHITE,
  PanelShading: "rgba(29, 29, 29, 0.53)",
  Gallery: PALETTE.GALLERY,
  Gray: PALETTE.GRAY,
  White: PALETTE.WHITE,
  Badge: PALETTE.GRENADIER,
  BoxShadow: "rgba(0, 0, 0, 0.15)",
  ChatBadgeWrapper: PALETTE.ROSE_WHITE,
  DisabledSuccess: PALETTE.GOSSAMER,
  Error: PALETTE.WILD_WATERMELON,
  Warning: PALETTE.BRIGHT_SUN,
  Info: PALETTE.DODGER_BLUE,
  InfoYellowBackground: PALETTE.SAZERAC,
  InfoYellowBorder: PALETTE.SELECTIVE_YELLOW,
  InfoYellow: PALETTE.SUNSHADE,
  Light: PALETTE.WHITE_LILAC,
  LightBlue: PALETTE.BOTTICELLI,
  Success: PALETTE.GREEN_JADE,
  Facebook: PALETTE.SAN_MARINO,
  TableShadow: PALETTE.CATSKILL,
  OldBubbleChatBackground: PALETTE.ALTO,
  ChathamsBlue: PALETTE.CHATHAMS_BLUE,
  NudeOrange: PALETTE.NUDE_ORANGE,
  NudeGreen: PALETTE.NUDE_GREEN,
  NudeDarkBlue: PALETTE.NUDE_DARK_BLUE,
  NudeRed: PALETTE.NUDE_RED,
  DarkBlue: PALETTE.DARK_BLUE,
  Red: PALETTE.RED,
};

export const BANK_COLORS = {
  SCB: PALETTE.MINSK,
  KBANK: PALETTE.LA_PALMA,
  BBL: PALETTE.FUN_BLUE,
  CIMB: PALETTE.SOLID_PINK,
  CITI: PALETTE.DENIM,
  GSB: PALETTE.RED_VIOLET,
  KTB: PALETTE.CURIOUS_BLUE,
  TMB: PALETTE.LIGHT_DENIM,
  UOB: PALETTE.CATALINA_BLUE,
  BAY: PALETTE.SUNGLOW,
  THANACHART: PALETTE.TANGO,
};
