import { Grid } from "@material-ui/core";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper } from "containers/error/styled";

import QueryString from "qs";

import { Platform } from "types";

import Typography from "components/Typography";

import MessengerService from "services/MessengerService";

import { SupportMobileWrapper, BackToChatButton } from "./styled";

import { SUCCESS, FAIL } from "constants/image";

const SuccessPayment: FC = () => {
  const { t } = useTranslation();
  const queryString = decodeURIComponent(window.location.search);

  const [, , status = "success"] = window.location.pathname.split("/");
  const { amount, platform = "" } = QueryString.parse(queryString.replace("?", ""));
  const [isShowFooterButton, setIsShowFooterButton] = useState(false);

  const isSuccess = status === "success";
  const image = isSuccess ? SUCCESS : FAIL;

  const checkIsShowBankButton = useCallback(async () => {
    const isOpenWithMobileBrowser = await MessengerService.isOpenWithMobileBrowser(platform as Platform);

    setIsShowFooterButton(isOpenWithMobileBrowser);
  }, []);

  useEffect(() => {
    checkIsShowBankButton();
  }, []);

  return (
    <Wrapper container spacing={2} justify="center" alignItems="center">
      <Grid item xs={12}>
        <SupportMobileWrapper>
          <img className={status} src={image} alt="deeple-pay-logo" />
        </SupportMobileWrapper>
        <div className="pt-5 px-3">
          <Typography variant="dark-title-2">{t(`payment.${status}.label`)}</Typography>
          {amount && isSuccess && (
            <Typography className="pt-1" variant="dark-title-1">
              {`฿${amount}`}
            </Typography>
          )}
          {!isSuccess && (
            <Typography className="pt-1" variant="dark-body-3">
              {t(`payment.fail.description`)}
            </Typography>
          )}
        </div>
        {isShowFooterButton && (
          <div className="pt-5 px-3">
            <BackToChatButton onClick={() => MessengerService.closeWindow(platform as Platform)}>
              {t("payment.qrCode.cash.footer.label")}
            </BackToChatButton>
          </div>
        )}
      </Grid>
    </Wrapper>
  );
};

export default SuccessPayment;
