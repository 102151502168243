import { Grid } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";

import Icon from "components/Icon";
import Divider from "components/Divider";

import { getDefaultLanguage } from "utilities/common/language";

import { IconName, IconSize } from "types/icon";

import { TextItem, CardWrapper, ImageWrapper, CardTitle, DetailTitle, FooterTitle, Item } from "./styled";

import { LocaleData } from "constants/date";

import { COLORS } from "constants/color";

import { PROMOTION_END_DATE_DISPLAY_FORMAT } from "constants/product";

type CouponPropsType = {
  title: string;
  endDate: string;
  handleClick: () => void;
};

const Coupon: FC<CouponPropsType> = ({ title = "", endDate, handleClick }) => {
  const { t } = useTranslation();
  const defaultLanguage = getDefaultLanguage();

  const expiredAt = parseISO(endDate);
  const formattedEndDate = format(expiredAt, PROMOTION_END_DATE_DISPLAY_FORMAT, {
    locale: LocaleData[defaultLanguage],
  });

  return (
    <Item onClick={handleClick}>
      <CardWrapper container>
        <ImageWrapper item xs={3}>
          <Icon name={IconName.PROMOTION_DISCOUNT} color={COLORS.White} size={IconSize.LARGE} />
        </ImageWrapper>
        <Grid item xs={8}>
          <TextItem className="pt-1">
            <CardTitle>{title}</CardTitle>
          </TextItem>
        </Grid>
      </CardWrapper>
      <Divider />
      <CardWrapper container className="detail">
        <TextItem item xs={12}>
          <DetailTitle>{t("product.promotion.applyBefore")}</DetailTitle>
        </TextItem>
        <TextItem item xs={12}>
          <FooterTitle>{formattedEndDate}</FooterTitle>
        </TextItem>
      </CardWrapper>
    </Item>
  );
};

Coupon.displayName = "Coupon";

export default Coupon;
