import { IS_ALLOW_CREDIT_QR } from "../../config/index";

export const getPage = (path: string, params?: Record<string, string> | null, qs?: Record<string, string>) => {
  let url = path;
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      url = url.replace(`:${key}`, value);
    });
  }
  if (qs) {
    Object.entries(qs).forEach(([key, value], index) => {
      url = index === 0 ? `${url}?${key}=${value}` : `${url}&${key}=${value}`;
    });
  }
  return url;
};

export const isAllowCreditQR = IS_ALLOW_CREDIT_QR || false;

export const getIsPaymentSuccessPage = (route: string) => {
  const [, page, status] = route.split("/");

  return page === "payment" && (status === "success" || status === "fail");
};
