import { useEffect } from "react";
import debounce from "lodash/debounce";

// To fix vh problem in ios
function useIOSRender() {
  useEffect(() => {
    const resizeScreen = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    const onResize = debounce(resizeScreen, 50);

    resizeScreen();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);
}

export default useIOSRender;
