export enum ErrorCode {
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  PAGE_NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  REQUEST_TIMEOUT = 408,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
}
