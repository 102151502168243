import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const BackIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        className="cls-2"
        d="M16.17,24,36.46,3.73A2.18,2.18,0,1,0,33.37.64L11.54,22.47a2.19,2.19,0,0,0,0,3.09L33.37,47.4a2.19,2.19,0,0,0,3.09-3.09Z"
      />
    </SVG>
  );
};

export default BackIcon;
