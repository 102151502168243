import { gql } from "@apollo/client";

export const GENERATE_QR_PAYMENT = gql`
  mutation generateQRPayment($type: QRPaymentType!, $orderId: ID!) {
    generateQRPayment(type: $type, orderId: $orderId) {
      amount
      referenceNo
      qrCode
    }
  }
`;

export const GENERATE_MOBILE_BANKING = gql`
  mutation generateQRPayment($bank: MobileBankingBank!, $orderId: ID!) {
    generateMobileBanking(bank: $bank, orderId: $orderId) {
      referenceNo
      backgroundUrl
      responseUrl
      bankCode
      checksum
      amount
    }
  }
`;

export const GENERATE_CREDIT_CARD = gql`
  mutation generateCreditCardCharge($token: ID!, $orderId: ID!) {
    generateCreditCardCharge(token: $token, orderId: $orderId) {
      referenceNo
      gbpReferenceNo
    }
  }
`;

export const GENERATE_TRUE_MONEY_WALLET = gql`
  mutation generateTrueMoneyWallet($orderId: ID!) {
    generateTrueMoneyWallet(orderId: $orderId) {
      referenceNo
      backgroundUrl
      responseUrl
      checksum
      amount
    }
  }
`;
