import { useMutation, useQuery } from "@apollo/client";

import { CART } from "graphql/cart/query";

import MessengerService from "services/MessengerService";

import { Cart } from "types/cart";

import { UPDATE_CART, ADD_TO_CART } from "../graphql/product/mutation";
import { CHECKOUT_CART } from "../graphql/cart/mutation";

type CartQueryType = {
  cart: Cart;
};

const useCart = () => {
  const { data, loading, refetch } = useQuery<CartQueryType>(CART, { fetchPolicy: "network-only" });
  const [addToCart, { loading: addToCartLoading }] = useMutation(ADD_TO_CART);
  const [updateCart, { loading: updateCartLoading }] = useMutation(UPDATE_CART);
  const [checkoutCart, { loading: checkoutLoading }] = useMutation(CHECKOUT_CART, {
    onError: () => {
      throw new Error();
    },
    onCompleted: () => {
      MessengerService.closeWindow();
    },
  });

  const removeCart = async (id: string) => {
    await updateCart({
      variables: {
        currentProductSKUId: id,
        amount: 0,
      },
    });
  };

  const cart = {
    productTotal: data?.cart?.productTotal || 0,
    grandTotal: data?.cart?.grandTotal || 0,
    cartProducts: data?.cart?.cartProducts || [],
    loading,
  };

  return {
    cart,
    updateCart,
    checkoutCart,
    addToCart,
    removeCart,
    refetchCart: refetch,
    updateCartLoading,
    checkoutLoading,
    addToCartLoading,
  };
};

export default useCart;
