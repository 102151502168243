import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@material-ui/core";

import { useParams } from "react-router";

import { PopupType } from "types";

import { GB_PRIME_BASE_URL, GB_PRIME_PUBLIC_KEY } from "config";

import Footer from "components/Footer";
import Typography from "components/Typography";

import PopupComponent from "components/Popup";

import useOrder from "hooks/useOrder";

import { isMobileDevice } from "utilities/common";

import { formatPriceText } from "utilities/product/calculateMinAndMaxPrice";

import { MobileBankingBank, MobileBankingSchema } from "types/payment";

import { GeneratePaymentStatus, OrderSchema } from "types/order";

import { PageWrapper, Wrapper, ItemWrapper, FooterButton, LogoWrapper, CheckImage, SubmitButton } from "./styled";

import useMobileBanking from "./hooks/useMobileBanking";

import SupportMobileDevice from "../SupportMobileDevice";

import DeeplePayLoading from "../DeeplePayLoading";

import ErrorOrder from "../ErrorOrder";

import { CHECK_IMAGE } from "constants/image";
import { COLORS } from "constants/color";
import { MOBILE_BANKING_APPS } from "constants/payment";

export const MobileBanking: FC = () => {
  const { t } = useTranslation();
  const { orderId = "" } = useParams<{ orderId: string }>();
  const [isInitialPayment, setIsInitialPayment] = useState<boolean>(false);
  const [bankDetail, setBankDetail] = useState<MobileBankingSchema>();
  const [selectedBankingApp, setSelectedBankingApp] = useState<MobileBankingBank>();
  const { order, loadingOrder } = useOrder(orderId);
  const { generateBankDetail, loadingGenerateMobileBanking } = useMobileBanking(orderId);
  const isErrorOrder = order?.generatePaymentStatus !== GeneratePaymentStatus.SUCCESS;
  const keys = bankDetail ? (Object.keys(bankDetail) as (keyof MobileBankingSchema)[]) : [];

  const { generatePaymentAmount }: OrderSchema = order || {};

  const handleSubmitBanking = async () => {
    if (selectedBankingApp) {
      const bankDetail = await generateBankDetail(selectedBankingApp);
      await setBankDetail(bankDetail);
    }

    const button = document.getElementById("submit");
    if (button) {
      button.click();
    }
  };

  useEffect(() => {
    setIsInitialPayment(true);
    setTimeout(async () => {
      setIsInitialPayment(false);
    }, 2000);
  }, []);

  if (!isMobileDevice) {
    return <SupportMobileDevice />;
  }

  if (isErrorOrder && !(loadingOrder || loadingGenerateMobileBanking)) return <ErrorOrder />;

  if (isInitialPayment) {
    return <DeeplePayLoading />;
  }

  if (loadingOrder || loadingGenerateMobileBanking) {
    return <PopupComponent type={PopupType.LOADING} />;
  }

  return (
    <form action={`${GB_PRIME_BASE_URL}/v2/mobileBanking`} id="mobileBankingform" method="POST">
      {/* create form to submit to GBPrime */}
      {bankDetail && keys.map((key) => <input key={key} name={key} type="hidden" value={bankDetail[key]} />)}
      {bankDetail && <input name="publicKey" type="hidden" value={GB_PRIME_PUBLIC_KEY} />}
      <PageWrapper>
        <Wrapper>
          {MOBILE_BANKING_APPS.map(({ key, value, logo, title }) => (
            <ItemWrapper
              container
              onClick={() => setSelectedBankingApp(value)}
              key={key}
              justify="flex-start"
              className={selectedBankingApp === value ? "mt-3 p-3 selected" : "mt-3 p-3"}
            >
              <LogoWrapper container item alignItems="center" justify="center" className="pr-2 mr-1" xs={2}>
                <img className="mr-1" src={logo} alt={key} />
              </LogoWrapper>
              <Grid
                container
                item
                alignItems="center"
                className="pl-2"
                justify="flex-start"
                xs={selectedBankingApp === value ? 7 : 9}
              >
                <Grid item xs={12}>
                  <Typography variant="dark-title-5">{t(title)}</Typography>
                </Grid>
              </Grid>
              {selectedBankingApp === value && (
                <Grid container item alignItems="center" justify="flex-end" xs={2}>
                  <CheckImage src={CHECK_IMAGE} alt="check" />
                </Grid>
              )}
            </ItemWrapper>
          ))}
        </Wrapper>
        <Footer>
          <FooterButton handleClick={handleSubmitBanking} disabled={!selectedBankingApp} color={COLORS.Primary}>
            <Typography variant="white-title-3">
              {selectedBankingApp && generatePaymentAmount
                ? t("payment.mobileBanking.footer.button.continue", {
                    amount: generatePaymentAmount ? formatPriceText(generatePaymentAmount) : "",
                  })
                : t("payment.mobileBanking.footer.button.default")}
            </Typography>
          </FooterButton>
          <SubmitButton id="submit" type="submit" />
        </Footer>
      </PageWrapper>
    </form>
  );
};
