import styled from "styled-components";

export const Button = styled.button<{ palette: string; type: string }>`
  width: 100%;
  height: 100%;
  max-height: 50px;
  min-height: 30px;
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.White};
  background-color: ${({ theme, palette }) => theme.COLORS[palette] || palette};
  box-shadow: none;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;

  :hover {
    background-color: ${({ theme, palette }) => theme.COLORS[palette] || palette};
  }

  :disabled {
    color: ${({ theme }) => theme.COLORS.White};
    background-color: ${({ theme }) => theme.COLORS.DarkLight};
  }

  &.outlined {
    * {
      color: ${({ theme, palette }) => theme.COLORS[palette] || palette};
    }
    border: 1px solid ${({ theme, palette }) => theme.COLORS[palette] || palette};
    background-color: ${({ theme }) => theme.COLORS.White};

    :disabled {
      * {
        color: ${({ theme }) => theme.COLORS.DarkMed} !important;
      }
      border: 1px solid ${({ theme }) => theme.COLORS.DarkMed};
      background-color: ${({ theme }) => theme.COLORS.White};
    }

    :hover {
      background-color: ${({ theme }) => theme.COLORS.Panel};
    }
  }
`;
