import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const PaymentMethodWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0;

  svg + h5 {
    margin-left: 8px;
  }

  img + h5 {
    margin-left: 8px;
  }

  h5 + p {
    margin-left: 8px;
  }
`;

export const ImageThumbnail = styled.img`
  width: 32px;
  height: 32px;
`;

export const PopupProductWrapper = styled(Grid)`
  display: inline-flex;
  align-items: center;

  img + h6,
  svg + p {
    margin-left: 10px;
  }
`;

export const ShippingImage = styled.img`
  width: 32px;
  height: 32px;
`;
