import styled from "styled-components";

type ProductImagePropsType = {
  src?: string;
};

type ProductPricePropsType = {
  strikeThrough?: boolean;
};

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;

  :before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

export const ImageDisabled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
`;

export const ProductImage = styled.div<ProductImagePropsType>`
  object-fit: cover;
  text-align: center;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const TextEllipsis = styled.span`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PromotionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  width: calc(100% - 20px);
`;

export const ProductDetailWrapper = styled.div`
  padding: 16px 8px 8px 8px;
  height: 64px;
`;

export const ProductPrice = styled(TextEllipsis)<ProductPricePropsType>`
  text-decoration: ${({ strikeThrough }) => (strikeThrough ? "line-through" : "none")};
`;

export const PricePrefix = styled.span<{ lineHeight?: string }>`
  vertical-align: text-top;
  margin-right: 2px;
  line-height: ${({ lineHeight }) => lineHeight || "inherit"};
`;
