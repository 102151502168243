import React, { FC } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import TextField from "components/Input/TextField";

import { FixWidthTooltip } from "components/Tooltip";
import Icon from "components/Icon";

import { IconName, IconSize } from "types/icon";

import { COLORS } from "constants/color";

interface CreditCardCVVFieldPropsType {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  placeholder?: string;
  name?: string;
  tooltipText?: string;
}

const CreditCardCVVField: FC<CreditCardCVVFieldPropsType> = (props) => {
  const { tooltipText } = props;
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <TextField
      {...props}
      isOutlined
      fullWidth
      type="password"
      maxLength={3}
      endAdornment={
        tooltipText && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <FixWidthTooltip
              title={tooltipText}
              placement="bottom-end"
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipClose}
              open={open}
            >
              <div onClick={handleTooltipOpen}>
                <Icon name={IconName.INFO} size={IconSize.DEFAULT} color={COLORS.LightGray} />
              </div>
            </FixWidthTooltip>
          </ClickAwayListener>
        )
      }
    />
  );
};

export default CreditCardCVVField;
