const addCreditCard = {
  form: {
    title: "Debit / Credit card",
    subTitle: "webview.deeple.ai",
    description: {
      text: "บัตรของคุณจะถูกเรียกเก็บเงินเพื่อให้แน่ใจว่าถูกต้อง จำนวนเงินนั้นจะได้รับคืนโดยอัตโนมัติ",
      condition: "การเพิ่มบัตรเดบิต / เครดิตของคุณแสดงว่าคุณยอมรับข้อกำหนดและเงื่อนไข ",
      termOfService: "Terms of service",
    },
    label: {
      cvv: "CVV",
      validDate: "วันหมดอายุ",
      cardNumber: "หมายเลขบัตร",
      cardHolderName: "ชื่อผู้ถือบัตร",
      save: "ดำเนินการต่อ - ฿{{ totalPrice }}",
    },
    placeHolder: {
      cardHolderName: "ชื่อจริง นามสกุล",
    },
    error: {
      cvv: {
        required: "กรุณาระบุเลข CVV ที่อยู่ด้านหลังบัตร",
      },
      validDate: {
        inValid: "วันหมดอายุต้องเป็นวันในอนาคต",
        required: "กรุณาระบุวันหมดอายุ",
      },
      cardNumber: {
        inValid: "เลขบัตรเครดิตไม่ถูกต้อง",
        required: "กรุณาระบุเลขบัตรเดบิต/เครดิต",
      },
      cardHolderName: {
        required: "กรุณาระบุชื่อบนบัตร",
      },
    },
    tooltip: {
      cvv: "CVV คือตัวเลข 3 หลักด้านหลังบัตรเดบิต/เครดิตของคุณ",
      validDate: "วันหมดอายุที่อยู่ด้านหน้าบัตรของคุณ",
    },
  },
};

export default addCreditCard;
