import { FC } from "react";

import Typography from "components/Typography";

import { Category } from "types/category";

import { Wrapper, CategoryImage, CategoryNameWrapper } from "./styled";

import { NO_IMG_AVAILABLE } from "constants/image";

type CategoryCardPropsType = {
  category: Category;
};

const CategoryCard: FC<CategoryCardPropsType> = (props) => {
  const { category } = props;

  return (
    <Wrapper>
      <CategoryImage src={category.image || NO_IMG_AVAILABLE} />
      <CategoryNameWrapper>
        <Typography variant="white-title-2">{category.name}</Typography>
      </CategoryNameWrapper>
    </Wrapper>
  );
};

CategoryCard.displayName = "CategoryCard";

export default CategoryCard;
