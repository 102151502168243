import { FC } from "react";

import { CartProduct } from "types/cart";

import { ProductListWrapper } from "./styled";
import CartProductItem from "./CartProductItem";

export interface CartProductListPropsType {
  products?: CartProduct[];
  handleDeleteItem: (id: string) => void;
}

const CartProductList: FC<CartProductListPropsType> = (props) => {
  const { products = [], handleDeleteItem } = props;

  return (
    <ProductListWrapper>
      {products.map((product: CartProduct) => {
        return <CartProductItem handleDeleteItem={handleDeleteItem} key={product.productSKUId} product={product} />;
      })}
    </ProductListWrapper>
  );
};

export default CartProductList;
