import { FC, useState, useRef } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import _debounce from "lodash/debounce";

import { DefaultProps, Category } from "types";

import CartFooterButton from "containers/cart/CartFooterButton";

import InfiniteScroll from "containers/category/InfiniteScroll";

import { ALL_CATEGORY } from "graphql/category";

import { PRODUCTS } from "graphql/product";

import useCart from "hooks/useCart";

import { formatPriceText } from "utilities/product/calculateMinAndMaxPrice";

import HomeHeader from "./HomeHeader";

import { HomeWrapper } from "./styled";
import GroupProductCategory from "./GroupProductCategory";

const PREFIX_ID = "group-product-by-category-";
const LIMIT = 10;

interface CategoriesData {
  categories: Category[];
}

interface CategoriesVars {
  queryOption: {
    offset?: number;
    limit?: number;
  };
}

const Home: FC<DefaultProps> = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [keywordSearch, setKeywordSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const { cart } = useCart();
  const { productTotal, grandTotal } = cart || {};
  const [getProducts, { data: { products = {} } = {}, fetchMore, loading }] = useLazyQuery(PRODUCTS, {
    fetchPolicy: "network-only",
  });
  const hasItemsInCart = productTotal > 0;

  const { data } = useQuery<CategoriesData, CategoriesVars>(ALL_CATEGORY, {
    fetchPolicy: "cache-first",
  });

  const categories = data?.categories || [];

  const onSearch = _debounce((keyword) => {
    setKeywordSearch(keyword);
    if (!keyword) {
      return null;
    }
    getProducts({
      variables: {
        offset: 0,
        limit: LIMIT,
        productFilterInput: {
          keyword,
        },
      },
    });
    return setOffset(LIMIT);
  }, 500);

  const refresh = () => {
    getProducts({
      variables: {
        offset: 0,
        limit: LIMIT,
        productFilterInput: {
          keyword: keywordSearch,
        },
      },
    });
    return setOffset(LIMIT);
  };

  const loadMore = () => {
    if (fetchMore) {
      fetchMore({
        variables: {
          offset,
          limit: LIMIT,
          productFilterInput: {
            keyword: keywordSearch,
          },
        },
      });
      setOffset(offset + LIMIT);
    }
  };

  const hasMore = () => {
    if (products?.total && products?.results?.length) {
      return products.total > products.results.length;
    }
    return true;
  };

  return (
    <HomeWrapper id="home-container" ref={wrapperRef}>
      <HomeHeader
        prefixId={PREFIX_ID}
        categories={categories}
        isShowBreadcrumb={!keywordSearch}
        onSearch={onSearch}
        targetRef={wrapperRef.current}
        searchPlaceHolder={t("home.searchPlaceholder")}
      />
      {keywordSearch ? (
        <InfiniteScroll
          products={products.results || []}
          loading={loading}
          loadMore={loadMore}
          refresh={refresh}
          hasMore={hasMore()}
          target="home-container"
        />
      ) : (
        categories.map((category: Category) => (
          <GroupProductCategory key={category.id} id={`${PREFIX_ID}${category.id}`} category={category} />
        ))
      )}
      <CartFooterButton
        hasItemsInCart={hasItemsInCart}
        grandTotal={formatPriceText(grandTotal)}
        productTotal={productTotal}
      />
    </HomeWrapper>
  );
};

export default Home;
