import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const InvertCartIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.LightWhite } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        id="Union_1"
        data-name="Union 1"
        className="cls-1"
        d="M30.77,44.35H26.31a5.62,5.62,0,0,1-7.17,3.32,5.56,5.56,0,0,1-3.41-3.45,6.32,6.32,0,0,1-5.27-5.58v0l-3-32.54A2.57,2.57,0,0,0,4.91,3.75H2A1.89,1.89,0,0,1,0,1.93,1.89,1.89,0,0,1,1.85,0H4.91a6.36,6.36,0,0,1,6.36,5.75l.41,4.47H46.17a2,2,0,0,1,.59.09,1.83,1.83,0,0,1,1.15,2.29L43,27.05a8.68,8.68,0,0,1-8.23,6.05h-21l.47,5.17a2.55,2.55,0,0,0,1.61,2.11,5.63,5.63,0,0,1,7.3-3,5.57,5.57,0,0,1,3.16,3.24h4.46a5.62,5.62,0,0,1,7.17-3.32,5.52,5.52,0,0,1,3.37,7.08,5.63,5.63,0,0,1-7.17,3.33,5.56,5.56,0,0,1-3.37-3.33Zm3.47-1.88A1.8,1.8,0,1,0,36,40.69a1.78,1.78,0,0,0-1.8,1.78Zm-15,0A1.8,1.8,0,1,0,21,40.69a1.79,1.79,0,0,0-1.8,1.78Z"
        transform="translate(0 0)"
      />
    </SVG>
  );
};

export default InvertCartIcon;
