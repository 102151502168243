import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const CreditCardIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 32 32", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 10.0838V7.41509C32 6.08075 30.9192 5 29.5849 5H2.41509C1.08075 5 0 6.08075 0 7.41509V10.0838H32ZM25.9983 18.3344H24.664C24.2957 18.3344 23.9968 18.6333 23.9968 19.0016V20.3359C23.9968 20.7042 24.2957 21.0031 24.664 21.0031H25.9983C26.3666 21.0031 26.6655 20.7042 26.6655 20.3359V19.0016C26.6655 18.6333 26.3666 18.3344 25.9983 18.3344ZM0 23.916V13.2473H32V23.916C32 25.2504 30.9192 26.3311 29.5849 26.3311H2.41509C1.08075 26.3311 0 25.2504 0 23.916ZM4.66717 22.3341H12.6672C13.0355 22.3341 13.3343 22.0353 13.3343 21.667C13.3343 21.2987 13.0355 20.9998 12.6672 20.9998H4.66717C4.29887 20.9998 4 21.2987 4 21.667C4 22.0353 4.29887 22.3341 4.66717 22.3341ZM4.66717 19.6655H12.6672C13.0355 19.6655 13.3343 19.3666 13.3343 18.9983C13.3343 18.63 13.0355 18.3311 12.6672 18.3311H4.66717C4.29887 18.3311 4 18.63 4 18.9983C4 19.3666 4.29887 19.6655 4.66717 19.6655ZM26.0015 22.3311C27.1034 22.3311 28 21.4345 28 20.3326V18.9983C28 17.8964 27.1034 16.9998 26.0015 16.9998H24.6672C23.5653 16.9998 22.6687 17.8964 22.6687 18.9983V20.3326C22.6687 21.4345 23.5653 22.3311 24.6672 22.3311H26.0015Z"
        fill={color}
      />
    </SVG>
  );
};

export default CreditCardIcon;
