import { gql } from "@apollo/client";

import { DIGITAL_PRODUCT_FRAGMENT, FOOD_PRODUCT_FRAGMENT, PHYSICAL_PRODUCT_FRAGMENT } from "./fragments";

export const PRODUCTS = gql`
  query products($productFilterInput: ProductFilterInput!, $limit: Int!, $offset: Int!) {
    products(productFilterInput: $productFilterInput, limit: $limit, offset: $offset) {
      results {
        ...PhysicalProductSchema
        ...DigitalProductSchema
        ...FoodProductSchema
      }
      total
    }
  }
  ${PHYSICAL_PRODUCT_FRAGMENT}
  ${DIGITAL_PRODUCT_FRAGMENT}
  ${FOOD_PRODUCT_FRAGMENT}
`;

export const PRODUCT_BY_PRODUCT_ID = gql`
  query product($productId: ID!) {
    product(productId: $productId) {
      ...PhysicalProductSchema
      ...DigitalProductSchema
      ...FoodProductSchema
    }
  }
  ${PHYSICAL_PRODUCT_FRAGMENT}
  ${DIGITAL_PRODUCT_FRAGMENT}
  ${FOOD_PRODUCT_FRAGMENT}
`;
