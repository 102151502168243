import { FC } from "react";
import _ from "lodash";

import GroupOfSKUButton from "./GroupOfSKUButton";

import { TwoVariantVariableTypeSelectorPropsType } from "../types";

const TwoVariantVariableTypeSelector: FC<TwoVariantVariableTypeSelectorPropsType> = ({
  productSKUWithVariableKey,
  onChange,
  primaryKey,
  setPrimaryKey,
  secondaryKey,
  setSecondaryKey,
}) => {
  const skuGroupByFirstType = _.groupBy(productSKUWithVariableKey, "variableTypes[0].value");
  const skuGroupBySecondType = _.groupBy(productSKUWithVariableKey, "variableTypes[1].value");

  const handleChange = (primary: string, secondary: string) => {
    if (primary && secondary) {
      const selectSKU = productSKUWithVariableKey.find((sku) => {
        return _.difference([primary, secondary], sku.variableKey).length === 0;
      });
      return onChange(selectSKU, 1);
    }
    return onChange();
  };

  const onChangePrimarySKU = (currentKey: string, incomingKey: string, setFunction: (key: string) => void) => {
    if (currentKey === incomingKey) {
      handleChange("", secondaryKey);
      return setFunction("");
    }
    handleChange(incomingKey, secondaryKey);
    return setFunction(incomingKey);
  };
  const onChangeSecondarySKU = (currentKey: string, incomingKey: string, setFunction: (key: string) => void) => {
    if (currentKey === incomingKey) {
      handleChange(primaryKey, "");
      return setFunction("");
    }
    handleChange(primaryKey, incomingKey);
    return setFunction(incomingKey);
  };

  return (
    <>
      <GroupOfSKUButton
        label={productSKUWithVariableKey[0].variableTypes[0].key}
        skuGroupByValue={skuGroupByFirstType}
        onChangeSKU={(key) => onChangePrimarySKU(primaryKey, key, setPrimaryKey)}
        ownKey={primaryKey}
        watchKey={secondaryKey}
      />
      <GroupOfSKUButton
        label={productSKUWithVariableKey[0].variableTypes[1].key}
        skuGroupByValue={skuGroupBySecondType}
        onChangeSKU={(key) => onChangeSecondarySKU(secondaryKey, key, setSecondaryKey)}
        ownKey={secondaryKey}
        watchKey={primaryKey}
      />
    </>
  );
};

export default TwoVariantVariableTypeSelector;
