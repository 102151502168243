import { FC } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import StickyButton from "components/StickyButton";
import Typography from "components/Typography";

import { getPage } from "utilities/page";

import { Price, StickyWrapper } from "./styled";

import { PAGE } from "constants/page";

interface CartFooterButtonPropTypes {
  productTotal: number;
  grandTotal: string | number;
  hasItemsInCart?: boolean;
}

export const CartFooterButton: FC<CartFooterButtonPropTypes> = ({
  productTotal,
  grandTotal,
  hasItemsInCart = false,
}) => {
  const { t } = useTranslation();

  return hasItemsInCart ? (
    <Link to={getPage(PAGE.Cart)}>
      <StickyButton>
        <StickyWrapper container alignItems="center">
          <Grid item xs={7}>
            <Typography variant="white-title-2">
              {t("home.footer.cartButton.items.label", { item: productTotal })}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Price variant="price-title-2">
              {t("home.footer.cartButton.totalPrice.label", { totalPrice: grandTotal })}
            </Price>
          </Grid>
        </StickyWrapper>
      </StickyButton>
    </Link>
  ) : null;
};

export default CartFooterButton;
