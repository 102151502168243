import { useLayoutEffect, FC, useState } from "react";
import { isNil } from "lodash";

import Icon from "components/Icon";

import { IconName, IconSize } from "types/icon";

import { Wrapper, CounterButton, Input } from "./styled";

type CounterPropTypes = {
  max: number;
  min: number;
  handleChange: (value: number) => void;
  value: number;
  disabled?: boolean;
};

const Counter: FC<CounterPropTypes> = ({ value, handleChange, max, min, disabled = false }) => {
  const [number, setNumber] = useState(value);
  const increaseNumber = () => {
    if (max && number >= max) {
      return;
    }

    setNumber(value + 1);
    handleChange(value + 1);
  };

  const decreaseNumber = () => {
    if (min && number <= min) {
      return;
    }

    setNumber(value - 1);
    handleChange(value - 1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;

    if (min && value < min) {
      return;
    }

    if (!isNil(max) && value > max) {
      return;
    }
    setNumber(value);
  };

  useLayoutEffect(() => {
    setNumber(value);
  }, [value]);

  return (
    <Wrapper className={disabled ? "disabled" : ""}>
      <CounterButton className={value <= min || disabled ? "disabled" : ""} onClick={decreaseNumber}>
        <Icon name={IconName.MINUS} size={IconSize.SMALL} />
      </CounterButton>
      <Input
        type="number"
        step="1"
        disabled={disabled}
        value={+number}
        onChange={(e) => handleInputChange(e)}
        onBlur={() => handleChange(number)}
      />
      <CounterButton className={value >= max || disabled ? "disabled" : ""} onClick={increaseNumber}>
        <Icon name={IconName.PLUS} size={IconSize.SMALL} />
      </CounterButton>
    </Wrapper>
  );
};

Counter.displayName = "Counter";

export default Counter;
