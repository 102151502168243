import { FC, useState, useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";

import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import produce from "immer";

import foodSingleSKUMock from "__mock__/foodSingleSKUMock.json";

import { useTranslation } from "react-i18next";

import Swiper from "swiper";

import Counter from "components/Counter";
import CouponList from "components/Coupon/CouponList";
import Divider from "components/Divider";

import ImageCarousel, { Item } from "components/Carousel/ImageCarousel";
import Icon from "components/Icon";

import PromotionBadge from "components/Badge";

import Typography from "components/Typography";

import { SHIPPINGS } from "graphql/shippings/query";

import getDigitalContentAvailabilityText from "utilities/product/getDigitalContentAvailabilityText";

import { formatPriceRangeText, formatPriceText } from "utilities/product/calculateMinAndMaxPrice";

import { IconName } from "types/icon";
import { ProductType, DigitalContentOptions, ProductSKU, Product, InitialProductSKU } from "types/product";
import { Shipping } from "types/shipping";
import { Promotion } from "types/promotion";

import {
  DiscountPriceText,
  BodyWrapper,
  DigitalContentBox,
  CarouselWrapper,
  Body,
  SectionIcon,
  PriceWrapper,
  ProductGrid,
  SectionWrapper,
  CounterWrapper,
  BadgeWrapper,
} from "./styled";

import ProductSKUSelectors from "../ProductSKUSelectors";
import FoodProductOptionsSelector from "../FoodProductOptionsSelector";

import ShippingMethod from "../ShippingMethod";

interface ProductBodyPropTypes {
  product: Product;
  initialProductSKU?: InitialProductSKU;
  selectedProductSKU?: ProductSKU;
  setSelectedProductSKU: (sku: ProductSKU) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  setIsOpenDescriptionPopup: (value: boolean) => void;
  setIsOpenConditionPopup: (value: boolean) => void;
  setIsOpenPromotionPopup: (value: boolean) => void;
  setSelectedPromotion: (promotion: Promotion) => void;
}

export const ProductBody: FC<ProductBodyPropTypes> = ({
  product,
  initialProductSKU,
  quantity,
  setQuantity,
  selectedProductSKU,
  setSelectedProductSKU,
  setIsOpenDescriptionPopup,
  setIsOpenConditionPopup,
  setIsOpenPromotionPopup,
  setSelectedPromotion,
}) => {
  const { t } = useTranslation();
  const [productSKUOptions, setProductSKUOptions] = useState(foodSingleSKUMock[0].options);
  const [swiper, setSwiper] = useState<Swiper>();

  // #region Handle set carousel items (product images) with setState
  const productImages: Item[] = product?.images
    ? product.images.map(({ src }) => ({ url: src as string, title: "" }))
    : [];
  const memoProductImages = useMemo(() => uniqBy([...productImages], "url"), [productImages]);
  const [carouselItems, setCarouselItems] = useState<Item[]>(memoProductImages);
  // #endregion

  // #region Shipping query
  const { data: { shippings = [] } = {} } = useQuery<{ shippings: Shipping[] | [] }>(SHIPPINGS, {
    fetchPolicy: "network-only",
  });
  // #endregion

  // #region Format data from product, product skus, promotions
  const { productSKUs = [], promotions = [], type = "" } = product || {};
  const isDigitalProduct = product?.type === ProductType.DIGITAL_CONTENT;
  const isShowDescriptionSection = Boolean(
    (product?.description && !isEmpty(product?.description)) || (product?.properties && !isEmpty(product?.properties)),
  );
  const isShowPromotionSection = Boolean(promotions.length);
  const isShowConditionSection = isDigitalProduct && product?.additionalDescription;
  const hasPromotion = promotions?.length > 0;

  const isShowDiscount = product.minPrice !== product.minDiscountedPrice;

  const price = selectedProductSKU
    ? formatPriceText(selectedProductSKU.price)
    : formatPriceRangeText(product.minPrice || 0, product.maxPrice || 0);

  const discountedPrice = selectedProductSKU
    ? formatPriceText(selectedProductSKU.discountedPrice)
    : formatPriceRangeText(product.minDiscountedPrice || 0, product.maxDiscountedPrice || 0);

  const maxQuantity = selectedProductSKU ? selectedProductSKU.stock - selectedProductSKU.reservedStock : 0;
  const isOutOfStock = selectedProductSKU
    ? selectedProductSKU?.stock - selectedProductSKU?.reservedStock - quantity < 0
    : true;

  const digitalContentOptions = selectedProductSKU?.digitalContentOptions;
  const digitalContentText = digitalContentOptions
    ? getDigitalContentAvailabilityText(digitalContentOptions as DigitalContentOptions, t)
    : "";

  const getStockWording = () => {
    const stock = selectedProductSKU ? selectedProductSKU.stock - selectedProductSKU.reservedStock : 0;
    if (!stock && selectedProductSKU) {
      return t("product.quantity.outOfStock");
    }

    if ((stock <= 5 || stock === quantity) && selectedProductSKU) {
      return t("product.quantity.remaining", { stock });
    }

    return undefined;
  };
  // #endregion

  // #region Handle change product sku selector
  const handleChangeProductSKUsSelector = (newSelectedProductSKU?: ProductSKU, amount = 0) => {
    // Check whether current product sku has image or not, otherwise it will fallback to product images one
    if (newSelectedProductSKU?.image && swiper) {
      // Setting carousel to product sku image
      setCarouselItems([{ title: "", url: newSelectedProductSKU.image }]);
    } else if (swiper) {
      // Resetting carousel item to default one aka product images
      setCarouselItems(memoProductImages);
    }

    setSelectedProductSKU(newSelectedProductSKU as ProductSKU);
    setQuantity(amount);
  };
  // #endregion

  const handleOpenPromotion = (item: Promotion) => {
    setIsOpenPromotionPopup(true);
    setSelectedPromotion(item);
  };

  const handleChangeProductSKUOptionsSelector = useCallback(
    (index: number, choiceIndex: number, checked: boolean, isMultipleChoices?: boolean) => {
      const newProductSKUOptions = produce(productSKUOptions, (draft) => {
        if (!isMultipleChoices) {
          draft[index].choices.forEach((choice) => {
            // eslint-disable-next-line no-param-reassign
            choice.isSelected = false;
          });
        }

        draft[index].choices[choiceIndex].isSelected = checked;
      });

      setProductSKUOptions(newProductSKUOptions);
    },
    [productSKUOptions],
  );

  return (
    <BodyWrapper>
      <CarouselWrapper>
        <ImageCarousel items={carouselItems} onSwiper={setSwiper} />
      </CarouselWrapper>
      <Body>
        <ProductGrid className="title" container alignItems="center" justify="center">
          {hasPromotion && (
            <BadgeWrapper item xs={12}>
              {promotions.map((promotion: Promotion) => (
                <PromotionBadge key={promotion.id} size="S">
                  {promotion.shortName}
                </PromotionBadge>
              ))}
            </BadgeWrapper>
          )}
          <Grid item xs={12}>
            <Typography variant="dark-title-1">{product.name}</Typography>
          </Grid>
          {isShowDiscount ? (
            <>
              <PriceWrapper item xs={12}>
                <Typography variant="price-title-2">฿</Typography>
                <Typography variant="price-title-1">{discountedPrice}</Typography>
              </PriceWrapper>
              <PriceWrapper item xs={12}>
                <Typography variant="price-title-7">฿</Typography>
                <DiscountPriceText variant="price-title-4" className="line-through">
                  {price}
                </DiscountPriceText>
              </PriceWrapper>
            </>
          ) : (
            <PriceWrapper item xs={12}>
              <Typography variant="price-title-2">฿</Typography>
              <Typography variant="price-title-1">{price}</Typography>
            </PriceWrapper>
          )}
        </ProductGrid>

        <ProductGrid className="selector" container alignItems="center" justify="center">
          <Grid className="selector" item xs={12}>
            {product.type === ProductType.SINGLE_SKU_WITH_OPTIONS && (
              <FoodProductOptionsSelector
                onSelectOption={handleChangeProductSKUOptionsSelector}
                productSKUOptions={productSKUOptions}
              />
            )}
            {[ProductType.SKU, ProductType.DIGITAL_CONTENT].includes(type as ProductType) && (
              <ProductSKUSelectors
                productSKUs={productSKUs as ProductSKU[]}
                onChange={handleChangeProductSKUsSelector}
                initialProductSKU={initialProductSKU}
              />
            )}
          </Grid>
          {digitalContentText && (
            <SectionWrapper className="selector" item xs={12}>
              <DigitalContentBox xs={12}>
                <Typography variant="dark-body-3">{digitalContentText}</Typography>
              </DigitalContentBox>
            </SectionWrapper>
          )}
          <ProductGrid className="selector" item xs={12}>
            <Typography variant="dark-title-2">{t("product.quantity.title")}</Typography>
            <CounterWrapper>
              <Counter
                min={0}
                max={maxQuantity > 99 ? 99 : maxQuantity}
                handleChange={setQuantity}
                disabled={isOutOfStock}
                value={quantity}
              />
              <Typography variant="accent-body-1">{getStockWording()}</Typography>
            </CounterWrapper>
          </ProductGrid>
        </ProductGrid>

        <ProductGrid className="divider">
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </ProductGrid>

        {isShowPromotionSection && (
          <>
            <ProductGrid container>
              <Grid item xs={12}>
                <Typography variant="dark-title-2">{t("product.promotion.title")}</Typography>
                <br />
                <CouponList handleClick={handleOpenPromotion} items={promotions as Promotion[]} />
                <br />
              </Grid>
            </ProductGrid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}

        <ProductGrid className="selector" container>
          {isShowDescriptionSection && (
            <>
              <Grid item xs={11} onClick={() => setIsOpenDescriptionPopup(true)}>
                <Typography variant="dark-title-2">{t("product.description")}</Typography>
              </Grid>
              <SectionIcon item xs={1} onClick={() => setIsOpenDescriptionPopup(true)}>
                <Icon name={IconName.NEXT} />
              </SectionIcon>
              <ProductGrid className="divider" item xs={12}>
                <Divider />
              </ProductGrid>
            </>
          )}
          {isShowConditionSection && (
            <>
              <Grid item xs={11} onClick={() => setIsOpenConditionPopup(true)}>
                <Typography variant="dark-title-2">{t("product.conditions")}</Typography>
              </Grid>
              <SectionIcon item xs={1} onClick={() => setIsOpenConditionPopup(true)}>
                <Icon name={IconName.NEXT} />
              </SectionIcon>
              <ProductGrid className="divider" item xs={12}>
                <Divider />
              </ProductGrid>
            </>
          )}
        </ProductGrid>

        <ProductGrid container className="no-space">
          <Grid item xs={12}>
            <Typography variant="dark-title-2">{t("product.shippingMethod.header")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ShippingMethod shippings={shippings} />
          </Grid>
        </ProductGrid>
      </Body>
    </BodyWrapper>
  );
};
