import styled from "styled-components";

import Typography from "components/Typography";

const promotionMapSize = {
  S: {
    padding: "2px 4px",
  },
  M: {
    padding: "4px",
  },
};

type SizeType = "S" | "M";

type BadgePropsType = {
  bgColor?: string;
  size?: SizeType;
};

type PromotionTextPropsType = {
  size?: SizeType;
};

const TextEllipsis = styled(Typography)`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Badge = styled.div<BadgePropsType>`
  display: inline-block;
  max-width: 160px;
  padding: ${({ size }) => (size ? promotionMapSize[size].padding : promotionMapSize.M.padding)};
  border-radius: 3px;
  background-color: ${({ bgColor, theme }) => bgColor || theme.COLORS.Primary};
  margin-right: 4px;
  :last-child {
    margin: 0;
  }
`;

export const PromotionText = styled(TextEllipsis)<PromotionTextPropsType>`
  letter-spacing: 0.25px;
`;
