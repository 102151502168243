/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import ErrorPage from "containers/error";

type ErrorBoundariesPropTypes = {
  error: any;
  resetErrorBoundary: any;
};

const ErrorFallback: FC<ErrorBoundariesPropTypes> = ({ error, resetErrorBoundary }) => {
  return <ErrorPage error={error} handleClick={resetErrorBoundary} />;
};

export default ErrorFallback;
