import { gql } from "@apollo/client";

export const SELECT_PRODUCTS_FRAGMENT = gql`
  fragment SelectedProductSchema on SelectedProductSchema {
    id
    createdAt
    updatedAt
    projectId
    productId
    productCode
    isActive
    isDeleted
    isFree
    image
    price
    variableTypes {
      key
      value
    }
    weight
    cfCode
    stock
    dimension
    product {
      id
      name
      images {
        src
      }
    }
  }
`;

export const FREE_PRODUCTS_FRAGMENT = gql`
  fragment FreeProductSchema on FreeProductSchema {
    id
    createdAt
    updatedAt
    projectId
    productId
    productCode
    isActive
    isDeleted
    isFree
    image
    price
    variableTypes {
      key
      value
    }
    weight
    cfCode
    stock
    dimension
    amount
    product {
      id
      name
      images {
        src
      }
    }
  }
`;

export const PROMOTION_FRAGMENT = gql`
  ${SELECT_PRODUCTS_FRAGMENT}
  ${FREE_PRODUCTS_FRAGMENT}
  fragment PromotionSchema on PromotionSchema {
    id
    projectId
    campaignId
    name
    description
    shortName
    type
    isIncludedAllProducts
    setting {
      awardFromLowToHigh
      type
      condition
      criteria {
        operator
        thresholds {
          ... on PromotionAmountThresholdSchema {
            amount
          }
          ... on PromotionPriceThresholdSchema {
            price
          }
        }
        discount {
          ... on PromotionAmountDiscountSchema {
            amount
          }
          ... on PromotionFixedDiscountSchema {
            fixed
          }
          ... on PromotionPercentageDiscountSchema {
            percent
          }
        }
        freeProducts {
          ...FreeProductSchema
        }
      }
      awardTotalPrice
      isBundled
      threshold {
        ... on PromotionAmountThresholdSchema {
          amount
        }
        ... on PromotionPriceThresholdSchema {
          price
        }
      }
      discount {
        ... on PromotionAmountDiscountSchema {
          amount
        }
        ... on PromotionFixedDiscountSchema {
          fixed
        }
        ... on PromotionPercentageDiscountSchema {
          percent
        }
      }
      shippingMethod
    }
    isActive
    isDeleted
    priority
    freeProducts {
      ...FreeProductSchema
    }
    selectedProducts {
      ...SelectedProductSchema
    }
    startDate
    endDate
  }
`;

export const PROMOTION_CART_FRAGMENT = gql`
  ${SELECT_PRODUCTS_FRAGMENT}
  ${FREE_PRODUCTS_FRAGMENT}
  fragment CartPromotionSchema on CartPromotionSchema {
    projectId
    campaignId
    name
    description
    shortName
    type
    setting {
      awardFromLowToHigh
      type
      condition
      criteria {
        operator
        thresholds {
          ... on PromotionAmountThresholdSchema {
            amount
          }
          ... on PromotionPriceThresholdSchema {
            price
          }
        }
        discount {
          ... on PromotionAmountDiscountSchema {
            amount
          }
          ... on PromotionFixedDiscountSchema {
            fixed
          }
          ... on PromotionPercentageDiscountSchema {
            percent
          }
        }
        freeProducts {
          ...FreeProductSchema
        }
      }
      awardTotalPrice
      isBundled
      threshold {
        ... on PromotionAmountThresholdSchema {
          amount
        }
        ... on PromotionPriceThresholdSchema {
          price
        }
      }
      discount {
        ... on PromotionAmountDiscountSchema {
          amount
        }
        ... on PromotionFixedDiscountSchema {
          fixed
        }
        ... on PromotionPercentageDiscountSchema {
          percent
        }
      }
      shippingMethod
    }
    isActive
    isDeleted
    priority
    freeProducts {
      ...FreeProductSchema
    }
    selectedProducts {
      ...SelectedProductSchema
    }
    startDate
    endDate
    totalDiscount
  }
`;

export const PHYSICAL_PRODUCT_SKU_FRAGMENT = gql`
  fragment PhysicalProductSKUSchema on PhysicalProductSKUSchema {
    id
    createdAt
    updatedAt
    projectId
    productId
    productCode
    isActive
    isDeleted
    isFree
    image
    price
    discountedPrice
    variableTypes {
      key
      value
    }
    weight
    cfCode
    stock
    reservedStock
    dimension
    promotions {
      ... on PromotionSchema {
        ...PromotionSchema
      }
    }
  }
`;

export const FOOD_PRODUCT_SKU_FRAGMENT = gql`
  fragment FoodProductSKUSchema on FoodProductSKUSchema {
    id
    createdAt
    updatedAt
    projectId
    productId
    productCode
    isActive
    isDeleted
    isFree
    image
    price
    discountedPrice
    variableTypes {
      key
      value
    }
    weight
    cfCode
    stock
    reservedStock
    dimension
    options {
      name
      isActive
      isMultipleChoices
      isRequired
      maximum
      choices {
        name
        price
        isActive
        isDefault
        isSelected
      }
    }
    promotions {
      ... on PromotionSchema {
        ...PromotionSchema
      }
    }
  }
`;

export const DIGITAL_PRODUCT_SKU_FRAGMENT = gql`
  fragment DigitalProductSKUSchema on DigitalProductSKUSchema {
    id
    createdAt
    updatedAt
    projectId
    productId
    productCode
    isActive
    isDeleted
    isFree
    image
    price
    discountedPrice
    variableTypes {
      key
      value
    }
    weight
    cfCode
    stock
    reservedStock
    dimension
    digitalContentOptions {
      outputType
      settings {
        ... on BaseDigitalContentSettings {
          availability {
            ... on NoExpiryAvailability {
              type
            }
            ... on PeriodAfterPurchasedAvailability {
              type
              settings {
                unit
                value
              }
            }
            ... on DateRangeAvailability {
              type
              settings {
                availableAt
                expiredAt
              }
            }
            ... on ExpiryDateAvailability {
              type
              settings {
                expiredAt
              }
            }
          }
        }
        ... on StaticDigitalContentSettings {
          content
        }
      }
    }
    promotions {
      ... on PromotionSchema {
        ...PromotionSchema
      }
    }
  }
`;

export const PHYSICAL_PRODUCT_FRAGMENT = gql`
  ${PHYSICAL_PRODUCT_SKU_FRAGMENT}
  ${PROMOTION_FRAGMENT}

  fragment PhysicalProductSchema on PhysicalProductSchema {
    id
    projectId
    name
    brand
    productCode
    cfCode
    description
    unit
    isDeleted
    isCustomSKU
    type
    defaultPrice

    minPrice
    maxPrice
    minDiscountedPrice
    maxDiscountedPrice

    weight
    dimension
    properties {
      id
      productId
      name
      value
      createdAt
      updatedAt
    }
    categories {
      id
      name
      description
      image
      properties {
        name
      }
      variations {
        name
      }
    }
    images {
      src
    }
    createdAt
    updatedAt
    promotions {
      ... on PromotionSchema {
        ...PromotionSchema
      }
    }
    productSKUs {
      ... on PhysicalProductSKUSchema {
        ...PhysicalProductSKUSchema
      }
    }
  }
`;

export const DIGITAL_PRODUCT_FRAGMENT = gql`
  ${DIGITAL_PRODUCT_SKU_FRAGMENT}
  ${PROMOTION_FRAGMENT}

  fragment DigitalProductSchema on DigitalProductSchema {
    id
    projectId
    name
    brand
    productCode
    cfCode
    description
    unit
    isDeleted
    isCustomSKU
    type
    defaultPrice

    minPrice
    maxPrice
    minDiscountedPrice
    maxDiscountedPrice

    weight
    dimension
    properties {
      id
      productId
      name
      value
      createdAt
      updatedAt
    }
    categories {
      id
      name
      description
      image
      properties {
        name
      }
      variations {
        name
      }
    }
    images {
      src
    }
    createdAt
    updatedAt
    additionalDescription
    promotions {
      ... on PromotionSchema {
        ...PromotionSchema
      }
    }
    productSKUs {
      ... on DigitalProductSKUSchema {
        ...DigitalProductSKUSchema
      }
    }
  }
`;

export const FOOD_PRODUCT_FRAGMENT = gql`
  ${FOOD_PRODUCT_SKU_FRAGMENT}
  ${PROMOTION_FRAGMENT}

  fragment FoodProductSchema on FoodProductSchema {
    id
    projectId
    name
    brand
    productCode
    cfCode
    description
    unit
    isDeleted
    isCustomSKU
    type
    defaultPrice

    minPrice
    maxPrice
    minDiscountedPrice
    maxDiscountedPrice

    weight
    dimension
    properties {
      id
      productId
      name
      value
      createdAt
      updatedAt
    }
    categories {
      id
      name
      description
      image
      properties {
        name
      }
      variations {
        name
      }
    }
    images {
      src
    }
    createdAt
    updatedAt
    promotions {
      ... on PromotionSchema {
        ...PromotionSchema
      }
    }
    productSKUs {
      ... on FoodProductSKUSchema {
        ...FoodProductSKUSchema
      }
    }
  }
`;
