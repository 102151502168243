import i18n, { Resource } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { Language } from "types";

import en from "./en";
import th from "./th";

const resources: Resource = {
  [Language.TH]: { translation: th },
  [Language.EN]: { translation: en },
};

const languages = [Language.TH, Language.EN];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: languages,
    nsSeparator: false, // char to split namespace from key (default is colon (:))
    fallbackLng: "th",
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
