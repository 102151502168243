export const en = {
  searchPlaceholder: "Search by product name",
  category: {
    seeAllItem: {
      label: "See all items",
    },
    seeItemInCategory: {
      label: "See all items in {{categoryName}}",
    },
  },
  footer: {
    cartButton: {
      items: {
        label: "Cart • {{item}} items",
      },
      totalPrice: {
        label: "฿{{totalPrice}}",
      },
    },
  },
};

export default en;
