import styled from "styled-components";

import DefaultCarousel from "components/Carousel";

import DefaultPopup from "components/Popup";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center !important;
  align-self: center;
  min-width: 32px;
  min-height: 20px;
  max-width: 45px;
  font-size: 8px;
  border-radius: 8px;
  z-index: 1;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(29, 29, 29, 0.56);

  &.full-screen {
    bottom: -36px;
  }
`;

export const PageText = styled.p`
  font-size: 8px;
  color: white;
`;

export const Carousel = styled(DefaultCarousel)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    width: 375px;
    height: 375px;
  }

  @media only screen and (max-width: 320px) {
    width: 320px;
    height: 320px;
  }

  div.swiper-wrapper {
    display: flex;
    align-items: center;
  }

  div.swiper-slide {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Image = styled.img`
  width: 600px;
  height: 600px;

  @media only screen and (max-width: 600px) {
    width: 375px;
    height: 375px;
  }

  @media only screen and (max-width: 320px) {
    width: 320px;
    height: 320px;
  }

  object-fit: contain !important;
  cursor: pointer;
`;

export const Thumbnail = styled(DefaultCarousel)`
  position: fixed;
  bottom: 24px;
  display: flex;
  width: calc(100vw - 40px);
  max-height: 80px;

  img {
    width: 100px;
    height: 100px;

    @media only screen and (max-width: 600px) {
      width: 48px;
      height: 48px;
    }

    object-fit: cover;
    border-radius: 2px;
  }

  div.swiper-slide {
    width: 100%;
    max-width: 375px;
    display: flex;
    align-items: center;
  }
`;

export const TransformBox = styled.div`
  position: relative;
  border-radius: 8px;
  bottom: 30px;
`;

export const Popup = styled(DefaultPopup)`
  background-color: ${({ theme }) => theme.COLORS.Gray};
`;

export const PopupBody = styled.div`
  width: 100vw;
  position: fixed;
  height: calc(100 * var(--vh, 1vh));
  top: 0;
  left: 0;

  div.swiper-container {
    margin: 0 20px;
  }
`;
