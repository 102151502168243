import styled from "styled-components";

import { COLORS } from "constants/color";

export const LoadingWrapper = styled.div`
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 70px;

  img {
    width: 100%;
    max-width: 500px;
  }
`;

export const BackToChatButton = styled.button`
  max-width: 134px;
  height: 40px;
  margin: 16px;
  width: 100%;
  font-size: 14px;
  color: ${COLORS.White};
  background-color: ${COLORS.Primary};
  box-shadow: none;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
`;

export const SupportMobileWrapper = styled.div`
  .logo {
    width: 100%;
    max-width: 165px;
    justify-self: center;
    text-align: center;
  }

  .success,
  .fail {
    width: 100%;
    max-width: 100px;
    justify-self: center;
    text-align: center;
  }

  .banner {
    width: 100%;
    min-width: 285px;
    max-width: 500px;
  }
`;
