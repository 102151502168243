import { FC } from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";
import Icon from "components/Icon";

import { ProductSKU } from "types/product";

import { ButtonColor } from "types/button";
import { IconName, IconSize } from "types/icon";

import { FooterButton } from "./FooterButton.styled";

import { COLORS } from "constants/color";

export interface FooterButtonPropTypes {
  isEditProduct: boolean;
  quantity: number;
  selectedProductSKU?: ProductSKU;
  total?: string | number;
  handleAddToCart: () => void;
  handleUpdateCart: () => void;
  handleRemoveProductFromCart: () => void;
  isUpdatingCart: boolean;
}

const FooterButtonComponent: FC<FooterButtonPropTypes> = ({
  isEditProduct,
  quantity,
  selectedProductSKU,
  total = 0,
  handleAddToCart,
  handleUpdateCart,
  handleRemoveProductFromCart,
  isUpdatingCart,
}) => {
  const { t } = useTranslation();

  if (isEditProduct) {
    if ((!selectedProductSKU || quantity <= 0) && !isUpdatingCart) {
      return (
        <FooterButton color={ButtonColor.ERROR} handleClick={() => handleRemoveProductFromCart()}>
          <Icon name={IconName.INVERT_TRASH} size={IconSize.SMALL} viewBox="0 0 48 48" color={COLORS.LightWhite} />
          <Typography variant="white-title-2">{t("product.footer.button.remove")}</Typography>
        </FooterButton>
      );
    }

    return (
      <FooterButton disabled={isUpdatingCart} color={ButtonColor.PRIMARY} handleClick={() => handleUpdateCart()}>
        <Icon name={IconName.INVERT_CART} size={IconSize.SMALL} viewBox="0 0 48 48" color={COLORS.LightWhite} />
        <Typography variant="white-title-2">
          {isUpdatingCart
            ? t("product.footer.button.updateCart")
            : t("product.footer.button.updateCartWithPrice", { total })}
        </Typography>
      </FooterButton>
    );
  }

  const isDisabledButton = Boolean(!selectedProductSKU || quantity <= 0);

  return (
    <FooterButton disabled={isDisabledButton} color={ButtonColor.PRIMARY} handleClick={() => handleAddToCart()}>
      <Icon name={IconName.CART} size={IconSize.SMALL} viewBox="0 0 48 48" color={COLORS.LightWhite} />
      <Typography variant="white-title-2">
        {isDisabledButton
          ? t("product.footer.button.addToCart")
          : t("product.footer.button.addToCartWithPrice", { total })}
      </Typography>
    </FooterButton>
  );
};

export default FooterButtonComponent;
