import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { DefaultProps } from "types";

import { useHistory } from "react-router-dom";

import CartProductList from "containers/cart/CartProductList";

import Alert from "components/Alert";
import Typography from "components/Typography";
import Icon from "components/Icon";
import Header from "components/Header";

import PopupComponent from "components/Popup";

import useRefElement from "hooks/useRefElement";
import useCart from "hooks/useCart";

import messengerService from "services/MessengerService";

import { convertPriceFormat } from "utilities/product/convertPriceFormat";

import { getPage } from "utilities/page";

import { IconSize, IconName } from "types/icon";

import { PopupType } from "types/popup";

import {
  Wrapper,
  ItemBox,
  SubmitButton,
  Body,
  Footer,
  FooterDetail,
  EmptyCartWrapper,
  IconText,
  FooterWrapper,
  CartTitleWrapper,
} from "./styled";

import { ButtonColor } from "../../types/button";

import { MESSAGE } from "constants/message";

import { COLORS } from "constants/color";
import { PAGE } from "constants/page";

const homePage = getPage(PAGE.Home);

const Cart: FC<DefaultProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { refElement: headerRef, height: headerHeight } = useRefElement();
  const { refElement: footerRef, height: footerHeight } = useRefElement();

  const { cart, checkoutCart, removeCart, refetchCart, checkoutLoading } = useCart();
  const { productTotal, grandTotal, cartProducts, loading } = cart || {};
  const [isOpenRemovePopup, setIsOpenRemovePopup] = useState(false);
  const [deletedProductId, setDeletedProductId] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);

  const hasNoItem = productTotal <= 0;
  const grandTotalPrice = convertPriceFormat(grandTotal);

  const handleClickFooter = useCallback(async () => {
    if (hasNoItem) {
      history.push(homePage);
      return;
    }

    await checkoutCart();
    setIsSuccessful(true);
  }, [checkoutCart, hasNoItem, history]);

  const handleAddItem = useCallback(() => {
    history.push(homePage);
  }, [history]);

  const handleRemoveProductFromCart = useCallback(async () => {
    if (deletedProductId && deletedProductId !== "") {
      await removeCart(deletedProductId);
      messengerService.sendMessages(MESSAGE.CART_LIST);
      await refetchCart();
    }

    setDeletedProductId("");
    setIsOpenRemovePopup(false);
  }, [deletedProductId, refetchCart, removeCart]);

  const handleDeleteItem = useCallback((id: string) => {
    setDeletedProductId(id);
    setIsOpenRemovePopup(true);
  }, []);

  if (loading || checkoutLoading) {
    return <PopupComponent type={PopupType.LOADING} />;
  }

  return (
    <>
      <Header>
        <div ref={headerRef}>
          <Wrapper container alignItems="center">
            <CartTitleWrapper item container alignItems="center" spacing={1} className="flex-grow-1">
              <Typography variant="dark-title-1">{t("cart.header.label", { items: productTotal })}</Typography>
            </CartTitleWrapper>
          </Wrapper>
          <ItemBox
            className={hasNoItem ? "hidden" : ""}
            item
            container
            xs={12}
            alignItems="center"
            justify="space-between"
          >
            <Grid item className="flex-grow-0">
              <Typography variant="dark-title-7" className="text-right">
                {t("cart.header.items.label", { items: productTotal })}
              </Typography>
            </Grid>
            <Grid item onClick={handleAddItem}>
              <Typography variant="dark-body-4">{t("cart.header.store.addItem.label")}</Typography>
              <Icon name={IconName.NEXT} size={IconSize.MEDIUM} />
            </Grid>
          </ItemBox>
        </div>
      </Header>
      <Body header={headerHeight || 0} footer={footerHeight || 0}>
        {hasNoItem ? (
          <EmptyCartWrapper>
            <Grid container alignItems="center" justify="center">
              <EmptyCartWrapper item xs={12}>
                <Icon name={IconName.EMPTY_CART} size={IconSize.EXTRA_LARGE} color={COLORS.LightGray} />
              </EmptyCartWrapper>
              <Grid item xs={12}>
                <IconText variant="gray-title-1">{t("cart.body.emptyCart")}</IconText>
              </Grid>
            </Grid>
          </EmptyCartWrapper>
        ) : (
          <CartProductList handleDeleteItem={handleDeleteItem} products={cartProducts} />
        )}
      </Body>

      <Footer>
        <FooterWrapper ref={footerRef}>
          <FooterDetail container spacing={2} justify="flex-start" alignItems="center">
            {Boolean(!hasNoItem) && (
              <>
                <Grid item xs={7}>
                  <Typography variant="dark-title-4">{t("cart.footer.total.label")}</Typography>
                  <Typography variant="dark-body-4">{t("cart.footer.total.shipping")}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="dark-title-2">
                    {t("cart.footer.total.price", { total: grandTotalPrice })}
                  </Typography>
                </Grid>
              </>
            )}
            {!grandTotal && (
              <Grid item xs={12}>
                <Alert severity="warning">{t("cart.footer.alert.warning.zeroValue")}</Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <SubmitButton disabled={!grandTotal || isSuccessful} handleClick={handleClickFooter}>
                <Icon name={IconName.CART} size={IconSize.SMALL} color={COLORS.LightWhite} />
                <Typography variant="white-title-2" className="pl-2">
                  {t(hasNoItem ? "cart.footer.submitButton.shopNow" : "cart.footer.submitButton.checkout")}
                </Typography>
              </SubmitButton>
            </Grid>
          </FooterDetail>
        </FooterWrapper>
      </Footer>

      {/* popup */}
      <PopupComponent
        type={PopupType.CONFIRM}
        isOpen={isOpenRemovePopup}
        handleConfirmPopup={handleRemoveProductFromCart}
        onClose={() => setIsOpenRemovePopup(false)}
        confirmButtonColor={ButtonColor.ERROR}
        confirmText={t("common.button.remove.label")}
        cancelText={t("common.button.cancel.label")}
      >
        {t("product.removeProduct.title")}
      </PopupComponent>
    </>
  );
};

export default Cart;
