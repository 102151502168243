import styled from "styled-components";
import { Grid } from "@material-ui/core";

import Typography from "components/Typography";

export const BodyWrapper = styled.div`
  position: relative;
  height: calc(100 * var(--vh, 1vh) - 85px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Body = styled.div`
  padding: 12px 16px;
`;

export const DiscountPriceText = styled(Typography)`
  &.line-through {
    text-decoration: line-through;
  }
`;

export const DigitalContentBox = styled(Grid)`
  width: 100%;
  text-align: left;
  background-color: ${({ theme }) => theme.COLORS.LightGray};
  padding: 15px;
  border-radius: 4px;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const SectionIcon = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const PriceWrapper = styled(Grid)`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin: 0;

  h2 + h1,
  h6 + h4 {
    margin-left: 5px;
  }
`;

export const ProductGrid = styled(Grid)`
  width: 100%;
  margin: 20px 0 0 0;
  justify-content: flex-start;

  &.title {
    margin-top: 8px !important;
  }

  &.MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 4px 0;
  }

  &.selector {
    margin-top: 16px;
  }

  &.divider {
    margin: 20px 0;
  }

  &.no-space {
    margin: 0;
  }
`;

export const SectionWrapper = styled(Grid)`
  margin-top: 16px;

  &.selector {
    margin-top: 16px;
  }
`;

export const CounterWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-top: 8px;

  div:first-child {
    max-width: 45%;
  }

  div + p {
    margin-left: 16px;
  }
`;

export const BadgeWrapper = styled(Grid)`
  margin-bottom: 8px;
`;
