import { FC, useLayoutEffect, useRef } from "react";

import { Category } from "types/category";

import { BreadcrumbWrapper, SliderWrapper, Slider, SliderText } from "./styled";

export interface Swiper {
  slideTo: (index: number) => void;
}

const STICKY_HEIGHT = 75;

interface StickyBreadcrumbPropsType {
  categories: Category[];
  onInit: (swiper: Swiper) => void;
  activeCategoryId: string;
  prefixId?: string;
  targetRef: HTMLElement | null;
  setPrevScrollTopRef: (scrollTo: number) => void;
}

const CAROUSEL_ITEM_SPACING = 10;

const StickyBreadcrumb: FC<StickyBreadcrumbPropsType> = ({
  categories,
  activeCategoryId,
  onInit,
  prefixId,
  targetRef,
  setPrevScrollTopRef,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const initWrapper = () => {
    const swiper = {
      slideTo: (_index: number) => {},
    };
    if (wrapperRef.current) {
      const wrapperEle = wrapperRef.current;
      const childNodes = wrapperEle.children as HTMLCollection;
      const wrapperWidth = wrapperRef.current?.clientWidth || 0;
      let indexOfChildrenThatOverWrapperWidth = -1;
      let sumChildNodeWidth = 0;
      for (let i = 0; i < childNodes.length; i += 1) {
        sumChildNodeWidth += childNodes[i].clientWidth + CAROUSEL_ITEM_SPACING * 2;
        if (sumChildNodeWidth > wrapperWidth) {
          indexOfChildrenThatOverWrapperWidth = i;
          break;
        }
      }
      if (childNodes.length && indexOfChildrenThatOverWrapperWidth !== -1) {
        swiper.slideTo = (index) => {
          let slidePx = 0;
          for (let i = indexOfChildrenThatOverWrapperWidth; i <= index; i += 1) {
            slidePx += childNodes[i].clientWidth + CAROUSEL_ITEM_SPACING * 2;
          }

          if (wrapperEle) {
            if (wrapperEle?.scrollTo) {
              wrapperEle.scrollTo({
                top: 0,
                left: slidePx,
                behavior: "smooth",
              });
            } else {
              // to support android case
              wrapperEle.scrollTop = 0;
              wrapperEle.scrollLeft = slidePx;
            }
          }
        };
      }

      onInit(swiper);
    }
  };

  useLayoutEffect(() => {
    initWrapper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickBreadcrumb = (category: Category) => {
    if (prefixId) {
      const sectionTarget = document.getElementById(`${prefixId}${category.id}`);
      const breadcrumbHeight = wrapperRef?.current?.offsetHeight || 0;
      if (sectionTarget && targetRef) {
        setPrevScrollTopRef(sectionTarget.offsetTop - breadcrumbHeight - STICKY_HEIGHT + 1);
        targetRef.scrollTo(0, sectionTarget.offsetTop - breadcrumbHeight - STICKY_HEIGHT);
      }
    }
  };

  return (
    <BreadcrumbWrapper ref={wrapperRef}>
      {categories.map((category) => {
        const isActive = activeCategoryId === `${category.id}`;
        return (
          <SliderWrapper
            key={category.id}
            onClick={() => {
              onClickBreadcrumb(category);
            }}
            active={isActive}
          >
            <Slider active={isActive}>
              <SliderText active={isActive} variant={isActive ? "dark-title-4" : "gray-body-1"}>
                {category.name}
              </SliderText>
            </Slider>
          </SliderWrapper>
        );
      })}
    </BreadcrumbWrapper>
  );
};

StickyBreadcrumb.displayName = "StickyBreadcrumb";

export default StickyBreadcrumb;
