import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const InfoIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 -1 32 32", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9999 1.77772C8.14523 1.77772 1.77773 8.14523 1.77773 15.9999C1.77773 23.8547 8.14523 30.2222 15.9999 30.2222C23.8547 30.2222 30.2222 23.8547 30.2222 15.9999C30.2222 8.14523 23.8547 1.77772 15.9999 1.77772ZM14.1957 9.42727C14.1957 8.91732 14.371 8.48705 14.7216 8.13646C15.0722 7.78587 15.4945 7.61058 15.9885 7.61058C16.4984 7.61058 16.9287 7.78587 17.2793 8.13646C17.6299 8.48705 17.8052 8.91732 17.8052 9.42727C17.8052 9.93722 17.6299 10.3635 17.2793 10.7061C16.9287 11.0488 16.4984 11.2201 15.9885 11.2201C15.4945 11.2201 15.0722 11.0488 14.7216 10.7061C14.371 10.3635 14.1957 9.93722 14.1957 9.42727ZM14.6259 24.3891L14.6259 12.7479L17.3748 12.7479L17.3748 24.3891L14.6259 24.3891Z"
        fill={color}
      />
    </SVG>
  );
};

export default InfoIcon;
