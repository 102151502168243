import styled from "styled-components";

import DefaultButton from "components/Button";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 9px;
  width: 100%;
  padding: 15px;
  background-color: transparent;
`;

export const Button = styled(DefaultButton)`
  width: 100%;
  padding: 15px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 40%) !important;
`;
