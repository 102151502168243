import { FC } from "react";
import { Grid } from "@material-ui/core";

import { ProductProperty } from "types";

import Typography from "components/Typography";
import { Divider } from "components/Divider/styled";

type DescriptionPropTypes = {
  readonly productProperties?: ProductProperty[];
  description: string;
  title?: string;
};

const Description: FC<DescriptionPropTypes> = ({ title, productProperties = [], description }) => {
  const hasProductProperty = productProperties && productProperties?.length > 0;

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      {title && (
        <Grid item xs={12}>
          <Typography variant="dark-title-2">{title}</Typography>
        </Grid>
      )}
      {hasProductProperty && (
        <>
          {productProperties.map(({ name, value }) => (
            <>
              <Grid item xs={4}>
                <Typography variant="dark-title-6">{name}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="gray-body-2">{value}</Typography>
              </Grid>
            </>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="dark-paragraph-2">{description}</Typography>
      </Grid>
    </Grid>
  );
};

export default Description;
