import styled from "styled-components";

export const LoadingWrapper = styled.div`
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 70px;

  img {
    width: 90%;
    max-width: 500px;
  }
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: calc((100 * var(--vh, 1vh)) - 73px);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
