import { FC } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import Icon from "components/Icon";

import { CATEGORY_WITH_PRODUCTS } from "graphql/category";

import { getProductStock } from "utilities/product/productStock";

import { getPage } from "utilities/page";

import { IconName, IconSize } from "types/icon";
import { Product } from "types/product";
import { Category } from "types/category";

import { Wrapper, Header, ButtonLink, Link, Typography, ButtonIconWrapper, GridWrapper, Title } from "./styled";

import ProductCard from "../ProductCard";
import LoadCard from "../LoaderCard";

import { PAGE } from "constants/page";

interface GroupProductCategoryPropsType {
  category: Category;
  id: string;
}

const LIMIT = 10;

const GroupProductCategory: FC<GroupProductCategoryPropsType> = ({ category, id }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(CATEGORY_WITH_PRODUCTS, {
    variables: { id: category.id, queryOption: { limit: LIMIT, offset: 0 } },
    fetchPolicy: "cache-first",
  });
  const products = data?.category?.products;
  const isShowSeeMore = products?.total > LIMIT;

  return (
    <Wrapper id={id}>
      <Header>
        <Title variant="dark-title-1">{category.name}</Title>
        {isShowSeeMore && (
          <Link to={getPage(PAGE.Category, { id: category.id })}>
            <Typography variant="dark-title-5">
              {t("home.category.seeAllItem.label")}{" "}
              <Icon name={IconName.NEXT} size={IconSize.SMALL} viewBox="-20 -12 48 48" />
            </Typography>
          </Link>
        )}
      </Header>
      <GridWrapper>
        {loading ? (
          <Grid container spacing={2}>
            {Array.from(Array(10).keys()).map((i) => (
              <Grid xs={6} sm={4} md={3} item key={i}>
                <LoadCard uniqueKey={id} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {products?.results?.slice(0, LIMIT).map((product: Product) => {
              const isOutOfStock = getProductStock(product.productSKUs) === 0;
              return (
                <Grid xs={6} sm={4} md={3} item key={product.id}>
                  <Link to={getPage(PAGE.Product, { productId: product.id })}>
                    <ProductCard product={product} isOutOfStock={isOutOfStock} />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        )}

        {isShowSeeMore && (
          <Link to={getPage(PAGE.Category, { id: category.id })}>
            <ButtonLink fullWidth className="mt-3">
              <Typography variant="dark-title-6">
                {t("home.category.seeItemInCategory.label", { categoryName: category.name })}
              </Typography>
              <ButtonIconWrapper>
                <Icon name={IconName.NEXT} size={IconSize.SMALL} viewBox="-20 -10 48 48" />
              </ButtonIconWrapper>
            </ButtonLink>
          </Link>
        )}
      </GridWrapper>
    </Wrapper>
  );
};

export default GroupProductCategory;
