import { gql } from "@apollo/client";

import { DIGITAL_PRODUCT_FRAGMENT, PHYSICAL_PRODUCT_FRAGMENT } from "../product/fragments";

export const ALL_CATEGORY = gql`
  query categories {
    categories {
      id
      createdAt
      updatedAt
      name
      description
      image
    }
  }
`;

export const CATEGORY_WITH_PRODUCTS = gql`
  query category($id: ID!, $queryOption: CategoryProductQueryOption) {
    category(id: $id) {
      id
      createdAt
      updatedAt
      name
      description
      image
      products(queryOption: $queryOption) {
        results {
          ...PhysicalProductSchema
          ...DigitalProductSchema
        }
        total
      }
    }
  }
  ${PHYSICAL_PRODUCT_FRAGMENT}
  ${DIGITAL_PRODUCT_FRAGMENT}
`;
