import { API_BASE_URL } from "config";

// GraphQL libs
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import { ApolloClient, from, InMemoryCache, NormalizedCacheObject, ApolloLink, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import fetch from "cross-fetch";
import { setContext } from "@apollo/client/link/context";

import { getProjectIdAndToken, isTokenExpired } from "utilities/token";

let apolloClient: ApolloClient<NormalizedCacheObject>;

function getApolloClient() {
  if (apolloClient) {
    return apolloClient;
  }

  const projectIdAndToken = getProjectIdAndToken();
  const projectId = get(projectIdAndToken, "projectId");
  const token = get(projectIdAndToken, "token");

  const isExpiredToken = isTokenExpired(token as string);

  // to trigger error boundaries
  try {
    if (isExpiredToken) {
      throw new Error("Token Expired");
    }
  } catch (err) {
    console.error(err);
  }

  // Create an http link:
  const rawHttpLink = createHttpLink({ uri: `${API_BASE_URL}/graphql`, fetch });

  const authLink = setContext(() => {
    return {
      headers: {
        "x-project-id": projectId,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }

    if (networkError) console.error(`${networkError}`);
  });

  // rawHttpLink need to be last
  const combineHttpLink = from([authLink, errorLink, rawHttpLink as unknown as ApolloLink]);

  const cache = new InMemoryCache({
    possibleTypes: {
      ProductSKUSchema: ["DigitalProductSKUSchema", "PhysicalProductSKUSchema", "FoodProductSKUSchema"],
    },
    typePolicies: {
      CategorySchema: {
        fields: {
          products: {
            keyArgs(args) {
              if (args?.queryOption?.keyword) {
                return args.queryOption.keyword;
              }
              return false;
            },
            merge(existing = { results: [] }, incoming) {
              const incomingResults = incoming?.results || [];
              return {
                ...incoming,
                results: uniqBy([...existing.results, ...incomingResults], "__ref"),
              };
            },
          },
        },
      },
      Query: {
        fields: {
          products: {
            keyArgs: ["productFilterInput", ["keyword", "productIds", "promotionIds", "categoryId"]],
            merge(existing = { results: [] }, incoming) {
              const incomingResults = incoming?.results || [];
              return {
                ...incoming,
                results: uniqBy([...existing.results, ...incomingResults], "__ref"),
              };
            },
          },
        },
      },
    },
  });

  // Finally, create your ApolloClient instance with the modified network interface
  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== "production",
    link: combineHttpLink,
    cache,
  });
}

export default { getApolloClient };
