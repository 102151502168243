import { FC } from "react";

import { ButtonColor, ButtonType } from "types/button";

import { Button } from "./styled";

type DefaultButtonType = "button" | "submit" | "reset";

type ButtonPropTypes = {
  variant?: ButtonType;
  color?: ButtonColor | string; // support 2 case => apply with theme and fix color
  disabled?: boolean;
  className?: string;
  type?: DefaultButtonType;
  handleClick?: () => void;
};

const ButtonComponent: FC<ButtonPropTypes> = ({
  children,
  color = ButtonColor.PRIMARY,
  variant = ButtonType.CONTAINED,
  disabled = false,
  type = "button",
  handleClick,
  ...props
}) => {
  return (
    <Button type={type} disabled={disabled} className={variant} palette={color} onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

Button.displayName = "Button";

export default ButtonComponent;
