import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const Wrapper = styled.div`
  height: calc(100 * var(--vh, 1vh));
  overflow-y: auto;
`;

export const HeaderWrapper = styled.div<{ shadow: boolean }>`
  position: sticky;
  top: 0;
  width: 100vw;
  z-index: 100;
  box-shadow: ${({ shadow, theme }) => (shadow ? theme.COLORS.DeepleBoxShadow : "")};
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
`;

export const TopSellingHeader = styled(Grid)`
  margin: 16px 24px;
  height: 40px;
  width: calc(100% - 40px);
  text-align: left;
`;
