import { FC } from "react";

import { IconSize, SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const CheckIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, ...rest } = props;

  return (
    <SVG className={size} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69806 17.3019 2.39999 12 2.39999C6.69806 2.39999 2.39999 6.69806 2.39999 12C2.39999 17.3019 6.69806 21.6 12 21.6ZM16.4485 10.4485C16.9172 9.97989 16.9172 9.22009 16.4485 8.75147C15.9799 8.28284 15.2201 8.28284 14.7515 8.75147L10.8 12.7029L9.24852 11.1515C8.77989 10.6828 8.02009 10.6828 7.55147 11.1515C7.08284 11.6201 7.08284 12.3799 7.55147 12.8485L9.95147 15.2485C10.4201 15.7172 11.1799 15.7172 11.6485 15.2485L16.4485 10.4485Z"
      />
    </SVG>
  );
};

CheckIcon.displayName = "CheckIcon";

CheckIcon.defaultProps = {
  color: COLORS.Primary,
  size: IconSize.DEFAULT,
  viewBox: "0 0 24 24",
};

export default CheckIcon;
