import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import VConsole from "vconsole";
import TagManager, { TagManagerArgs } from "react-gtm-module";

import App from "./App";
import { GTM_AUTH, GTM_ID, GTM_PREVIEW, IS_V_CONSOLE_ENABLED, SENTRY_DSN, SENTRY_ENV } from "./config";
import reportWebVitals from "./reportWebVitals";

// #region Sentry
Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
});
// #endregion

// #region VConsole
if (IS_V_CONSOLE_ENABLED) {
  // eslint-disable-next-line no-new
  const vConsole = new VConsole();
  console.log(`Running VConsole version: ${vConsole.version}`);
}
// #endregion

// #region Google tag manager
if (typeof GTM_ID === "string") {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: GTM_ID,
    auth: GTM_AUTH,
    preview: GTM_PREVIEW,
  };

  TagManager.initialize(tagManagerArgs);
}
// #endregion

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
