import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import "utilities-css/dist/utilities-css.css";

import "./typography.css";

export default createGlobalStyle`
  ${styledNormalize}

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent !important;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: "Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    background-color: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  #root {
    width: 100%;
    min-height: calc(100 * var(--vh, 1vh));
    overflow-x: hidden;
    overflow-y: auto;
  }

  input[type=text] {
    font-family: "Graphik";
    font-weight: normal;
    font-stretch: normal;
    line-height: 11;
    text-align: left;
    color: var(--dark);
  }

  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }
`;
