import { FC, useState, useLayoutEffect } from "react";
import { SwiperSlide } from "swiper/react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Popup from "components/Popup";

import useOrientation from "hooks/useOrientation";

import { Item } from ".";

import { Wrapper, Image, Thumbnail, TransformBox, PopupBody } from "./styled";

import { COLORS } from "constants/color";

interface ImagePopupPropTypes {
  items?: Item[];
  slide?: number;
  isOpen: boolean;
  page: number;
  setPage: (page: number) => void;
  handleClose: () => void;
}

const ImagePopup: FC<ImagePopupPropTypes> = ({ page = 0, setPage, items = [], slide = 1, isOpen, handleClose }) => {
  const [slidesPerView, setSlidesPerView] = useState(slide);
  const [selectedImage, setSelectedImage] = useState<Item>(items[page]);
  const { screenSize } = useOrientation();

  const handleClickThumbnail = (value: number, props: Item) => {
    setPage(value);
    setSelectedImage(props);
  };

  useLayoutEffect(() => {
    const divider = screenSize.width > 600 ? 130 : 80;
    setSlidesPerView(screenSize.width / divider);
  }, [screenSize]);

  useLayoutEffect(() => {
    setSelectedImage(items[page]);
  }, [page, items, setSelectedImage]);

  return (
    <Popup backgroundColor={COLORS.Gray} isOpen={isOpen} onClose={handleClose}>
      <PopupBody>
        <Wrapper>
          <TransformWrapper>
            <TransformBox>
              <TransformComponent>
                <Image className="carousel" src={selectedImage?.url} alt={selectedImage?.title} />
              </TransformComponent>
            </TransformBox>
          </TransformWrapper>
        </Wrapper>
        <Thumbnail
          slidesPerView={slidesPerView}
          freeMode
          watchSlidesVisibility
          watchSlidesProgress
          className="mySwiper"
        >
          {items.map((item, i) => {
            return (
              <SwiperSlide key={item.url}>
                <Image src={item.url} alt={item.title} onClick={() => handleClickThumbnail(i, item)} />
              </SwiperSlide>
            );
          })}
        </Thumbnail>
      </PopupBody>
    </Popup>
  );
};

ImagePopup.displayName = "ImagePopup";

export default ImagePopup;
