import { ColorsType } from "types/style";

import { COLORS } from "./color";

export interface ThemeType {
  default: ColorsType;
  darkMode: ColorsType;
}

export const THEME: ThemeType = {
  default: {
    ...COLORS,
  },
  darkMode: {
    Badge: COLORS.Badge,
    BoxShadow: COLORS.BoxShadow,
    ChatBadgeWrapper: COLORS.ChatBadgeWrapper,
    Dark: COLORS.Dark,
    DarkGray: COLORS.DarkGray,
    DarkLight: COLORS.DarkLight,
    DarkMed: COLORS.DarkMed,
    DisabledSuccess: COLORS.DisabledSuccess,
    Error: COLORS.Error,
    Info: COLORS.Info,
    InfoYellowBackground: COLORS.InfoYellowBackground,
    InfoYellowBorder: COLORS.InfoYellowBorder,
    InfoYellow: COLORS.InfoYellow,
    Light: COLORS.Light,
    LightBlue: COLORS.LightBlue,
    LightGray: COLORS.LightGray,
    LightWhite: COLORS.LightWhite,
    Primary: COLORS.Dark,
    PrimaryDark: COLORS.Dark,
    PrimaryNude: COLORS.PrimaryNude,
    Success: COLORS.Success,
    Warning: COLORS.Warning,
    TableShadow: COLORS.TableShadow,
    Facebook: COLORS.Facebook,
  },
};

export const THEME_TYPE = {
  default: THEME.default,
  darkMode: THEME.darkMode,
};
