import styled from "styled-components";
import { Link as DefaultLink } from "react-router-dom";

interface TextCenterPropsType {
  color?: string;
}

export const WrapperLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

export const TextCenter = styled.h3<TextCenterPropsType>`
  text-align: center;
  color: ${({ theme, color }) => (color ? theme.COLORS[color] || theme.COLORS.Primary : theme.COLORS.Primary)};
`;

export const WrapperEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: calc(35vh - 120px);
  margin-bottom: 50px;
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.Dark};
  :visited {
    color: ${({ theme }) => theme.COLORS.Dark};
  }
`;
