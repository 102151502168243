const common = {
  outOfStock: "Out of stock",
  header: { backToHome: { label: "Back to home" } },
  page: {
    product: {
      title: {
        label: "รายละเอียดสินค้า",
      },
    },
    cart: {
      title: {
        label: "รายการสั่งซื้อ",
      },
    },
    category: {
      title: {
        label: "เมนูสินค้า",
      },
    },
    categories: {
      title: {
        label: "เมนูสินค้า",
      },
    },
    "top-selling": {
      title: {
        label: "สินค้าขายดี",
      },
    },
    promotion: {
      title: {
        label: "โปรโมชั่น",
      },
    },
    payment: {
      title: {
        label: "การชำระเงิน",
      },
      qrCode: {
        title: {
          label: "คิวอาร์โค้ด",
        },
      },
      mobileBanking: {
        title: { label: "โอนเงินผ่านแอพธนาคาร" },
      },
      eWallet: {
        title: { label: "อีวอลเล็ท" },
      },
      creditCard: {
        title: { label: "บัตรเดบิต / เครดิต" },
      },
    },
  },
  button: {
    confirm: {
      label: "Confirm",
    },
    remove: {
      label: "Remove",
    },
    cancel: {
      label: "Cancel",
    },
  },
  error: {
    notFound: {
      label: "Error 404",
    },
  },
  actionText: {
    edit: {
      label: "Edit",
    },
  },
  search: {
    noResult: {
      label: "No result is found",
    },
  },
};

export default common;
