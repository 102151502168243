import { FC, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import { useQuery } from "@apollo/client";

import CartFooterButton from "containers/cart/CartFooterButton";

import InfiniteScroll from "containers/category/InfiniteScroll";

import HeaderWithSearch from "components/Header/HeaderWithSearch";

import { CATEGORY_WITH_PRODUCTS } from "graphql/category";

import useCart from "hooks/useCart";

import { getPage } from "utilities/page";

import { Category as CategoryType } from "types/category";

import { Wrapper } from "./styled";

import { PAGE } from "constants/page";

interface CategoryWithProductsData {
  category: CategoryType;
}

interface CategoryWithProductsVars {
  id?: string;
  queryOption?: {
    keyword?: string;
    offset?: number;
    limit?: number;
  };
}

const LIMIT = 10;

const Category: FC<RouteChildrenProps<{ id: string }>> = ({ match, history }) => {
  const { t } = useTranslation();
  const id = match?.params.id;
  const [keyword, setKeyword] = useState("");
  const { cart } = useCart();
  const { productTotal, grandTotal } = cart || {};
  const hasItemsInCart = productTotal > 0;

  const { data, fetchMore, loading, refetch } = useQuery<CategoryWithProductsData, CategoryWithProductsVars>(
    CATEGORY_WITH_PRODUCTS,
    {
      variables: {
        id,
        queryOption: {
          keyword,
          offset: 0,
          limit: LIMIT,
        },
      },
      fetchPolicy: "cache-first",
    },
  );

  const category = data?.category;
  const products = category?.products;

  const onSearch = debounce((text) => {
    setKeyword(text);
  }, 500);

  const loadMore = () => {
    if (fetchMore) {
      fetchMore({
        variables: {
          queryOption: {
            offset: data?.category?.products?.results?.length || 0,
            limit: LIMIT,
            keyword,
          },
        },
      });
    }
  };

  const refresh = () => {
    if (refetch) {
      refetch({
        queryOption: {
          offset: 0,
          limit: LIMIT,
          keyword,
        },
      });
    }
  };

  const hasMore = () => {
    if (data?.category?.products?.total && data?.category?.products?.results?.length) {
      return data?.category?.products.total > data?.category?.products.results.length;
    }
    return true;
  };

  if (!id) {
    history.push(getPage(PAGE.Error));
    return null;
  }

  return (
    <Wrapper id="category-container">
      <HeaderWithSearch
        onSearch={onSearch}
        title={category?.name || ""}
        subTitle={t("common.header.backToHome.label")}
        onClickSubTitle={() => history.push(getPage(PAGE.Home))}
        searchPlaceholder={t("category.searchPlaceholder", { category: category?.name || "" })}
      />
      <InfiniteScroll
        products={products?.results || []}
        loading={loading}
        loadMore={loadMore}
        refresh={refresh}
        hasMore={hasMore()}
        target="category-container"
      />
      <CartFooterButton hasItemsInCart={hasItemsInCart} grandTotal={grandTotal} productTotal={productTotal} />
    </Wrapper>
  );
};

export default Category;
