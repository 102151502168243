import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import { PRODUCTS } from "graphql/product";

import { Product } from "types/product";

const PRODUCT_LIMIT = 10;

interface InfiniteScrollPropsType {
  categoryId?: string;
  productIds?: ReadonlyArray<string>;
  promotionIds?: ReadonlyArray<string>;
  keyword?: string;
  isOnlyHavePromotion?: boolean;
}

interface ProductData {
  products: {
    results: ReadonlyArray<Product>;
    total?: number;
  };
}

interface ProductDataVariablesType {
  offset: number;
  limit: number;
  productFilterInput: {
    categoryId: string;
    productIds: ReadonlyArray<string>;
    promotionIds: ReadonlyArray<string>;
    keyword: string;
    isOnlyHavePromotion: boolean;
  };
}

const useInfiniteScroll = ({
  categoryId = "",
  productIds = [],
  promotionIds = [],
  keyword = "",
  isOnlyHavePromotion = false,
}: InfiniteScrollPropsType) => {
  const productFilterInput = {
    categoryId,
    productIds,
    promotionIds,
    keyword,
    isOnlyHavePromotion,
  };
  const { data, fetchMore, loading, refetch } = useQuery<ProductData, ProductDataVariablesType>(PRODUCTS, {
    variables: {
      offset: 0,
      limit: PRODUCT_LIMIT,
      productFilterInput,
    },
    fetchPolicy: "network-only",
  });
  const products = data?.products || { results: [] };

  const loadMore = () => {
    if (fetchMore) {
      fetchMore({
        variables: {
          offset: products?.results?.length || 0,
        },
      });
    }
  };

  const hasMore = () => {
    if (products?.total && products?.results?.length) {
      return products.total > products.results.length;
    }
    return true;
  };

  const refresh = () => {
    if (refetch) {
      refetch({
        offset: 0,
        limit: PRODUCT_LIMIT,
        productFilterInput,
      });
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return { loading, refresh, hasMore, products, loadMore };
};

export default useInfiniteScroll;
