import { ApolloError, useMutation } from "@apollo/client";

import { useState } from "react";

import get from "lodash/get";

import { GENERATE_QR_PAYMENT } from "graphql/payment/mutation";

import { CashQR, QRPaymentType } from "types/payment";

const useQRPayment = () => {
  const [qrCodeData, setQRCodeData] = useState<CashQR | null>();
  const [generateQRCode, { loading, error }] = useMutation<CashQR>(GENERATE_QR_PAYMENT, {
    onError: (err: ApolloError) => {
      console.error(err);
    },
  });

  const generateQRPayment = async (orderId: string, type: QRPaymentType) => {
    const data = await generateQRCode({
      variables: {
        type,
        orderId,
      },
    });
    const result = get(data, "data.generateQRPayment");
    setQRCodeData(result);
  };

  return { qrCodeData, loading, generateQRCode: generateQRPayment, error };
};

export default useQRPayment;
