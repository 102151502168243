import { Grid } from "@material-ui/core";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper } from "containers/error/styled";

import Typography from "components/Typography";

import MessengerService from "services/MessengerService";

import { SupportMobileWrapper, BackToChatButton } from "./styled";

import { ERROR_ORDER } from "constants/image";

const ErrorOrder: FC = () => {
  const { t } = useTranslation();

  const [isShowFooterButton, setIsShowFooterButton] = useState(false);

  const checkIsShowBankButton = useCallback(async () => {
    const isOpenWithMobileBrowser = await MessengerService.isOpenWithMobileBrowser();

    setIsShowFooterButton(isOpenWithMobileBrowser);
  }, []);

  useEffect(() => {
    checkIsShowBankButton();
  }, []);

  return (
    <Wrapper container spacing={2} justify="center" alignItems="center">
      <Grid item xs={12}>
        <SupportMobileWrapper>
          <img className="logo" src={ERROR_ORDER} alt="deeple-pay-logo" />
        </SupportMobileWrapper>
        <div className="pt-3 px-3">
          <Typography className="pt-1" variant="dark-title-3">
            {t(`payment.order.unavialable.label`)}
          </Typography>
          <Typography className="pt-1" variant="dark-body-3">
            {t(`payment.order.unavialable.description`)}
          </Typography>
        </div>
        {isShowFooterButton && (
          <div className="pt-5 px-3">
            <BackToChatButton onClick={() => MessengerService.closeWindow()}>
              {t("payment.qrCode.cash.footer.label")}
            </BackToChatButton>
          </div>
        )}
      </Grid>
    </Wrapper>
  );
};

export default ErrorOrder;
