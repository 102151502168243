import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const CartIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        className="cls-1"
        d="M39.85,31.39A8.6,8.6,0,0,0,43,27.05l4.95-14.46a1.81,1.81,0,0,0-1.14-2.28h0a2,2,0,0,0-.58-.09H11.64l-.41-4.47A6.24,6.24,0,0,0,9.18,1.65,6.41,6.41,0,0,0,4.87,0h-3A1.89,1.89,0,0,0,0,1.88H0A1.89,1.89,0,0,0,1.9,3.75h3A2.58,2.58,0,0,1,7.45,6.07l3,32.55v0a6.33,6.33,0,0,0,5.28,5.58,5.63,5.63,0,0,0,10.59.12h4.47a5.53,5.53,0,1,0,0-3.75H26.29a5.64,5.64,0,0,0-10.47-.2,2.55,2.55,0,0,1-1.61-2.11l-.47-5.18h21A8.59,8.59,0,0,0,39.85,31.39ZM36,40.69a1.78,1.78,0,1,1-1.8,1.78A1.79,1.79,0,0,1,36,40.69Zm-15,0a1.78,1.78,0,1,1-1.8,1.78A1.8,1.8,0,0,1,21,40.69ZM13.39,29.34h0L12,14H43.42L39.34,25.87v.05a4.91,4.91,0,0,1-4.63,3.42Z"
        transform="translate(0 0)"
      />
    </SVG>
  );
};

export default CartIcon;
