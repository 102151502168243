import { forwardRef, HTMLAttributes, createElement, ForwardRefExoticComponent } from "react";

type TypographyPropsType = { variant: TypographyVariant } & HTMLAttributes<HTMLDivElement>;

export type TypographyVariant =
  | "dark-title-1"
  | "dark-title-2"
  | "dark-title-3"
  | "dark-title-4"
  | "dark-title-5"
  | "dark-title-6"
  | "dark-title-7"
  | "dark-title-8"
  | "dark-body-1"
  | "dark-body-2"
  | "dark-body-3"
  | "dark-body-4"
  | "dark-body-5"
  | "dark-body-6"
  | "gray-title-1"
  | "gray-title-2"
  | "gray-title-3"
  | "gray-body-1"
  | "gray-body-2"
  | "gray-body-3"
  | "price-title-1"
  | "price-title-2"
  | "price-title-3"
  | "price-title-4"
  | "price-title-5"
  | "price-title-6"
  | "price-title-7"
  | "price-title-8"
  | "price-title-9"
  | "price-title-10"
  | "price-title-11"
  | "accent-body-1"
  | "accent-body-2"
  | "white-title-1"
  | "white-title-2"
  | "white-title-3"
  | "white-title-4"
  | "white-title-5"
  | "white-title-6"
  | "dark-paragraph-1"
  | "dark-paragraph-2";

type HtmlTypography = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "pre";

const variantMapping: Record<TypographyVariant, HtmlTypography> = {
  "dark-title-1": "h1",
  "dark-title-2": "h2",
  "dark-title-3": "h3",
  "dark-title-4": "h4",
  "dark-title-5": "h5",
  "dark-title-6": "h6",
  "dark-title-7": "h6",
  "dark-title-8": "h6",
  "dark-body-1": "p",
  "dark-body-2": "p",
  "dark-body-3": "p",
  "dark-body-4": "p",
  "dark-body-5": "p",
  "dark-body-6": "p",
  "gray-title-1": "h1",
  "gray-title-2": "h2",
  "gray-title-3": "h3",
  "gray-body-1": "p",
  "gray-body-2": "p",
  "gray-body-3": "p",
  "price-title-1": "h1",
  "price-title-2": "h2",
  "price-title-3": "h3",
  "price-title-4": "h4",
  "price-title-5": "h5",
  "price-title-6": "h5",
  "price-title-7": "h6",
  "price-title-8": "h6",
  "price-title-9": "h6",
  "price-title-10": "h6",
  "price-title-11": "h6",
  "accent-body-1": "p",
  "accent-body-2": "p",
  "white-title-1": "h1",
  "white-title-2": "h2",
  "white-title-3": "h3",
  "white-title-4": "h4",
  "white-title-5": "h5",
  "white-title-6": "h6",
  "dark-paragraph-1": "pre",
  "dark-paragraph-2": "pre",
};

const Typography: ForwardRefExoticComponent<TypographyPropsType> = forwardRef((props, ref) => {
  const { variant, className, ...rest } = props;

  const htmlTypography: HtmlTypography = variantMapping[variant];
  return createElement(htmlTypography, { ...rest, className: `typography ${variant} ${className}`, ref });
});

Typography.displayName = "Typography";

export default Typography;
