type CreditCardBrand = "VISA" | "MASTERCARD" | "JCB" | "AMERICAN_EXPRESS";
type InvoiceType = "MAIN" | "LOGISTIC" | "CREDIT_CARD";
type CreditTransactionType = "MAIN" | "LOGISTIC" | "CREDIT_CARD";
type TransactionType =
  | "ADDON"
  | "CREDIT_CARD"
  | "FREE_CREDIT"
  | "LALAMOVE"
  | "PACKAGE"
  | "PACKAGE_AND_ADDON"
  | "PROMO_CODE"
  | "SHIPPOP"
  | "TOP_UP";
type AgentType = "ADMIN" | "SYSTEM";

export interface CreditCardInput {
  name: string;
  number: string;
  expirationMonth: string;
  expirationYear: string;
  token: string;
  brand: CreditCardBrand;
  isPrimary: boolean;
}

export interface CreditCard {
  id: string;
  name: string;
  number: string;
  expirationMonth: string;
  expirationYear: string;
  token: string;
  brand: CreditCardBrand;
}

export interface Invoice {
  id: string;
  type: InvoiceType;
  startBillingDate: Date;
  endBillingDate: Date;
  invoiceNumber: string;
  totalPrice: number;
  vatPrice: number;
}

export interface CreditTransaction {
  id: string;
  projectId: string;
  type: CreditTransactionType;
  transactionType: TransactionType;
  transactionId: string;
  value: number;
  agent: string;
  agentType: AgentType;
  refId: string;
  createdAt: Date;
}

export enum AllowCardType {
  visa,
  mastercard,
  jcb,
}
