import styled from "styled-components";

import Button from "components/Button";

export const HomeButton = styled(Button)`
  max-width: 44px;
  max-height: 44px;
  padding: 12px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.COLORS.Primary};
  border-radius: 5px;
`;

export const CartButton = styled(Button)`
  position: fixed;
  bottom: 77px;
  right: 0;
  max-width: 44px;
  max-height: 44px;
  padding: 12px;
  margin: 16px;
  background-color: ${({ theme }) => theme.COLORS.Primary};
  border-radius: 50% !important;
  cursor: pointer;
  z-index: 100;
`;

export const CartBadge = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
  width: 12px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.Accent};
  border-radius: 5px;

  &.hidden {
    display: none;
  }
`;
