const en = {
  qrCode: {
    cash: {
      total: "Total: ",
      amount: "฿{{amount}}",
      description: {
        default: "Please scan QR code to make a transfer.",
        detail: "Screenshot this QR code to pay through mobile banking application",
        retry: "QR code error, please try again.",
        process: "Creating a QR code, please wait a moment.",
      },
      retry: {
        label: "Touch to retry",
      },
      popup: {
        saveQRSuccess: "The image has been saved to your device.",
      },
      footer: {
        label: "Back to chat",
      },
    },
  },
  mobileBanking: {
    type: {
      kPlus: {
        title: "KPLUS",
        description: "3-minute verification after pay",
      },
      kma: {
        title: "KMA",
        description: "3-minute verification after pay",
      },
      scbEasy: {
        title: "SCB easy",
        description: "3-minute verification after pay",
      },
    },
    footer: {
      button: {
        continue: "ชำระเงิน - ฿{{amount}}",
      },
    },
    error: {
      notSupportedDesktop: {
        title: "Not available on desktop",
        description: "You can access this page via the smartphone and tablet versions of Messenger.",
      },
    },
  },
  order: {
    unavialable: {
      label: "This order is unavailable",
      description: "You cannot complete the payment as this order is recently unavailable.",
    },
    expired: {
      label: "Unfortunately, your order has been expired.",
      description: "You have to re-order this in order to complete the payment.",
    },
  },
  success: {
    label: "Your payment is successful",
  },
  fail: {
    label: "Your payment failed",
    description: "Please try again.",
  },
};

export default en;
