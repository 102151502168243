import BackArrowIcon from "./BackArrowIcon";
import BackIcon from "./BackIcon";
import CartIcon from "./CartIcon";
import CircleRemove from "./CircleRemoveIcon";
import ClearIcon from "./ClearIcon";
import CloseIcon from "./CloseIcon";
import CreditCardIcon from "./CreditCardIcon";
import CreditCardJcbIcon from "./CreditCardJcbIcon";
import CreditCardMaster from "./CreditCardMasterIcon";
import CreditCardVisa from "./CreditCardVisaIcon";
import CreditCardOutline from "./CreditCardOutlineIcon";
import EmptyCartIcon from "./EmptyCartIcon";
import ErrorIcon from "./ErrorIcon";
import HomeIcon from "./HomeIcon";
import InfoIcon from "./InfoIcon";
import InvertCartIcon from "./InvertCartIcon";
import InvertMenuIcon from "./InvertMenuIcon";
import InvertPromotionDiscountIcon from "./InvertPromotionDiscountIcon";
import InvertTrashIcon from "./InvertTrashIcon";
import MenuIcon from "./MenuIcon";
import MinusIcon from "./MinusIcon";
import NextIcon from "./NextIcon";
import NotificationIcon from "./NotificationIcon";
import PickupIcon from "./PickupIcon";
import PlusIcon from "./PlusIcon";
import PromotionDiscountIcon from "./PromotionDiscountIcon";
import RemoveIcon from "./RemoveIcon";
import SearchIcon from "./SearchIcon";
import SelectedIcon from "./SelectedIcon";
import DownloadIcon from "./DownloadIcon";
import ReloadIcon from "./ReloadIcon";

export default {
  BACK_ARROW: BackArrowIcon,
  BACK: BackIcon,
  CART: CartIcon,
  CIRCLE_REMOVE: CircleRemove,
  CLEAR: ClearIcon,
  CLOSE: CloseIcon,
  CREDIT_CARD: CreditCardIcon,
  CREDIT_CARD_JCB: CreditCardJcbIcon,
  CREDIT_CARD_MASTER: CreditCardMaster,
  CREDIT_CARD_VISA: CreditCardVisa,
  CREDIT_CARD_OUTLINE: CreditCardOutline,
  EMPTY_CART: EmptyCartIcon,
  ERROR: ErrorIcon,
  HOME: HomeIcon,
  INFO: InfoIcon,
  INVERT_CART: InvertCartIcon,
  INVERT_MENU: InvertMenuIcon,
  INVERT_PROMOTION_DISCOUNT: InvertPromotionDiscountIcon,
  INVERT_TRASH: InvertTrashIcon,
  MENU: MenuIcon,
  MINUS: MinusIcon,
  NEXT: NextIcon,
  NOTIFICATION_ALERT: NotificationIcon,
  PICKUP: PickupIcon,
  PLUS: PlusIcon,
  PROMOTION_DISCOUNT: PromotionDiscountIcon,
  REMOVE: RemoveIcon,
  SEARCH: SearchIcon,
  SELECTED: SelectedIcon,
  DOWNLOAD: DownloadIcon,
  RELOAD: ReloadIcon,
};
