import { FC } from "react";
import classNames from "classnames";

import { IconSize, SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

const CreditCardMasterIcon: FC<SVGIconPropTypes> = (props) => {
  const { size = IconSize.DEFAULT, style, viewBox = "0 0 24 24", className = "" } = props;
  const iconClass = classNames({ [size]: size, [className]: className });
  return (
    <SVG className={iconClass} style={style} viewBox={viewBox}>
      <g>
        <path
          d="M22.5 3.75H1.5C0.671573 3.75 0 4.43558 0 5.28129V19.4687C0 20.3144 0.671573 21 1.5 21H22.5C23.3284 21 24 20.3144 24 19.4687V5.28129C24 4.43558 23.3284 3.75 22.5 3.75Z"
          fill="#EFF1F5"
        />
        <path d="M13.9657 9.23242H9.93066V16.4774H13.9657V9.23242Z" fill="#F16522" />
        <path
          d="M10.1857 12.855C10.1857 12.1571 10.3444 11.4684 10.6498 10.8408C10.9551 10.2132 11.3991 9.66329 11.9482 9.2325C11.2687 8.69919 10.4529 8.36775 9.59396 8.27604C8.73506 8.18432 7.86767 8.33602 7.09089 8.71382C6.3141 9.09162 5.65923 9.68028 5.20109 10.4126C4.74295 11.1448 4.5 11.9912 4.5 12.855C4.5 13.7188 4.74295 14.5652 5.20109 15.2974C5.65923 16.0297 6.3141 16.6184 7.09089 16.9962C7.86767 17.374 8.73506 17.5257 9.59396 17.434C10.4529 17.3423 11.2687 17.0108 11.9482 16.4775C11.3982 16.0475 10.9537 15.4977 10.6482 14.8699C10.3428 14.2422 10.1846 13.5531 10.1857 12.855Z"
          fill="#E41B24"
        />
        <path
          d="M19.4032 12.8549C19.4014 13.7187 19.157 14.5646 18.6979 15.2963C18.2389 16.0279 17.5836 16.616 16.8066 16.9935C16.0297 17.371 15.1624 17.5228 14.3035 17.4316C13.4446 17.3403 12.6285 17.0097 11.9482 16.4774C12.4972 16.0466 12.9411 15.4966 13.2463 14.869C13.5515 14.2415 13.7101 13.5528 13.7101 12.8549C13.7101 12.1571 13.5515 11.4684 13.2463 10.8408C12.9411 10.2132 12.4972 9.66326 11.9482 9.23241C12.6285 8.7001 13.4446 8.3695 14.3035 8.27825C15.1624 8.18701 16.0297 8.33878 16.8066 8.71629C17.5836 9.0938 18.2389 9.68188 18.6979 10.4136C19.157 11.1453 19.4014 11.9911 19.4032 12.8549ZM18.9607 15.7124V15.5399H19.0207H18.8707H18.9307V15.6899L18.9607 15.7124ZM19.2607 15.7124V15.5399H19.2157L19.1557 15.6599L19.1032 15.5399H19.0582V15.7199V15.5849L19.1107 15.6974H19.1482L19.1932 15.5849V15.7199L19.2607 15.7124Z"
          fill="#F89E1C"
        />
      </g>
    </SVG>
  );
};

export default CreditCardMasterIcon;
