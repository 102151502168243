import { FC } from "react";
import _ from "lodash";

import GroupOfSKUButton from "./GroupOfSKUButton";

import { VariantVariableTypeSelectorPropsType } from "../types";

const OneVariantVariableTypeSelector: FC<VariantVariableTypeSelectorPropsType> = ({
  productSKUWithVariableKey,
  onChange,
  primaryKey,
  setPrimaryKey,
}) => {
  const skuGroupByFirstType = _.groupBy(productSKUWithVariableKey, "variableTypes[0].value");

  const handleChange = (key: string) => {
    if (key) {
      const selectSKU = productSKUWithVariableKey.find((sku) => {
        return _.difference([key], sku.variableKey).length === 0;
      });
      return onChange(selectSKU, 1);
    }
    return onChange();
  };

  const onChangeSKU = (currentKey: string, incomingKey: string, setFunction: (key: string) => void) => {
    if (currentKey === incomingKey) {
      handleChange("");
      return setFunction("");
    }
    handleChange(incomingKey);
    return setFunction(incomingKey);
  };

  return (
    <GroupOfSKUButton
      label={productSKUWithVariableKey[0].variableTypes[0].key}
      skuGroupByValue={skuGroupByFirstType}
      onChangeSKU={(key) => onChangeSKU(primaryKey, key, setPrimaryKey)}
      ownKey={primaryKey}
    />
  );
};

export default OneVariantVariableTypeSelector;
