import { FC, useState } from "react";
import Grid from "@material-ui/core/Grid";

import Icon from "components/Icon";
import SearchInput from "components/SearchInput";
import Typography from "components/Typography";

import { IconSize, IconName } from "types/icon";

import { Wrapper } from "./styled";

import { SearchWrapper } from "../styled";

import { COLORS } from "constants/color";

interface HeaderWithSearchPropsType {
  onSearch: (text: string) => void;
  title: string;
  subTitle?: string;
  onClickSubTitle?: () => void;
  searchPlaceholder?: string;
}

const HeaderWithSearch: FC<HeaderWithSearchPropsType> = ({
  title,
  subTitle,
  onClickSubTitle,
  onSearch,
  searchPlaceholder,
}) => {
  const [isShowSearchInput, setIsShowSearchInput] = useState(false);
  return (
    <SearchWrapper>
      {isShowSearchInput ? (
        <Wrapper>
          <span onClick={() => setIsShowSearchInput(false)}>
            <Icon name={IconName.BACK} size={IconSize.MEDIUM} />
          </span>
          <SearchInput
            data-testid="search-input"
            onChange={onSearch}
            placeholder={searchPlaceholder || "Search by product name"}
            autoFocus
          />
        </Wrapper>
      ) : (
        <Grid container>
          <Grid xs={6} item>
            <Typography variant="dark-title-1">{title}</Typography>
            {!!subTitle && (
              <Wrapper onClick={() => setIsShowSearchInput(false)}>
                <Typography data-testid="sub-title" variant="dark-body-5" onClick={onClickSubTitle}>
                  <Icon name={IconName.HOME} size={IconSize.EXTRA_SMALL} color={COLORS.Primary} />
                  {subTitle}
                </Typography>
              </Wrapper>
            )}
          </Grid>
          <Grid container xs={6}>
            <Wrapper className="search-icon" data-testid="search-icon" onClick={() => setIsShowSearchInput(true)}>
              <Icon name={IconName.SEARCH} size={IconSize.MEDIUM} />
            </Wrapper>
          </Grid>
        </Grid>
      )}
    </SearchWrapper>
  );
};

HeaderWithSearch.displayName = "HeaderWithSearch";

export default HeaderWithSearch;
