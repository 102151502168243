import { FC, useState, useEffect } from "react";
import { PromotionProperties, ShoppingCartCalculator } from "@deeple-ai/shopping-cart-calculator";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import { PopupComponent } from "components/Popup";

import { PRODUCT_BY_PRODUCT_ID } from "graphql/product";

import useCart from "hooks/useCart";

import messengerService from "services/MessengerService";

import { getPage } from "utilities/page";

import { formatPriceText } from "utilities/product/calculateMinAndMaxPrice";

import { PopupType } from "types/popup";

import { CartProduct } from "types/cart";

import { ProductSKU, InitialProductSKU } from "types/product";
import { Promotion } from "types/promotion";

import PromotionDescription from "./PromotionDescription";
import Description from "./Description";
import ProductFooter from "./ProductFooter";
import { ProductBody } from "./ProductBody";

import { MESSAGE } from "constants/message";

import { PAGE } from "constants/page";

type ProductPropTypes = {
  isEditProduct?: boolean;
};

const cartPage = getPage(PAGE.Cart);

const ProductContainer: FC<ProductPropTypes> = ({ isEditProduct = false }) => {
  const history = useHistory();
  const { productId, productSKUId } = useParams<{ productId: string; productSKUId: string }>();
  const { data, loading } = useQuery(PRODUCT_BY_PRODUCT_ID, {
    variables: {
      productId,
    },
    fetchPolicy: "no-cache",
  });

  const { cart, updateCart, removeCart, addToCart, refetchCart, updateCartLoading, addToCartLoading } = useCart();
  const { cartProducts } = cart || {};

  const { t } = useTranslation();
  const [isOpenDescriptionPopup, setIsOpenDescriptionPopup] = useState(false);
  const [isOpenConditionPopup, setIsOpenConditionPopup] = useState(false);
  const [isOpenPromotionPopup, setIsOpenPromotionPopup] = useState(false);
  const [selectedProductSKU, setSelectedProductSKU] = useState<ProductSKU | undefined>();
  const [initialProductSKU, setInitialProductSKU] = useState<InitialProductSKU>();
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion | undefined>();
  const [quantity, setQuantity] = useState(0);

  const product = data?.product || {};

  const shoppingCartCalculatorToDisplayPriceAddToCart = new ShoppingCartCalculator(
    selectedProductSKU
      ? [
          {
            id: selectedProductSKU.id,
            price: Number(selectedProductSKU.price),
            amount: Number(quantity),
            stock: Number(selectedProductSKU.stock),
            weight: 0,
            isFree: false,
          },
        ]
      : [],
  );

  // NOTE: it should be type Promotion[], calculator is required promotion.settings but in GQL resolve promotion.setting
  const promotions = selectedProductSKU?.promotions || [];
  const newFormatPromotion: unknown = promotions.map((promotion: Promotion) => {
    const selectedProducts = promotion.isIncludedAllProducts ? [selectedProductSKU] : promotion.selectedProducts;
    return {
      ...promotion,
      selectedProducts,
      settings: {
        ...promotion.setting,
      },
    };
  });

  const totalDataPerItemWithAmountAndPromotion = shoppingCartCalculatorToDisplayPriceAddToCart
    .setBulkPromotionWithPromotionProperties(newFormatPromotion as PromotionProperties[])
    .calculate();

  const grandTotalPrice = totalDataPerItemWithAmountAndPromotion.grandTotal;
  const productProperty = product?.properties || [];

  const handleAddToCart = async () => {
    if (selectedProductSKU && quantity > 0) {
      try {
        await addToCart({
          variables: {
            productSKUId: selectedProductSKU?.id,
            amount: quantity,
          },
        });

        await messengerService.sendMessages(MESSAGE.CART_LIST);
      } catch (error) {
        throw new Error(error);
      } finally {
        setQuantity(0);
        if (refetchCart) {
          await refetchCart();
        }
      }
    }
  };

  const handleUpdateCart = async () => {
    if (selectedProductSKU && quantity > 0) {
      try {
        if (initialProductSKU && selectedProductSKU && initialProductSKU?.id !== selectedProductSKU?.id) {
          await updateCart({
            variables: {
              newProductSKUId: selectedProductSKU?.id,
              currentProductSKUId: initialProductSKU?.id,
              amount: quantity,
            },
          });
        } else {
          await updateCart({
            variables: {
              currentProductSKUId: selectedProductSKU?.id,
              amount: quantity,
            },
          });
        }

        await messengerService.sendMessages(MESSAGE.CART_LIST);
      } catch (error) {
        throw new Error(error);
      } finally {
        history.push(cartPage);
      }
    }
  };

  const handleRemoveProductFromCart = async () => {
    const productSKU = initialProductSKU || selectedProductSKU;
    if (productSKU) {
      await removeCart(productSKU?.id);
      await messengerService.sendMessages(MESSAGE.CART_LIST);
    }

    history.push(cartPage);
  };

  const handleClosePromotion = () => {
    setIsOpenPromotionPopup(false);
    setSelectedPromotion(undefined);
  };

  useEffect(() => {
    if (cartProducts && isEditProduct) {
      const productInCart = cartProducts.find(({ productSKU: { id } }: CartProduct) => id === productSKUId);
      const productSKU = productInCart?.productSKU;

      if (productSKU && productInCart) {
        setInitialProductSKU({
          ...productSKU,
          amount: productInCart.amount,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditProduct, cartProducts]);

  if (loading || addToCartLoading || updateCartLoading) {
    return <PopupComponent type={PopupType.LOADING} />;
  }

  return (
    <>
      <ProductBody
        product={product}
        selectedProductSKU={selectedProductSKU}
        setSelectedProductSKU={setSelectedProductSKU}
        quantity={quantity}
        initialProductSKU={initialProductSKU}
        setQuantity={setQuantity}
        setIsOpenDescriptionPopup={setIsOpenDescriptionPopup}
        setIsOpenConditionPopup={setIsOpenConditionPopup}
        setIsOpenPromotionPopup={setIsOpenPromotionPopup}
        setSelectedPromotion={setSelectedPromotion}
      />

      <ProductFooter
        isUpdatingCart={cart.loading}
        total={formatPriceText(grandTotalPrice) || 0}
        selectedProductSKU={selectedProductSKU}
        isEditProduct={isEditProduct}
        handleAddToCart={handleAddToCart}
        handleUpdateCart={handleUpdateCart}
        handleRemoveProductFromCart={handleRemoveProductFromCart}
        productInCart={cartProducts?.length}
        quantity={quantity}
      />

      {/* popup description */}
      <PopupComponent isHalfScreen isOpen={isOpenDescriptionPopup} onClose={() => setIsOpenDescriptionPopup(false)}>
        <Description
          title={t("product.description")}
          productProperties={productProperty}
          description={product?.description}
        />
      </PopupComponent>
      {/* popup Conditions */}
      <PopupComponent isHalfScreen isOpen={isOpenConditionPopup} onClose={() => setIsOpenConditionPopup(false)}>
        <Description title={t("product.conditions")} description={product?.additionalDescription} />
      </PopupComponent>
      {/* popup promotion */}
      <PopupComponent
        {...selectedPromotion}
        isHalfScreen
        type={PopupType.DEFAULT}
        isOpen={isOpenPromotionPopup}
        onClose={handleClosePromotion}
      >
        <PromotionDescription {...(selectedPromotion as Promotion)} />
      </PopupComponent>
    </>
  );
};

export default ProductContainer;
