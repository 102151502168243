import styled from "styled-components";

export const SearchBox = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.COLORS.PrimaryNude};

  input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;

    font-size: 14px;
    font-family: "Graphik", sans-serif;
    color: ${({ theme }) => theme.COLORS.Dark};
  }
`;

export const IconWrapper = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
