import { MobileBankingBank } from "types/payment";

import { IMAGE_PATH } from "./image";

export const MOBILE_BANKING_APPS: {
  readonly key: MobileBankingBank;
  readonly value: MobileBankingBank;
  readonly logo: string;
  readonly title: string;
  readonly description: string;
}[] = [
  {
    key: MobileBankingBank.KBANK,
    value: MobileBankingBank.KBANK,
    logo: `${IMAGE_PATH}/mobile-banking/k-plus.png`,
    title: "payment.mobileBanking.type.kPlus.title",
    description: "payment.mobileBanking.type.kPlus.description",
  },
  {
    key: MobileBankingBank.BAY,
    value: MobileBankingBank.BAY,
    logo: `${IMAGE_PATH}/mobile-banking/kma.png`,
    title: "payment.mobileBanking.type.kma.title",
    description: "payment.mobileBanking.type.kma.description",
  },
  {
    key: MobileBankingBank.SCB,
    value: MobileBankingBank.SCB,
    logo: `${IMAGE_PATH}/mobile-banking/scb-easy.png`,
    title: "payment.mobileBanking.type.scbEasy.title",
    description: "payment.mobileBanking.type.scbEasy.description",
  },
];
