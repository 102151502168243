import * as yup from "yup";
import isBefore from "date-fns/isBefore";
import getYear from "date-fns/getYear";
import creditCardType from "credit-card-type";

import { getExpiredDate } from "utilities/credit-card";

import { AllowCardType } from "types/billing";

const schema = yup.object({
  cardType: yup.string().required(),
  cardNumber: yup
    .string()
    .required("addCreditCard.form.error.cardNumber.required")
    .test("validate card type", "addCreditCard.form.error.cardNumber.inValid", (cardNumber: string | undefined) => {
      if (!cardNumber) {
        return false;
      }
      const possibleCardType = creditCardType(cardNumber);
      const allowCardType = Object.values(AllowCardType);
      return allowCardType.some((type) => possibleCardType.find((possible) => possible.type === type));
    })
    .test("validate card number", "addCreditCard.form.error.cardNumber.inValid", (cardNumber) => {
      const cardNumberReplaceSpace = cardNumber?.replace(/\D+/g, "") || "";
      if (cardNumberReplaceSpace.length !== 16) {
        return false;
      }
      return true;
    }),
  validDate: yup
    .string()
    .required("addCreditCard.form.error.validDate.required")
    .test("validate date", "addCreditCard.form.error.validDate.inValid", (date: string | undefined) => {
      const { month, year } = getExpiredDate(date || "");

      if (year?.length) {
        const currentDate = new Date();
        const currentYear = getYear(currentDate).toString();

        if (currentYear[0] > year[0]) {
          return false;
        }

        const cardExpirationDate = new Date(`20${year}/${month}/1`);
        return isBefore(currentDate, cardExpirationDate);
      }
      return false;
    }),
  cvv: yup.string().required("addCreditCard.form.error.cvv.required").length(3),
  cardHolderName: yup.string().required("addCreditCard.form.error.cardHolderName.required"),
});

export default schema;
