import styled from "styled-components";

type CategoryImagePropsType = {
  src?: string;
};

export const Wrapper = styled.div`
  position: relative;
  width: 124px;
  height: 124px;
`;

export const CategoryImage = styled.div<CategoryImagePropsType>`
  object-fit: cover;
  text-align: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(20, 25, 49, 0) 30%, rgba(20, 25, 49, 0.7)), url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
`;

export const CategoryNameWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 124px;
  padding: 10px 14px;
  white-space: pre-wrap;
  overflow: visible;
  text-overflow: ellipsis;
  word-break: break-word;
`;
