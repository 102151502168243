import { LIFF_ID, API_BASE_URL } from "config";

import get from "lodash/get";

import { isTokenExpired, getPlatform, getProjectIdAndToken } from "utilities/token";

import { isMobileDevice } from "utilities/common";

import { Platform } from "types/platform";

import { MESSAGE_TYPE } from "../constants";

class HttpClientService {
  private token = "" as string;

  private platform = "" as Platform;

  private fallbackCloseWindow = (platform: Platform) => {
    if (isMobileDevice && platform === Platform.FACEBOOK) {
      return window.location.replace("https://www.messenger.com/closeWindow/?display_text=closing%20window");
    }

    return window.top && window.top.close();
  };

  /**
   * @private
   * Close Facebook window
   * @returns
   */
  private closeFacebookWindow = async (platform: Platform) => {
    try {
      await new Promise((resolve, reject) => {
        if (window.MessengerExtensions) {
          return window.MessengerExtensions.requestCloseBrowser(
            function success() {
              resolve(null);
            },
            function error(err: Error) {
              console.error(err);

              reject(err);
            },
          );
        }

        return this.fallbackCloseWindow(platform);
      });

      return;
    } catch (err) {
      this.fallbackCloseWindow(platform);
    }
  };

  private closeLineWindow = () => {
    return window.liff.closeWindow();
  };

  private closeWindowByPlatform = (platform: Platform) => {
    if ([Platform.FACEBOOK, Platform.INSTAGRAM].includes(platform)) {
      return this.closeFacebookWindow(platform);
    }

    return this.closeLineWindow();
  };

  /**
   * Get customer platform from query string
   * @returns {Platform}
   */
  private getCustomerPlatformByQueryString = (): Platform | undefined => {
    const customerPlatform = getPlatform();
    if (customerPlatform) {
      this.platform = customerPlatform;
    }

    return customerPlatform;
  };

  /**
   * @private
   * Get project id and token from query string
   */
  private getProjectIdAndToken = () => {
    const projectIdAndToken = getProjectIdAndToken();
    const token = get(projectIdAndToken, "token");
    if (isTokenExpired(token)) {
      return undefined;
    }

    return projectIdAndToken;
  };

  private sendLineMessage = async (message: string): Promise<void> => {
    try {
      await window.liff.sendMessages([
        {
          type: MESSAGE_TYPE.TEXT,
          text: message,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Initialize LIFF client with LIFF ID
   */
  init = async (): Promise<void> => {
    if (window.liff && LIFF_ID) {
      await window.liff.init({ liffId: LIFF_ID });
    }
  };

  /**
   * Get customer platform by project id and token
   * @returns {Promise<Platform>}
   */
  getCustomerPlatform = async (): Promise<Platform | undefined> => {
    if (this.platform) {
      return this.platform;
    }

    const customerPlatform = this.getCustomerPlatformByQueryString();
    if (customerPlatform) {
      return customerPlatform;
    }

    const projectIdAndToken = this.getProjectIdAndToken();
    if (!projectIdAndToken) {
      return undefined;
    }

    try {
      const fetchIsCustomerInFacebookPlatform = await fetch(`${API_BASE_URL}/graphql`, {
        mode: "cors",
        method: "POST",
        headers: {
          "x-project-id": projectIdAndToken.projectId as string,
          authorization: `Bearer ${projectIdAndToken.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          query: `
            query {
              customer {
                platform
              }
            }
          `,
        }),
      });

      const customerResponse = await fetchIsCustomerInFacebookPlatform.json();

      this.platform = customerResponse?.data?.customer?.platform;

      return customerResponse?.data?.customer?.platform;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  /**
   * Is customer platform
   * @param platforms
   * @returns {Promise<boolean>}
   */
  isCustomerPlatform = async (platforms: ReadonlyArray<Platform>): Promise<boolean> => {
    const projectIdAndToken = this.getProjectIdAndToken();
    if (!projectIdAndToken) {
      return false;
    }

    try {
      const customerPlatform = await this.getCustomerPlatform();
      if (!customerPlatform) {
        return false;
      }

      return platforms.includes(customerPlatform);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /**
   * Is line client
   * @returns {Promise<boolean>} isLineClient
   */
  isLineClient = async (): Promise<boolean> => {
    /**
     * If client has "platform" already, client should be able to
     * use this private variable right away
     */
    if (this.platform) {
      return this.platform === Platform.LINE;
    }

    const hasLiffSDK = get(window, "liff");
    if (!hasLiffSDK) {
      return false;
    }

    const isInLiffBrowser = window.liff.isInClient();
    if (isInLiffBrowser) {
      this.platform = Platform.LINE;

      return true;
    }

    const customerPlatform = await this.getCustomerPlatform();

    return customerPlatform === Platform.LINE;
  };

  /**
   * Is meta platform
   *
   * ** note **
   *
   * this function create on facebook sdk can't detect platform issue if facebook sdk work correctly should remove this function
   * @returns
   */
  isMetaPlatform = async (): Promise<boolean> => {
    /**
     * If client has "platform" already, client should be able to
     * use this private variable right away
     */
    if (this.platform) {
      return [Platform.FACEBOOK, Platform.INSTAGRAM].includes(this.platform);
    }

    // #region Refetch customer's platform
    return this.isCustomerPlatform([Platform.FACEBOOK, Platform.INSTAGRAM]);
    // #endregion
  };

  /**
   * Is meta client
   * @returns {Promise<boolean> | boolean}
   */
  isMetaClient = (): Promise<boolean> | boolean => {
    const isInExtension = window.MessengerExtensions?.isInExtension;
    if (isInExtension && isInExtension()) {
      return true;
    }

    return this.isMetaPlatform();
  };

  /**
   * Send message
   *
   * ** note **
   *
   * Only supports for LINE client
   *
   * @param messages
   */
  sendMessages = async (messages: string) => {
    const isLineClient = await this.isLineClient();
    if (isLineClient) {
      await this.sendLineMessage(messages);
    }
  };

  /**
   * Close window
   */
  closeWindow = async (platform?: Platform) => {
    let customerPlatform = platform;
    if (!customerPlatform) {
      customerPlatform = await this.getCustomerPlatform();
    }

    try {
      if (customerPlatform) {
        return this.closeWindowByPlatform(customerPlatform);
      }

      // Fallback to unknown platform
      return window.top && window.top.close();
    } catch (error) {
      console.error(error);

      return this.fallbackCloseWindow(customerPlatform as Platform);
    }
  };

  /**
   * Is open with mobile browser
   * @param platform
   * @returns {Promise<boolean>}
   */
  isOpenWithMobileBrowser = async (platform?: Platform): Promise<boolean> => {
    if (platform) {
      return [Platform.FACEBOOK, Platform.INSTAGRAM, Platform.LINE].includes(platform) && isMobileDevice;
    }

    const isMetaClient = await this.isMetaClient();
    const isLineClient = await this.isLineClient();

    return (isMetaClient || isLineClient) && isMobileDevice;
  };
}

const MessengerService = new HttpClientService();

export default MessengerService;
