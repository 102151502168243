import { FC, forwardRef, ReactElement, Ref } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import { PopupType } from "types";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { GridItem, GridContainer, Popup, Content, useStyles } from "./styled";

import Typography from "../Typography/index";
import { ButtonColor, ButtonType } from "../../types/button";

import { COLORS } from "constants/color";

type PopupPropTypes = {
  isOpen?: boolean;
  title?: string;
  type?: PopupType;
  cancelText?: string;
  confirmText?: string;
  isHalfScreen?: boolean;
  onClose?: () => void;
  handleConfirmPopup?: () => void;
  cancelButtonColor?: ButtonColor;
  confirmButtonColor?: ButtonColor;
  backgroundColor?: string;
  hasCloseButton?: boolean;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PopupComponent: FC<PopupPropTypes> = ({
  isOpen = false,
  onClose,
  handleConfirmPopup,
  type = PopupType.DEFAULT,
  isHalfScreen = false,
  children,
  cancelText = "cancel",
  confirmText = "confirm",
  cancelButtonColor = COLORS.Primary,
  confirmButtonColor = COLORS.Primary,
  backgroundColor,
  hasCloseButton = true,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  switch (type) {
    case PopupType.CONFIRM: {
      return (
        <Popup
          open={isOpen}
          onClose={onClose}
          TransitionComponent={Transition}
          backgroundColor={backgroundColor}
          {...props}
        >
          <Content>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item xs={12}>
                {children}
              </Grid>
              <Grid item xs={6}>
                <Button variant={ButtonType.OUTLINED} handleClick={onClose} color={cancelButtonColor}>
                  <Typography variant="dark-title-3">{t(cancelText)}</Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button handleClick={handleConfirmPopup} color={confirmButtonColor}>
                  <Typography variant="white-title-3">{t(confirmText)}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Content>
        </Popup>
      );
    }
    case PopupType.ALERT: {
      return (
        <Popup
          open={isOpen}
          onClose={onClose}
          TransitionComponent={Transition}
          backgroundColor={backgroundColor}
          {...props}
        >
          {hasCloseButton && (
            <AppBar className={classes.appBar}>
              <GridContainer container backgroundColor={backgroundColor}>
                <GridItem className="close" item xs={2}>
                  <CloseIcon onClick={onClose} />
                </GridItem>
              </GridContainer>
            </AppBar>
          )}
          <DialogContent>{children}</DialogContent>
        </Popup>
      );
    }
    case PopupType.LOADING: {
      return (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    default:
      return (
        <Popup
          fullScreen
          open={isOpen}
          onClose={onClose}
          TransitionComponent={Transition}
          className={isHalfScreen ? "halfScreen" : ""}
          backgroundColor={backgroundColor}
          {...props}
        >
          <AppBar className={classes.appBar}>
            <GridContainer container backgroundColor={backgroundColor}>
              <GridItem className="close" item xs={2}>
                <CloseIcon onClick={onClose} />
              </GridItem>
            </GridContainer>
          </AppBar>
          <DialogContent>{children}</DialogContent>
        </Popup>
      );
  }
};

PopupComponent.displayName = "Popup";

export default PopupComponent;
