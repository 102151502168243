import { CircularProgress, Grid } from "@material-ui/core";
import { FC, useState, useEffect, useCallback } from "react";
import FileSaver from "file-saver";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { PopupType } from "types";

import ErrorOrder from "containers/payment/ErrorOrder";

import Typography from "components/Typography";

import Footer from "components/Footer";

import QRCode from "components/QRCode";

import Icon from "components/Icon";

import Popup, { PopupComponent } from "components/Popup";

import useOrder from "hooks/useOrder";

import MessengerService from "services/MessengerService";

import { isMobileDevice } from "utilities/common";

import { QRPaymentType } from "types/payment";

import { IconName } from "types/icon";

import { GeneratePaymentStatus } from "types/order";

import {
  Body,
  DownloadButton,
  FooterButton,
  Header,
  PopupDetail,
  QRBox,
  QRWrapper,
  Retry,
  RetryWrapper,
  TotalWrapper,
} from "./styled";

import DeeplePayLoading from "../../DeeplePayLoading";
import useQRPayment from "../hooks/useQRPayment";

import { Wrapper } from "../styled";

import { PHOTO } from "../../../../constants/image";

import { IconSize } from "../../../../types/icon";

import { COLORS } from "constants/color";

import { THAI_QR_PAYMENT_LOGO } from "constants/image";

const CashQRPayment: FC = () => {
  const { orderId = "" } = useParams<{ orderId: string }>();
  const [isOpenSuccessPopup, setIsOpenSuccessPopup] = useState<boolean>(false);
  const [isInitialPayment, setIsInitialPayment] = useState<boolean>(false);
  const [isShowFooterButton, setIsShowFooterButton] = useState(false);
  const { t } = useTranslation();
  const qrType = QRPaymentType.QR_CASH;
  const downloadFileName = `DP-${orderId}-${new Date().toLocaleDateString("en-CA")}.png`;
  const { order, loadingOrder } = useOrder(orderId);
  const { qrCodeData, generateQRCode, loading, error } = useQRPayment();

  const isErrorOrder = order?.generatePaymentStatus !== GeneratePaymentStatus.SUCCESS;

  const checkIsShowBankButton = useCallback(async () => {
    const isOpenWithMobileBrowser = await MessengerService.isOpenWithMobileBrowser();

    setIsShowFooterButton(isOpenWithMobileBrowser);
  }, []);

  const backToChat = () => MessengerService.closeWindow();
  const generateQRPayment = async () => {
    await generateQRCode(orderId, qrType);
  };
  const downloadQRCode = async () => {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;

    if (canvas) {
      if (!isMobileDevice) {
        await canvas?.toBlob((blob) => {
          FileSaver.saveAs(blob as Blob, downloadFileName);
        });
      } else {
        canvas?.toBlob((blob) => {
          const urlCreator = window.URL || window.webkitURL;
          const objectURL = urlCreator.createObjectURL(blob as Blob);

          const element = document.createElement("a");
          element.setAttribute("href", objectURL);
          element.setAttribute("download", "download");
          element.click();
        });
      }

      await setIsOpenSuccessPopup(true);
      await setTimeout(() => {
        backToChat();
      }, 2000);
    }
  };

  useEffect(() => {
    setIsInitialPayment(true);
    checkIsShowBankButton();
    generateQRPayment();
    setTimeout(() => {
      setIsInitialPayment(false);
    }, 2000);
  }, []);

  if (isErrorOrder && !loadingOrder) return <ErrorOrder />;

  if (isInitialPayment) {
    return <DeeplePayLoading />;
  }

  if (loading || loadingOrder) {
    return <PopupComponent type={PopupType.LOADING} />;
  }

  return (
    <>
      <Wrapper>
        <Grid container justify="center" alignItems="center">
          <Grid container justify="center" alignItems="center" className="pb-3">
            <TotalWrapper>
              <Typography variant="dark-body-4">{t("payment.qrCode.cash.total")}</Typography>
              <Typography variant="dark-title-1">
                {qrCodeData?.amount
                  ? qrCodeData?.amount?.toLocaleString("th-TH", { style: "currency", currency: "THB" })
                  : "฿0.00"}
              </Typography>
            </TotalWrapper>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <QRBox container>
              <Header>
                <img src={THAI_QR_PAYMENT_LOGO} alt="thai-qr-payment" />
              </Header>
              <Body>
                {qrCodeData?.qrCode ? (
                  <div>
                    <QRWrapper>
                      <QRCode code={qrCodeData?.qrCode} />
                    </QRWrapper>
                    <Typography variant="gray-body-3">{t("payment.qrCode.cash.description.default")}</Typography>
                  </div>
                ) : (
                  <div>
                    <RetryWrapper>
                      {loading && <CircularProgress size="20px" color="inherit" />}
                      {!loading && (
                        <Retry onClick={generateQRPayment}>
                          <Icon name={IconName.RELOAD} size={IconSize.LARGE} color={COLORS.Primary} />
                          <Typography className="pt-2" variant="dark-body-4">
                            {t("payment.qrCode.cash.retry.label")}
                          </Typography>
                        </Retry>
                      )}
                    </RetryWrapper>
                    <Typography
                      variant="gray-body-3"
                      style={{ color: loading ? COLORS.DarkMed : COLORS.Error }}
                      className="pt-3"
                    >
                      {loading && t("payment.qrCode.cash.description.process")}
                      {!loading && t("payment.qrCode.cash.description.retry")}
                    </Typography>
                  </div>
                )}
              </Body>
            </QRBox>
            <Grid container justify="center" alignItems="center">
              <TotalWrapper>
                <Typography variant="dark-title-2" className="pt-3 px-3">
                  {t("payment.qrCode.cash.description.detail")}
                </Typography>
              </TotalWrapper>
            </Grid>
          </Grid>
        </Grid>
        {!error && qrCodeData?.qrCode && !isMobileDevice && (
          <DownloadButton handleClick={downloadQRCode}>
            <Icon name={IconName.DOWNLOAD} color={COLORS.White} />
          </DownloadButton>
        )}
      </Wrapper>
      {isShowFooterButton && (
        <Footer>
          <FooterButton handleClick={backToChat} color={COLORS.Primary}>
            <Typography variant="white-title-3">{t("payment.qrCode.cash.footer.label")}</Typography>
          </FooterButton>
        </Footer>
      )}

      <Popup
        isOpen={isOpenSuccessPopup}
        onClose={() => setIsOpenSuccessPopup(false)}
        type={PopupType.ALERT}
        hasCloseButton={false}
      >
        <PopupDetail className="m-4">
          <img src={PHOTO} alt="success-pic" />
          <Typography variant="dark-body-3">{t("payment.qrCode.cash.popup.saveQRSuccess")}</Typography>
        </PopupDetail>
      </Popup>
    </>
  );
};

export default CashQRPayment;
