import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;

  span + div {
    margin-left: 8px;
  }

  p > svg {
    margin-right: 4px;
  }

  span {
    display: flex;
  }

  &.search-icon {
    justify-content: flex-end;
  }
`;
