import { Grid } from "@material-ui/core";
import styled from "styled-components";

import Typography from "components/Typography";

export const Wrapper = styled(Grid)`
  display: flex;
  flex-wrap: nowrap;
  padding: 16px 0;
  position: relative;
  border-bottom: dashed 0.5px ${({ theme }) => theme.COLORS.DarkLight};
`;

export const ProductWrapper = styled(Grid)`
  display: flex;
  flex-grow: 1;
`;

export const EditButtonWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 0;
  margin-left: 16px;
  cursor: pointer;
`;

export const ProductImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 10px;
`;

export const ProductDetailWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Badge = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  color: ${({ theme }) => theme.COLORS.Primary};
  padding: 5px;
  margin-right: 8px;
`;

export const ProductListWrapper = styled.div`
  padding: 0 16px;
`;

export const IconWrapper = styled.div`
  margin-left: 8px;
  margin-right: -8px;
`;

export const TextRow = styled(Grid)`
  padding-top: 4px;
`;

export const Price = styled(Typography)`
  :before {
    content: "฿";
  }
`;

export const DiscountPrice = styled(Typography)`
  padding-right: 8px;

  &.line-through {
    color: ${({ theme }) => theme.COLORS.DarkMed};
    text-decoration: line-through;
  }
`;

export const DeleteButtonBox = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 16px;
  right: 0;
  background-color: ${({ theme }) => theme.COLORS.Gallery};
  width: 22px;
  height: 22px;
  border-radius: 2px;
`;
