import { useQuery } from "@apollo/client";
import { FC, createContext, useState, useEffect, useRef } from "react";
import { Platform, PopupType, Project } from "types";
import httpStatus from "http-status";

import ErrorPage from "containers/error";

import PopupComponent from "components/Popup";

import { PROJECT } from "graphql/project";

import messengerService from "services/MessengerService";

import { getToken, isTokenExpired } from "../../utilities/token/index";

interface ProjectData {
  project: Project;
}

export const ProjectContext = createContext<Project | undefined>(undefined);

export const ProjectContextProvider: FC = ({ children }) => {
  const token = getToken();
  const isExpiredToken = isTokenExpired(token as string);
  const [isCheckPlatform, setIsCheckPlatform] = useState<boolean>(false);
  const customerPlatformRef = useRef<Platform | undefined>(undefined);
  const { data, loading } = useQuery<ProjectData>(PROJECT, { skip: isExpiredToken });

  useEffect(() => {
    const checkPlatform = async () => {
      const customerPlatform = await messengerService.getCustomerPlatform();
      customerPlatformRef.current = customerPlatform;

      setIsCheckPlatform(true);
    };

    checkPlatform();
  }, []);

  if (loading || !isCheckPlatform) {
    return <PopupComponent type={PopupType.LOADING} />;
  }

  if (!data?.project) {
    return <ErrorPage />;
  }

  const isLineConnected =
    data?.project.lineChannelId && data?.project.lineChannelAccessToken && data?.project.lineChannelSecret;
  const isMetaConnected = data?.project.facebookPageId && data?.project.facebookPageToken;
  const isInstagramConnected = data?.project.instagramBusinessId && data?.project.instagramBusinessToken;

  // handle when project already disconnected and customer try to open webview from flex/message template
  if (
    (customerPlatformRef.current === Platform.LINE && !isLineConnected) ||
    (customerPlatformRef.current === Platform.FACEBOOK && !isMetaConnected) ||
    (customerPlatformRef.current === Platform.INSTAGRAM && !isInstagramConnected)
  ) {
    return <ErrorPage error={httpStatus.NOT_FOUND} />;
  }

  return <ProjectContext.Provider value={data.project}>{children}</ProjectContext.Provider>;
};
