import styled from "styled-components";
import { Card, Grid } from "@material-ui/core";

import DefaultDivider from "components/Divider";

export const HeaderWrapper = styled(Grid)`
  margin: 15px 0;
  font-size: 20px;
`;

export const Item = styled(Card)`
  max-width: 150px;
  background-image: linear-gradient(to right, #140133 -31%, #290362 68%);
  margin: 0 8px 0 0;
  padding: 12px;
`;

export const Image = styled.img`
  max-width: 25px;
  height: auto;
`;

export const Title = styled.span`
  font-size: 16px;
`;

export const CardTitle = styled.span`
  color: ${({ theme }) => theme.COLORS.White} !important;
  font-weight: 600;
  font-size: 20px;
`;

export const CardWrapper = styled(Grid)`
  outline: 1px;

  &.detail {
    padding: 0 5px;
  }
`;

export const SubTitle = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.COLORS.White} !important;Ó
`;

export const DetailTitle = styled.span`
  font-size: 7px;
  color: ${({ theme }) => theme.COLORS.White} !important;
`;

export const FooterTitle = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.COLORS.White} !important;
`;

export const TextItem = styled(Grid)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.COLORS.White} !important;
`;

export const ImageWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`;

export const Divider = styled(DefaultDivider)`
  margin: 0 15px;
`;
