import { getTypeInfo } from "credit-card-type";
import cardValidator from "card-validator";

import { AllowCardType } from "types/billing";

export function prettyCardNumber(cardNumber: string, cardType: string) {
  const card = getTypeInfo(cardType);

  if (card) {
    const cardGaps = card?.gaps || [4, 8, 12];
    const offsets: ReadonlyArray<number> = [0, ...cardGaps, cardNumber.length];
    const cardNumberFormats = [];

    for (let i = 0; offsets[i] < cardNumber.length; i += 1) {
      const start = offsets[i];
      const end = Math.min(offsets[i + 1], cardNumber.length);
      cardNumberFormats.push(cardNumber.substring(start, end));
    }
    return cardNumberFormats.join(" ");
  }

  return cardNumber;
}

export const getCreditCardInformation = (cardNumber: string) => {
  const onlyCardNumber = cardNumber.replace(/\D+/g, "");
  const allowCardType = Object.values(AllowCardType);
  const { card: creditCardInfo, isValid } = cardValidator.number(onlyCardNumber);
  const isValidCardType = allowCardType.find((cardType) => creditCardInfo?.type === cardType);

  return {
    cardNumberGaps: creditCardInfo?.gaps || [],
    cardNumberLengths: creditCardInfo?.lengths || [],
    creditCardType: creditCardInfo?.type || "",
    isValid: !!(isValid && isValidCardType),
  };
};

export const getExpiredDate = (expiredDate: string) => {
  const formattedValue = expiredDate.replace(/\D+/g, "");

  const month = formattedValue.substring(0, 2);
  const year = formattedValue.substring(2, 4);

  return { month, year };
};

export const validateCreditCardNumber = (cardNumber: string) => {
  const { isValid } = cardValidator.number(cardNumber);

  return isValid;
};

export const formatCreditCardMonth = (value: string) => {
  let formattedValue = value;
  if (value.length === 1 && value[0] > "1") {
    formattedValue = `0${value}`;
  }

  if (value.length === 2) {
    if (Number(value) === 0) {
      formattedValue = "01";

      // this can happen when user paste number
    } else if (value > "12") {
      formattedValue = "12";
    }
  }

  return formattedValue;
};

export const getCreditCardExpiry = (value: string) => {
  const currentValue = value.replace(/\D+/g, "");
  const month = formatCreditCardMonth(currentValue.substring(0, 2));
  const year = currentValue.substring(2, 4);

  return month + (year.length ? ` / ${year}` : "");
};
