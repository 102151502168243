import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const PlusIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 32 32", color = COLORS.Primary } = props;
  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <g className="cls-2">
        <path
          className="cls-1"
          fill={color}
          d="M16,.07A2.87,2.87,0,0,1,19,2.8V13H29.22A2.87,2.87,0,0,1,32,16a2.86,2.86,0,0,1-2.73,3H19V29.24A2.87,2.87,0,0,1,16,32a2.87,2.87,0,0,1-3-2.73V19H2.78A2.86,2.86,0,0,1,.05,16a2.87,2.87,0,0,1,2.73-3H13V2.8A2.87,2.87,0,0,1,16,.07Z"
        />
        <g id="Group_601" data-name="Group 601">
          <g className="cls-3">
            <rect className="cls-1" x="-39.83" y="-39.81" width="111.65" height="111.65" />
            <g id="Group_600" data-name="Group 600">
              <path id="Path_276" data-name="Path 276" className="cls-4" d="M-19.89-19.87H51.89V51.91H-19.89Z" />
            </g>
          </g>
        </g>
      </g>
    </SVG>
  );
};

export default PlusIcon;
