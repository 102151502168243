export const th = {
  searchPlaceholder: "ค้นหาจากชื่อสินค้า",
  category: {
    seeAllItem: {
      label: "ดูสินค้าเพิ่มเติม",
    },
    seeItemInCategory: {
      label: "ดูสินค้าเพิ่มเติมใน {{categoryName}}",
    },
  },
  footer: {
    cartButton: {
      items: {
        label: "สินค้า • {{item}} รายการ",
      },
      totalPrice: {
        label: "฿{{totalPrice}}",
      },
    },
  },
};

export default th;
