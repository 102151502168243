import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";
import { COLORS } from "../../../constants/color";

const ClearIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Gallery } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        className="cls-1"
        d="M16.09,18.33a1.6,1.6,0,0,1-.43-.82.28.28,0,0,0,0,.09,1.63,1.63,0,0,0,.45.87L21.61,24l.05-.05Z"
      />
      <path className="cls-1" d="M21.63,24l0,0-5.52,5.52a1.64,1.64,0,0,0-.58,1.2,1.67,1.67,0,0,1,.58-1.15Z" />
      <path
        className="cls-1"
        d="M16.09,16a1.7,1.7,0,0,1,2.39,0L24,21.57,29.61,16A1.71,1.71,0,0,1,32,16a1.73,1.73,0,0,1,.49,1.17A1.66,1.66,0,0,0,32,15.94a1.69,1.69,0,0,0-2.39,0L24,21.51l-5.56-5.57a1.69,1.69,0,0,0-2.82,1.57A1.69,1.69,0,0,1,16.09,16Z"
      />
      <path
        className="cls-1"
        d="M29.62,31.86l-5.57-5.58-5.57,5.58a1,1,0,0,1-.18.17,1.67,1.67,0,0,1-2.38-.17,1.64,1.64,0,0,1-.41-1.18,1.71,1.71,0,0,0,.41,1.24,1.69,1.69,0,0,0,2.38.17l.18-.18L24,26.37l5.52,5.52a1.57,1.57,0,0,0,.65.37,1.68,1.68,0,0,0,2.3-1.6,1.69,1.69,0,0,1-2.86,1.2Z"
      />
      <path className="cls-1" d="M32,29.52a1.68,1.68,0,0,1,.47,1.14A1.65,1.65,0,0,0,32,29.47l-5.55-5.55,0,0Z" />
      <path
        className="cls-1"
        d="M32.49,17.16A1.64,1.64,0,0,1,32,18.32l-5.57,5.57,0,0L32,18.37A1.67,1.67,0,0,0,32.49,17.16Z"
      />
      <path
        fill={color}
        className="cls-2"
        d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0Zm8,29.47a1.65,1.65,0,0,1,.47,1.19,1.68,1.68,0,0,1-2.3,1.6,1.57,1.57,0,0,1-.65-.37L24,26.37l-5.53,5.54-.18.18a1.69,1.69,0,0,1-2.38-.17,1.71,1.71,0,0,1-.41-1.24,1.64,1.64,0,0,1,.58-1.2L21.61,24l-5.49-5.49a1.63,1.63,0,0,1-.45-.87.28.28,0,0,1,0-.09,1.69,1.69,0,0,1,2.82-1.57L24,21.51l5.57-5.57a1.69,1.69,0,0,1,2.39,0,1.76,1.76,0,0,1,0,2.43l-5.55,5.55Z"
      />
      <path
        fill={COLORS.Primary}
        className="cls-3"
        d="M32,18.32a1.64,1.64,0,0,0,.48-1.16A1.73,1.73,0,0,0,32,16a1.71,1.71,0,0,0-2.39,0L24,21.57,18.48,16a1.68,1.68,0,0,0-2.82,1.52,1.6,1.6,0,0,0,.43.82l5.57,5.58-.05.05,0,0-5.54,5.54a1.67,1.67,0,0,0-.58,1.15,1.64,1.64,0,0,0,.41,1.18A1.67,1.67,0,0,0,18.3,32a1,1,0,0,0,.18-.17l5.57-5.58,5.57,5.58A1.67,1.67,0,0,0,32,29.52L26.44,24l0,0,0,0Z"
      />
    </SVG>
  );
};

export default ClearIcon;
