import { FC } from "react";
import InfiniteSC from "react-infinite-scroll-component";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useTranslation } from "react-i18next";

import ProductCard from "containers/home/ProductCard";

import { getProductStock } from "utilities/product/productStock";

import { getPage } from "utilities/page";

import { Product } from "types/product";

import { WrapperLoading, TextCenter, WrapperEmpty, Link } from "./style";

import LoadCard from "../../home/LoaderCard";

import { SEARCH_NOT_FOUND } from "constants/image";
import { PAGE } from "constants/page";

interface InfiniteScrollProps {
  loadMore: () => void;
  refresh: () => void;
  loading?: boolean;
  products: ReadonlyArray<Product>;
  hasMore: boolean;
  target: string;
}

const InfiniteScroll: FC<InfiniteScrollProps> = ({ products = [], refresh, loadMore, hasMore, loading, target }) => {
  const { t } = useTranslation();

  if (loading && !products.length) {
    return (
      <Grid container spacing={2} className="p-3">
        {Array.from(Array(10).keys()).map((i) => (
          <Grid xs={6} sm={4} md={3} item key={i}>
            <LoadCard uniqueKey={`loading-${i}`} />
          </Grid>
        ))}
      </Grid>
    );
  }
  if (!products.length) {
    return (
      <>
        <WrapperEmpty>
          <img src={SEARCH_NOT_FOUND} alt="search-not-found" />
        </WrapperEmpty>
        <TextCenter color="LightGray">{t("common.search.noResult.label")}</TextCenter>
      </>
    );
  }
  return (
    <InfiniteSC
      dataLength={products.length}
      next={loadMore}
      hasMore={hasMore}
      loader={
        <WrapperLoading>
          <CircularProgress />
        </WrapperLoading>
      }
      refreshFunction={refresh}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      pullDownToRefreshContent={<TextCenter>Pull down to refresh</TextCenter>}
      releaseToRefreshContent={<TextCenter>Release to refresh</TextCenter>}
      scrollableTarget={target}
      scrollThreshold={0.9}
    >
      <Grid container className="px-2">
        {products.map((product: Product) => {
          const isOutOfStock = getProductStock(product.productSKUs) === 0;
          return (
            <Grid xs={6} sm={4} md={3} item key={product.id} className="p-2">
              <Link to={getPage(PAGE.Product, { productId: product.id })}>
                <ProductCard product={product} isOutOfStock={isOutOfStock} />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </InfiniteSC>
  );
};

export default InfiniteScroll;
