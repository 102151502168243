import { format, parse } from "date-fns";
import { TFunction } from "react-i18next";

import { DigitalContentOptions, DigitalContentAvailabilityType } from "types/product";

import { DATE_DIGITAL_CONTENT_EXPIRED_AT_FORMAT, DATE_DIGITAL_CONTENT_DISPLAY_FORMAT } from "constants/product";

const getDigitalContentAvailabilityText = (digitalContentOptionsData: DigitalContentOptions, t: TFunction) => {
  const { outputType } = digitalContentOptionsData;
  const availability = digitalContentOptionsData.settings?.availability;
  const settingsType = digitalContentOptionsData.settings?.availability?.type;

  if (!outputType || !availability?.settings) {
    return "";
  }

  if (settingsType === DigitalContentAvailabilityType.PERIOD_AFTER_PURCHASED && availability.settings.value) {
    return `${t("product.digitalContent.availableAt")} ${availability?.settings?.value} ${t(
      "product.digitalContent.days",
    )} ${t("product.digitalContent.afterPurchased")}`;
  }

  if (settingsType === DigitalContentAvailabilityType.EXPIRY_DATE && availability?.settings?.expiredAt) {
    const expiredAt = parse(availability?.settings?.expiredAt, DATE_DIGITAL_CONTENT_EXPIRED_AT_FORMAT, new Date());
    const formattedExpiredAt = format(expiredAt, DATE_DIGITAL_CONTENT_DISPLAY_FORMAT);
    return `${t("product.digitalContent.expireAt")} ${formattedExpiredAt}`;
  }

  if (settingsType === DigitalContentAvailabilityType.DATE_RANGE && availability.settings.availableAt) {
    return `${t("product.digitalContent.availableAt")} ${availability.settings.availableAt} ${t(
      "product.digitalContent.to",
    )} ${t(availability.settings.expiredAt as string)}`;
  }

  return "";
};

export default getDigitalContentAvailabilityText;
