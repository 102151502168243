import React, { FC } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import TextField from "components/Input/TextField";

import { FixWidthTooltip } from "components/Tooltip";
import Icon from "components/Icon";

import { getCreditCardExpiry } from "utilities/credit-card";

import { IconName, IconSize } from "types/icon";

import { COLORS } from "constants/color";

interface CreditCardExpiredDateFieldPropsType {
  onChange: (expireDate: string) => void;
  value: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  placeholder?: string;
  name?: string;
  tooltipText?: string;
}

const CreditCardExpiredDateField: FC<CreditCardExpiredDateFieldPropsType> = (props) => {
  const { onChange, tooltipText } = props;
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <TextField
      {...props}
      isOutlined
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const formatValue = getCreditCardExpiry(e.target.value || "");
        if (typeof onChange === "function") {
          onChange(formatValue);
        }
      }}
      endAdornment={
        tooltipText && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <FixWidthTooltip
              title={tooltipText}
              placement="bottom-end"
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipClose}
              open={open}
            >
              <div onClick={handleTooltipOpen}>
                <Icon name={IconName.INFO} size={IconSize.DEFAULT} color={COLORS.LightGray} />
              </div>
            </FixWidthTooltip>
          </ClickAwayListener>
        )
      }
    />
  );
};

export default CreditCardExpiredDateField;
