import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const MenuIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        className="cls-1"
        d="M46.11,8.52a1.89,1.89,0,0,0-1.89,1.89V41.67a2.54,2.54,0,0,1-2.54,2.55H10.93a1.89,1.89,0,0,0,0,3.78H41.68A6.32,6.32,0,0,0,48,41.68V10.41h0A1.89,1.89,0,0,0,46.11,8.52Z"
      />
      <path
        className="cls-1"
        d="M39.66,37.26V2.39A2.39,2.39,0,0,0,37.26,0H2.39A2.39,2.39,0,0,0,0,2.39V37.26a2.4,2.4,0,0,0,2.39,2.4H37.26A2.39,2.39,0,0,0,39.66,37.26Zm-3.78-1.38H3.78V3.78h32.1Z"
      />
    </SVG>
  );
};

export default MenuIcon;
