import { FC } from "react";

import { IconSize, IconName } from "types/icon";

import Icons from "./Icons";

type IconPropTypes = {
  name: IconName;
  style?: Object;
  color?: string;
  size?: IconSize;
  viewBox?: string;
  className?: string;
};

const IconComponent: FC<IconPropTypes> = ({ name, size = IconSize.MEDIUM, color, style, viewBox, ...props }) => {
  const Icon = Icons[name as keyof typeof Icons];

  return <Icon color={color} size={size} style={style} viewBox={viewBox} {...props} />;
};

IconComponent.displayName = "Icon";

export default IconComponent;
