export const PAGE: { [key: string]: string } = {
  Cart: "/cart",
  Category: "/category/:id",
  EditProduct: "/product/:productId/:productSKUId/edit",
  Error: "/error",
  Home: "/category",
  Product: "/product/:productId",
  Promotion: "/promotion",
  TopSelling: "/top-selling",
  QRCode: "/payment/qrCode/:orderId",
  CashQRPayment: "/payment/qrCode/cash/:orderId",
  CreditQRPayment: "/payment/qrCode/credit/:orderId",
  MobileBanking: "/payment/mobileBanking/:orderId",
  DebitCreditCard: "/payment/creditCard/:orderId",
  EWallet: "/payment/eWallet/:orderId",
  TrueMoneyWallet: "/payment/eWallet/trueMoneyWallet/:orderId",
};

export const PUBLIC_PAGES: { [key: string]: string } = {
  SuccessPayment: "/payment/success",
  FailedPayment: "/payment/fail",
};
