import { FC, useState, useEffect, useCallback } from "react";

import { PopupType } from "types";

import { GB_PRIME_BASE_URL, GB_PRIME_PUBLIC_KEY } from "config";

import { useParams } from "react-router";

import ErrorOrder from "containers/payment/ErrorOrder";

import SupportMobileDevice from "containers/payment/SupportMobileDevice";

import { PopupComponent } from "components/Popup";

import useOrder from "hooks/useOrder";

import { isMobileDevice } from "utilities/common";

import { GeneratePaymentStatus } from "types/order";

import { TrueMoneyWalletSchema } from "types/payment";

import DeeplePayLoading from "../../DeeplePayLoading";
import useTrueMoneyWallet from "../hooks/useTrueMoneyWallet";

const TrueMoneyWallet: FC = () => {
  const { orderId = "" } = useParams<{ orderId: string }>();
  const [isInitialPayment, setIsInitialPayment] = useState<boolean>(false);
  const [trueWalletData, setTrueWalletData] = useState<TrueMoneyWalletSchema>();
  const { order, loadingOrder } = useOrder(orderId);
  const { generateTrueMoneyWallet, loadingGenerateTrueMoneyWallet } = useTrueMoneyWallet(orderId);
  const isErrorOrder = order?.generatePaymentStatus !== GeneratePaymentStatus.SUCCESS;
  const keys = trueWalletData ? (Object.keys(trueWalletData) as (keyof TrueMoneyWalletSchema)[]) : [];

  const generateTrueWalletData = useCallback(async () => {
    const value = await generateTrueMoneyWallet();

    await setTrueWalletData(value);

    const button = document.getElementById("submit");
    if (button) {
      button.click();
    }
  }, []);

  useEffect(() => {
    setIsInitialPayment(true);
    setTimeout(async () => {
      setIsInitialPayment(false);
      generateTrueWalletData();
    }, 2000);
  }, []);

  if (!isMobileDevice) {
    return <SupportMobileDevice />;
  }

  if (isErrorOrder && !loadingOrder) return <ErrorOrder />;

  if (isInitialPayment) {
    return <DeeplePayLoading />;
  }

  if (loadingGenerateTrueMoneyWallet) {
    return <PopupComponent type={PopupType.LOADING} />;
  }

  return (
    <form action={`${GB_PRIME_BASE_URL}/v2/trueWallet`} id="mobileBankingform" method="POST">
      {/* create form to submit to GBPrime */}
      {trueWalletData && keys.map((key) => <input key={key} name={key} type="hidden" value={trueWalletData[key]} />)}
      {trueWalletData && <input name="publicKey" type="hidden" value={GB_PRIME_PUBLIC_KEY} />}
      <button id="submit" type="submit" style={{ visibility: "hidden" }} />
    </form>
  );
};

export default TrueMoneyWallet;
