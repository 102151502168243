import { FC, useLayoutEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { Carousel } from "components/Carousel";

import { Promotion } from "types/promotion";

import { Wrapper } from "./styled";

import CouponComponent from "..";
import useOrientation from "../../../hooks/useOrientation";

type CouponListPropTypes = {
  readonly items: Promotion[];
  handleClick: (data: Promotion) => void;
};

const CouponList: FC<CouponListPropTypes> = ({ items, handleClick }) => {
  const { orientation, screenSize } = useOrientation();
  const [slidesPerView, setSlidesPerView] = useState(1);

  useLayoutEffect(() => {
    setSlidesPerView(screenSize.width / 180);
  }, [orientation, screenSize]);

  return (
    <Wrapper>
      <Carousel
        spaceBetween={2}
        slidesPerView={slidesPerView}
        freeMode
        pagination={{
          clickable: true,
        }}
      >
        {items.map((props) => {
          const { id, name, shortName, endDate } = props;
          const title = shortName || name;

          return (
            <SwiperSlide key={`${name}-${id}`}>
              <CouponComponent handleClick={() => handleClick(props)} title={title} endDate={endDate} />
            </SwiperSlide>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

CouponList.displayName = "CouponList";

export default CouponList;
