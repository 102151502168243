import styled from "styled-components";

export const FooterBox = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: ${({ theme }) => theme.COLORS.Panel};
  border-top: 1px solid ${({ theme }) => theme.COLORS.OldBubbleChatBackground};
  outline: 8px solid ${({ theme }) => theme.COLORS.PrimaryNude};
`;
