import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const InvertMenuIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 100 100", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        className="cls-1"
        d="M37.24,39.45a2.4,2.4,0,0,0,2.4-2.38V2.38h0A2.38,2.38,0,0,0,37.24,0H2.39A2.38,2.38,0,0,0,0,2.38V37.07a2.38,2.38,0,0,0,2.39,2.38Z"
        transform="translate(0 0)"
      />
      <path
        className="cls-1"
        d="M46.11,8.72a1.89,1.89,0,0,0-1.89,1.88V41.71a2.54,2.54,0,0,1-2.54,2.53H11A1.88,1.88,0,1,0,11,48H41.68A6.31,6.31,0,0,0,48,41.71V10.6h0A1.89,1.89,0,0,0,46.11,8.72Z"
        transform="translate(0 0)"
      />
    </SVG>
  );
};

export default InvertMenuIcon;
