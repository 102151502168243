import { Divider as DefaultDivider } from "@material-ui/core";
import styled from "styled-components";

export const Divider = styled(DefaultDivider)`
  margin: 0;

  &.dash {
    border-top: 1px dashed rgba(137, 137, 137, 0.5);
    background-color: transparent;
  }

  &.default {
    border: none;
  }
`;
