import { FC } from "react";
import { Grid, Box } from "@material-ui/core";

import Typography from "components/Typography";

import { Button, Container } from "../styled";
import { ProductSKUWithVariableKeyType } from "../types";

interface GroupOfSKUButtonPropsType {
  skuGroupByValue: Record<string, ProductSKUWithVariableKeyType[]>;
  onChangeSKU: (key: string) => void;
  ownKey: string;
  watchKey?: string;
  label: string | Element;
}

const GroupOfSKUButton: FC<GroupOfSKUButtonPropsType> = ({ skuGroupByValue, onChangeSKU, ownKey, watchKey, label }) => {
  const onClick = (key: string) => {
    onChangeSKU(key);
  };
  return (
    <>
      <Box my={1}>
        <Typography variant="dark-title-2">{label}</Typography>
      </Box>
      <Container container spacing={1}>
        {Object.keys(skuGroupByValue).map((key, index) => {
          const filterByWatchKey = watchKey
            ? skuGroupByValue[key].filter((sku) => {
                return sku.variableKey.includes(watchKey);
              })
            : skuGroupByValue[key];
          const disabled = filterByWatchKey.every((sku) => sku.stock - sku.reservedStock <= 0);
          const active = key === ownKey;
          return (
            <Grid item key={JSON.stringify({ key, disabled, active, index })}>
              <Button
                onClick={() => onClick(key)}
                className={`dark-body-2 ${active ? "active" : ""}`}
                disabled={disabled}
              >
                {key}
              </Button>
            </Grid>
          );
        })}
      </Container>
    </>
  );
};

export default GroupOfSKUButton;
