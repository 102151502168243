import { gql } from "@apollo/client";

import { PROMOTION_FRAGMENT, PROMOTION_CART_FRAGMENT } from "graphql/product/fragments";

export const CART = gql`
  ${PROMOTION_FRAGMENT}
  ${PROMOTION_CART_FRAGMENT}

  query cart {
    cart {
      projectId
      customerUUID
      cartProducts {
        amount
        isFree
        productId
        productSKUId
        stock
        productPrice
        product {
          id
          name
          images {
            src
          }
        }
        productSKU {
          ... on PhysicalProductSKUSchema {
            id
            price
            discountedPrice
            image
            variableTypes {
              key
              value
            }
            promotions {
              ... on PromotionSchema {
                ...PromotionSchema
              }
            }
            stock
            reservedStock
          }
          ... on DigitalProductSKUSchema {
            id
            price
            discountedPrice
            image
            variableTypes {
              key
              value
            }
            promotions {
              ... on PromotionSchema {
                ...PromotionSchema
              }
            }
            stock
            reservedStock
          }
          ... on FoodProductSKUSchema {
            id
            price
            discountedPrice
            image
            variableTypes {
              key
              value
            }
            promotions {
              ... on PromotionSchema {
                ...PromotionSchema
              }
            }
            stock
            reservedStock
          }
        }
        note
        appliedPromotions {
          ... on CartPromotionSchema {
            ...CartPromotionSchema
          }
        }
        discountSummary {
          productSKUId
          amount
          defaultUnitPrice
          discountedUnitPrice
          totalPrice
          totalDiscount
          discountedPrice
        }
      }
      productTotal
      grandTotal
      appliedPromotions {
        ... on CartPromotionSchema {
          ...CartPromotionSchema
        }
      }
    }
  }
`;
