import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const CloseIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        id="Fill"
        d="M.66,47.34a2.25,2.25,0,0,1,0-3.18h0L20.82,24,.66,3.84A2.25,2.25,0,0,1,3.84.65L24,20.82,44.16.66a2.25,2.25,0,1,1,3.18,3.18L27.18,24,47.34,44.16a2.25,2.25,0,0,1-3.18,3.18L24,27.18,3.84,47.34a2.25,2.25,0,0,1-3.18,0Z"
        transform="translate(0 0)"
      />
    </SVG>
  );
};

export default CloseIcon;
