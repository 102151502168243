import styled from "styled-components";

import Button from "components/Button";

export const FooterButton = styled(Button)`
  min-height: 44px;

  svg + h2 {
    margin-left: 9px;
  }
`;
