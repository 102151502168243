import { FC, useRef } from "react";
import { SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Carousel from "components/Carousel";

import { getPage } from "utilities/page";

import { Category } from "types/category";

import { Wrapper, Title } from "./styled";

import CategoryCard from "../CategoryCard";

import { PAGE } from "constants/page";

interface CategoryCarouselType {
  categories: Category[];
}

interface BreakpointType {
  slidesPerView: number;
  spaceBetween: number;
}

export const calculateBreakPoint = (widthArray: number[]) => {
  return widthArray.reduce((acc: Record<number, BreakpointType>, cur: number) => {
    return {
      ...acc,
      [cur]: {
        slidesPerView: cur / 146,
        spaceBetween: 16,
      },
    };
  }, {});
};

const breakpoints = calculateBreakPoint([200, 240, 280, 320, 360, 374, 410, 424, 479, 639, 767, 1365]);

const CategoryCarousel: FC<CategoryCarouselType> = ({ categories }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  return (
    <Wrapper ref={wrapperRef}>
      <>
        <Title variant="dark-title-1">{t("category.carouselTitle")}</Title>
        <Carousel
          slidesPerView={1}
          spaceBetween={5}
          virtual={false}
          preloadImages
          freeMode
          freeModeSticky
          className="px-3"
          breakpoints={breakpoints}
        >
          {categories.map((category) => {
            return (
              <SwiperSlide key={category.id}>
                <Link to={getPage(PAGE.Category, { id: category.id })}>
                  <CategoryCard category={category} />
                </Link>
              </SwiperSlide>
            );
          })}
        </Carousel>
      </>
    </Wrapper>
  );
};

CategoryCarousel.displayName = "CategoryCarousel";

export default CategoryCarousel;
