import { useQuery } from "@apollo/client";

import get from "lodash/get";

import { ORDER } from "graphql/order/query";

const useOrder = (orderId: string) => {
  const { data, loading: loadingOrder } = useQuery(ORDER, { variables: { orderId } });

  return {
    order: get(data, "order"),
    loadingOrder,
  };
};

export default useOrder;
