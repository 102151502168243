const cart = {
  header: {
    label: "ตะกร้าสินค้า",
    items: {
      label: "{{items}} รายการ",
    },
    store: {
      addItem: {
        label: "เพิ่มสินค้า",
      },
    },
  },
  body: {
    emptyCart: "ไม่มีรายการในตะกร้า",
  },
  footer: {
    alert: {
      warning: {
        zeroValue: "รายการสั่งซื้อยอดรวมต่ำกว่ากำหนด",
      },
    },
    total: {
      label: "ยอดรวม",
      shipping: "(ไม่รวมค่าขนส่ง)",
      price: "฿{{total}}",
    },
    submitButton: {
      shopNow: "เลือกสินค้าเพิ่มเติม",
      checkout: "ยืนยันการสั่งซื้อ",
    },
  },
};

export default cart;
