const error = {
  description: {
    default: "The page you request could not be found.",
    badRequest: "This page is only available via smartphone and tablet.",
    notFound: "Page not available",
  },
  subDescription: {
    pleaseContactAdmin: "Please contact admin for item purchase",
  },
  button: {
    back: {
      label: "Go back",
    },
    retry: {
      label: "Retry",
    },
  },
};

export default error;
