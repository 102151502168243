const cart = {
  header: {
    label: "Shopping cart",
    items: {
      label: "{{items}} items",
    },
    store: {
      addItem: {
        label: "Add items",
      },
    },
  },
  body: {
    emptyCart: "Your shopping cart is empty",
  },
  footer: {
    alert: {
      zeroValue: "The total amount of the order is below the specified limit.",
    },
    total: {
      label: "Total",
      shipping: "(Shipping not included)",
      price: "฿{{total}}",
    },
    submitButton: {
      shopNow: "SHOP NOW",
      checkout: "CHECKOUT",
    },
  },
};

export default cart;
