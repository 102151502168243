import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Typography from "components/Typography";
import Icon from "components/Icon";

import { convertPriceFormat } from "utilities/product/convertPriceFormat";
import { getProductSKUText } from "utilities/product/getProductSKUText";

import { getPage } from "utilities/page";

import { IconName } from "types/icon";
import { CartProduct } from "types/cart";
import { CartPromotion } from "types/promotion";

import {
  Wrapper,
  ProductWrapper,
  EditButtonWrapper,
  ProductImage,
  ProductDetailWrapper,
  Badge,
  TextRow,
  DiscountPrice,
  DeleteButtonBox,
} from "./styled";

import { IconSize } from "../../../types/icon";

import { NO_IMG_AVAILABLE } from "constants/image";
import { COLORS } from "constants/color";
import { PAGE } from "constants/page";

type CartProductItemPropsType = {
  product: CartProduct;
  handleDeleteItem: (id: string) => void;
};

const CartProductItem: FC<CartProductItemPropsType> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { product: cartProduct, handleDeleteItem } = props;
  const { amount, product, productSKU, discountSummary, appliedPromotions = [], isFree, stock } = cartProduct;
  const { totalPrice = 0, discountedPrice = 0 } = discountSummary || {};

  const totalProductPrice = convertPriceFormat(totalPrice);
  const totalDiscountedPrice = convertPriceFormat(discountedPrice);
  const productName = product.name;
  const variableTypes = productSKU.variableTypes || [];
  const hasDiscountValue = Boolean(totalPrice > discountedPrice);
  const productSKUText = getProductSKUText(variableTypes);
  const productSKUId = productSKU.id;
  const productImage = productSKU.image || product?.images?.[0]?.src || NO_IMG_AVAILABLE;
  const isOutOfStock = stock - (productSKU?.reservedStock || 0) <= 0;

  const handleClickEditItem = () => {
    history.push(getPage(PAGE.EditProduct, { productId: product.id, productSKUId: productSKU.id }));
  };

  return (
    <Wrapper container>
      <ProductWrapper container>
        <Grid item>
          <ProductImage src={productImage} />
        </Grid>
        <ProductDetailWrapper item container>
          <Typography variant="dark-title-5">{productName}</Typography>
          <TextRow item container direction="row" alignItems="center">
            {!isOutOfStock && (
              <Badge>
                <Typography variant="dark-title-5">{t("product.item.amountBadge", { amount })}</Typography>
              </Badge>
            )}
            <Typography variant="dark-body-4">{productSKUText}</Typography>
          </TextRow>
          <TextRow item container direction="row">
            {isOutOfStock ? (
              <Typography variant="accent-body-2">{t("product.item.outOfStock")}</Typography>
            ) : (
              <>
                <DiscountPrice className={hasDiscountValue ? "line-through" : ""} variant="price-title-6">
                  {t(isFree ? "product.item.free" : "product.item.price", { price: totalProductPrice })}
                </DiscountPrice>
                {hasDiscountValue && (
                  <Typography variant="price-title-5">
                    {t("product.item.price", { price: totalDiscountedPrice })}
                  </Typography>
                )}
              </>
            )}
          </TextRow>
          {Boolean(appliedPromotions.length) &&
            appliedPromotions.map(({ name }: CartPromotion) => {
              return (
                <TextRow item container key={name}>
                  <Typography variant="accent-body-2">{name || ""}</Typography>
                </TextRow>
              );
            })}
        </ProductDetailWrapper>
      </ProductWrapper>
      {!isFree && (
        <>
          <EditButtonWrapper onClick={handleClickEditItem}>
            <Typography variant="gray-body-2">{t("common.actionText.edit.label")}</Typography>
            <Icon name={IconName.NEXT} color={COLORS.DarkMed} />
          </EditButtonWrapper>
          <DeleteButtonBox onClick={() => handleDeleteItem(productSKUId)}>
            <Icon name={IconName.REMOVE} color={COLORS.DarkMed} size={IconSize.EXTRA_SMALL} />
          </DeleteButtonBox>
        </>
      )}
    </Wrapper>
  );
};

export default CartProductItem;
