import { gql } from "@apollo/client";

export const ADD_TO_CART = gql`
  mutation addCartProduct($amount: Int!, $productSKUId: ID!) {
    addCartProduct(amount: $amount, productSKUId: $productSKUId) {
      cartProducts {
        amount
        productSKUId
        productPrice
        discountSummary {
          totalPrice
        }
        stock
      }
      totalProductDiscount
      grandTotal
    }
  }
`;

export const UPDATE_CART = gql`
  mutation updateCartProduct($newProductSKUId: ID, $amount: Int!, $currentProductSKUId: ID!) {
    updateCartProduct(newProductSKUId: $newProductSKUId, amount: $amount, currentProductSKUId: $currentProductSKUId) {
      projectId
      customerUUID
      cartProducts {
        amount
        productSKUId
        productPrice
        discountSummary {
          totalPrice
        }
        stock
      }
      productTotal
      totalProductDiscount
      grandTotal
    }
  }
`;
