export interface CashQR {
  amount: number;
  qrCode: string;
  refId: string;
}

export enum QRPaymentType {
  QR_CASH = "QR_CASH",
  QR_CREDIT = "QR_CREDIT",
}

export enum MobileBankingBank {
  BAY = "BAY",
  SCB = "SCB",
  KBANK = "KBANK",
}

export interface MobileBankingSchema {
  referenceNo: string;
  backgroundUrl: string;
  responseUrl: string;
  bankCode: string;
  checksum: string;
  amount: string;
}

export interface TrueMoneyWalletSchema {
  referenceNo: string;
  backgroundUrl: string;
  responseUrl: string;
  checksum: string;
  amount: string;
}
