import { useLayoutEffect, useCallback, useState, FC, useRef } from "react";

import StickyBreadcrumb, { Swiper } from "components/Carousel/CategoryCarousel/StickyBreadcrumb";
import CategoryCarousel from "components/Carousel/CategoryCarousel";
import SearchInput from "components/SearchInput";

import { Category } from "types/category";

import { StickyWrapper, SearchWrapper, Typography } from "../../../components/Header/styled";

interface HeaderPropsType {
  prefixId?: string;
  categories?: Category[] | [];
  isShowBreadcrumb?: boolean;
  onSearch: (text: string) => void;
  title?: string;
  onClickTitle?: () => void;
  targetRef: HTMLElement | null;
  searchPlaceHolder?: string;
}
let scrollingTimeout: ReturnType<typeof setTimeout>;

const OFFSET_BREADCRUMB = 180;
const OFFSET_SCROLL = 40;
const HomeHeader: FC<HeaderPropsType> = ({
  categories = [],
  prefixId,
  isShowBreadcrumb = false,
  onSearch,
  title,
  onClickTitle,
  targetRef,
  searchPlaceHolder,
}) => {
  const [isShowSticky, setIsShowSticky] = useState(false);
  const [activeCategoryId, setActiveCategoryId] = useState("");
  const [stickySwiper, setStickySwiper] = useState<Swiper | null>(null);
  const refCarouselWrapper = useRef<HTMLDivElement>(null);
  const refSearchWrapper = useRef<HTMLDivElement>(null);
  const prevScrollTopRef = useRef<number>(0);
  const scrollCountTime = useRef(0);

  const findContainerId = useCallback(() => {
    const { scrollTop } = targetRef as HTMLElement;
    const nodeList = document.querySelectorAll<Element>(`[id*='${prefixId}']`);
    const currentNode = Array.from(nodeList).filter((node) => {
      const ele = node as HTMLElement;
      return ele.offsetTop + ele.offsetHeight - OFFSET_BREADCRUMB > scrollTop;
    });
    if (currentNode[0]?.id) {
      return currentNode[0]?.id.replace(`${prefixId}`, "");
    }
    return `${categories?.[0]?.id}`;
  }, [prefixId, categories, targetRef]);

  const scrollToActiveCategory = useCallback(
    (categoryId: string) => {
      const index = categories.findIndex((category) => {
        return `${category.id}` === categoryId;
      });
      if (index !== -1 && stickySwiper) {
        stickySwiper.slideTo(index);
      }
    },
    [stickySwiper, categories],
  );

  const setPrevScrollTopRef = (value: number) => {
    prevScrollTopRef.current = value;
  };

  useLayoutEffect(() => {
    const scrollEvent = () => {
      const scrollTop = targetRef?.scrollTop || 0;
      scrollCountTime.current += 1;
      if (scrollCountTime.current >= 1) {
        const isScrollDown = scrollTop > prevScrollTopRef.current;
        setPrevScrollTopRef(scrollTop);
        if (isScrollDown) {
          setIsShowSticky(false);
        }
      }
      clearTimeout(scrollingTimeout);
      scrollingTimeout = setTimeout(() => {
        if (refCarouselWrapper.current && refSearchWrapper?.current && targetRef) {
          const fromTop = refCarouselWrapper.current.offsetHeight - OFFSET_SCROLL;

          const isShowStickyCondition = scrollTop > fromTop;
          setIsShowSticky(isShowStickyCondition);
          setPrevScrollTopRef(scrollTop);
          scrollCountTime.current = 0;
        }
      }, 100);
      if (prefixId) {
        const activeCategoryId = findContainerId();
        setActiveCategoryId(activeCategoryId);
        scrollToActiveCategory(activeCategoryId);
      }
    };
    targetRef?.addEventListener("scroll", scrollEvent);

    return () => targetRef?.removeEventListener("scroll", scrollEvent);
  }, [targetRef, findContainerId, prefixId, scrollToActiveCategory]);

  const onInitStickyBreadcrumb = (swiper: Swiper) => {
    setStickySwiper(swiper);
  };
  return (
    <>
      <SearchWrapper ref={refSearchWrapper}>
        {title && (
          <Typography variant="dark-title-1" onClick={onClickTitle}>
            {title}
          </Typography>
        )}
        <SearchInput onChange={onSearch} placeholder={searchPlaceHolder || "Search by product name"} />
      </SearchWrapper>
      {!!categories.length && isShowBreadcrumb && (
        <>
          <div ref={refCarouselWrapper}>
            <CategoryCarousel categories={categories} />
          </div>
          <StickyWrapper
            show={isShowSticky && isShowBreadcrumb}
            offsetTop={(refSearchWrapper.current?.offsetHeight || 0) - 10}
          >
            <StickyBreadcrumb
              onInit={onInitStickyBreadcrumb}
              categories={categories}
              activeCategoryId={activeCategoryId}
              prefixId={prefixId}
              targetRef={targetRef}
              setPrevScrollTopRef={setPrevScrollTopRef}
            />
          </StickyWrapper>
        </>
      )}
    </>
  );
};

HomeHeader.displayName = "HomeHeader";

export default HomeHeader;
