import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const PromotionDiscountIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <g id="Group_997" data-name="Group 997">
        <path
          id="Path_330"
          data-name="Path 330"
          className="cls-1"
          d="M37.71,0h-7a.68.68,0,0,0-.67.55,6.17,6.17,0,0,1-4.82,4.82A6.18,6.18,0,0,1,18,.55h0A.69.69,0,0,0,17.28,0h-7A.69.69,0,0,0,9.6.69V47.31a.69.69,0,0,0,.68.69h7a.68.68,0,0,0,.67-.55,6.17,6.17,0,0,1,12.1,0,.68.68,0,0,0,.67.55h7a.69.69,0,0,0,.68-.69V.69A.69.69,0,0,0,37.72,0ZM37,13H35.22a.69.69,0,0,0,0,1.37H37V46.63H31.26a7.55,7.55,0,0,0-14.52,0H11V14.4h1.81a.69.69,0,0,0,0-1.37H11V1.37h5.77A7.55,7.55,0,0,0,22,6.58a7.55,7.55,0,0,0,9.31-5.21H37Z"
        />
        <path
          id="Path_331"
          data-name="Path 331"
          className="cls-1"
          d="M19,14.4A.69.69,0,1,0,19,13H16.52a.68.68,0,0,0-.69.68.69.69,0,0,0,.69.69H19Z"
        />
        <path
          id="Path_332"
          data-name="Path 332"
          className="cls-1"
          d="M22.75,14.4h2.5a.69.69,0,0,0,0-1.37h-2.5a.67.67,0,0,0-.68.68.68.68,0,0,0,.68.69Z"
        />
        <path
          id="Path_333"
          data-name="Path 333"
          className="cls-1"
          d="M28.3,13.71a.69.69,0,0,0,.69.69h2.49a.69.69,0,1,0,0-1.37H29A.69.69,0,0,0,28.3,13.71Z"
        />
        <path
          id="Path_334"
          data-name="Path 334"
          className="cls-1"
          d="M30.63,21.83a.69.69,0,0,0-1,0l0,0L16.93,34.53a.69.69,0,0,0,0,1,.68.68,0,0,0,.95,0L30.61,22.8A.69.69,0,0,0,30.63,21.83Z"
        />
        <path
          id="Path_335"
          data-name="Path 335"
          className="cls-1"
          d="M19.57,27.59a3.17,3.17,0,1,0-3.17-3.17,3.16,3.16,0,0,0,3.17,3.17Zm0-5a1.8,1.8,0,1,1-1.8,1.79h0A1.8,1.8,0,0,1,19.57,22.63Z"
        />
        <path
          id="Path_336"
          data-name="Path 336"
          className="cls-1"
          d="M28.39,30a3.17,3.17,0,1,0,3.16,3.17A3.17,3.17,0,0,0,28.39,30Zm0,5a1.8,1.8,0,1,1,1.79-1.79A1.8,1.8,0,0,1,28.39,35Z"
        />
      </g>
    </SVG>
  );
};

export default PromotionDiscountIcon;
