import { gql } from "@apollo/client";

export const ORDER = gql`
  query order($orderId: ID!) {
    order(orderId: $orderId) {
      id
      projectId
      customerId
      generatePaymentAmount
      generatePaymentStatus

      # Todo: remove comment when use this key
      # shippingMethod
      # shippingPrice
      # shippingDuration
      # state
      # receiverName
      # phoneNumber
      # note
      # email
      # trackingNumber
      # paymentBillImageSrc
      # paymentBankName
      # paymentAt
      # paymentAmount
      # shippedAt
      # firstName
      # lastName
      # streetAddress
      # streetAddress2
      # district
      # subDistrict
      # province
      # postalCode
      # orderNumber
      # cancelReason
      # cancelBy
      # cancelDate
      # vatOptions
      # vatValue
      # totalPrice
      # vatPrice
      # grandTotal
      # discountTotalPrice
      # discountShippingPrice
      # paymentMethodType
      # depositAmount
      # remark
      # depositBillImageSrc
      # depositBankName
      # depositedAt
      # deliverySchedule
      # shippingPriceNote
      # totalWeight
      # referenceCode
      # adminsNote
      # agent
      # shippingCalculationType
      # shippingDescription
      # platform
      # openedBy
      # secondaryPayslipImages
      # additionalDiscount
      # shippingRules
      # merchantId
      # managementFee
      # voidReason
      # voidedBy
      # voidedAt
      # isShippingIncludedInVat
      # largestDimension
      # codInformation
    }
  }
`;

export const ORDER_SUMMARY = gql`
  query order($orderId: ID!) {
    order(orderId: $orderId) {
      id
      state
      generatePaymentAmount
    }
  }
`;
