import { FC } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { parseISO, format } from "date-fns";

import Typography from "components/Typography";
import { Divider } from "components/Divider/styled";

import { getDefaultLanguage } from "utilities/common/language";

import { Promotion } from "types/promotion";

import ProductSKUList from "./ProductItemList";

import { LocaleData } from "constants/date";
import { PROMOTION_END_DATE_DISPLAY_FORMAT } from "constants/product";

const PopupDescription: FC<Promotion> = ({
  name,
  shortName,
  setting,
  description,
  endDate,
  selectedProducts,
  isIncludedAllProducts,
  freeProducts,
}) => {
  const { t } = useTranslation();
  const title = shortName || name;
  const parseISOEndDate = parseISO(endDate);
  const defaultLanguage = getDefaultLanguage();
  const formattedEndDate = endDate
    ? format(parseISOEndDate, PROMOTION_END_DATE_DISPLAY_FORMAT, { locale: LocaleData[defaultLanguage] })
    : "";
  const promotionSettingType = setting?.type || "";

  const hasMainProduct = Boolean((selectedProducts || []).length) || isIncludedAllProducts;
  const hasFreeProduct = Boolean((freeProducts || []).length);

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      <Grid item xs={12}>
        <Typography variant="dark-title-2">{title}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="dark-title-6">{t("product.promotion.name")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="gray-body-1">{name}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="dark-title-6">{t("product.promotion.type")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="gray-body-1">{t(`product.promotion.promotionType.${promotionSettingType}`)}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="dark-title-6">{t("product.promotion.applyBefore")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="gray-body-1">{formattedEndDate}</Typography>
      </Grid>

      {description && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="dark-title-6">{description}</Typography>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {hasMainProduct && (
        <>
          <Grid item xs={12}>
            <Typography variant="dark-title-5">{t("product.promotion.mainProduct")}</Typography>
          </Grid>
          <ProductSKUList productSKUs={selectedProducts} isIncludedAllProducts={isIncludedAllProducts} />
        </>
      )}

      {hasFreeProduct && (
        <>
          <Grid item xs={12}>
            <Typography variant="dark-title-5">{t("product.promotion.freeProduct")}</Typography>
          </Grid>
          <ProductSKUList productSKUs={freeProducts} />
        </>
      )}
    </Grid>
  );
};

export default PopupDescription;
