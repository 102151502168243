import { FC } from "react";

import { useHistory } from "react-router-dom";

import Icon from "components/Icon";

import Footer from "components/Footer/index";

import Typography from "components/Typography";

import { getPage } from "utilities/page";

import { IconName, IconSize } from "types/icon";

import FooterButton, { FooterButtonPropTypes } from "./FooterButton";

import { HomeButton, CartButton, CartBadge } from "./styled";

import { COLORS } from "constants/color";
import { PAGE } from "constants/page";

interface ProductFooterButtonPropTypes extends FooterButtonPropTypes {
  productInCart: number;
}

const ProductFooterButton: FC<ProductFooterButtonPropTypes> = (props) => {
  const { productInCart, isEditProduct } = props;

  const history = useHistory();
  const cartText = productInCart > 9 ? "9+" : productInCart;

  return (
    <>
      {!isEditProduct && (
        <CartButton handleClick={() => history.push(getPage(PAGE.Cart))}>
          <Icon name={IconName.INVERT_CART} size={IconSize.MEDIUM} color={COLORS.LightWhite} />
          <CartBadge className={productInCart > 0 ? "" : "hidden"}>
            <Typography variant="white-title-6">{cartText}</Typography>
          </CartBadge>
        </CartButton>
      )}
      <Footer>
        {!isEditProduct && (
          <HomeButton handleClick={() => history.push(getPage(PAGE.Home))}>
            <Icon name={IconName.HOME} size={IconSize.MEDIUM} />
          </HomeButton>
        )}
        <FooterButton {...props} />
      </Footer>
    </>
  );
};

export default ProductFooterButton;
