import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const NotificationIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path className="cls-1" d="M13.58,1.5H9.7a8.17,8.17,0,0,0,0,16.33h3.88a8.17,8.17,0,1,0,0-16.33Z" />
      <path
        className="cls-2"
        d="M13.54,19.33H9.67A9.67,9.67,0,1,1,9.67,0h3.87a9.67,9.67,0,1,1,0,19.33ZM9.67,1.5a8.17,8.17,0,1,0,0,16.33h3.87a8.17,8.17,0,1,0,0-16.33Z"
      />
      <path fill={COLORS.White} className="cls-2" d="M10.94,7.21,9.34,8.3V7.06l1.75-1.24h1.26v7.55H10.94Z" />
    </SVG>
  );
};

export default NotificationIcon;
