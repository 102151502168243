import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 58px;
  background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  padding: 4px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;

  svg {
    border: none;
    fill: transparent;
  }

  path.cls-3 {
    fill: transparent;
  }

  :hover {
    border: 1px solid ${({ theme }) => theme.COLORS.Primary};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  &.disabled {
    pointer-events: none;
    border: none !important;
  }
`;

export const CounterButton = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.COLORS.LightWhite} !important;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Input = styled.input`
  width: 100%;
  font-size: 20px;
  line-height: 11px;
  text-align: center;
  font-family: "Graphik";
  background-color: transparent;
  border: none;
  outline: none;
`;
