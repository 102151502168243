import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import qs from "qs";
import { RouteChildrenProps } from "react-router-dom";

import InfiniteScroll from "containers/category/InfiniteScroll";

import HeaderWithSearch from "components/Header/HeaderWithSearch";

import useInfiniteScroll from "hooks/useInfiniteScroll";

import { Wrapper } from "./styled";

interface PromotionVariable {
  keyword: string;
  isOnlyHavePromotion: boolean;
  promotionIds?: ReadonlyArray<string>;
}

const Promotion: FC<RouteChildrenProps> = ({ location }) => {
  const { search } = location;

  const { t } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const { promotionIds: qsPromotionIds = "" } = qs.parse(search.slice(1));
  const promotionIds = (qsPromotionIds as string).split(",").filter((promotionId) => promotionId);

  let variable: PromotionVariable = {
    keyword,
    isOnlyHavePromotion: true,
  };

  if (promotionIds.length) {
    variable = {
      ...variable,
      promotionIds,
    };
  }

  const { loadMore, refresh, hasMore, products, loading } = useInfiniteScroll(variable);

  const onSearch = debounce((text) => {
    setKeyword(text);
  }, 500);

  return (
    <Wrapper id="promotion-container">
      <HeaderWithSearch onSearch={onSearch} title={t("promotion.title")} />
      <InfiniteScroll
        products={products.results}
        loading={loading}
        loadMore={loadMore}
        refresh={refresh}
        hasMore={hasMore()}
        target="promotion-container"
      />
    </Wrapper>
  );
};

export default Promotion;
