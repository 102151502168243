import { FC } from "react";
import { RouteChildrenProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "qs";

import InfiniteScroll from "containers/category/InfiniteScroll";

import { Helmet } from "react-helmet-async";

import Typography from "components/Typography";

import useInfiniteScroll from "hooks/useInfiniteScroll";

import { Wrapper, TopSellingHeader, HeaderWrapper } from "./styled";

const TopSelling: FC<RouteChildrenProps> = ({ location }) => {
  const { search } = location;

  const { t } = useTranslation();
  const { isTopSellingProduct, productIds: qsProductIds = "" } = qs.parse(search.slice(1));

  const productIds = (qsProductIds as string).split(",").filter((productId) => productId);
  const { loadMore, refresh, hasMore, products, loading } = useInfiniteScroll({
    productIds,
  });

  const title = (isTopSellingProduct as string) === "true" ? t("topSelling.title") : t("product.title");

  return (
    <Wrapper id="top-selling-container">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <HeaderWrapper shadow={false}>
        <TopSellingHeader container alignItems="center" justify="flex-start">
          <Typography variant="dark-title-1">{title}</Typography>
        </TopSellingHeader>
      </HeaderWrapper>
      <InfiniteScroll
        products={products.results}
        loading={loading}
        loadMore={loadMore}
        refresh={refresh}
        hasMore={hasMore()}
        target="top-selling-container"
      />
    </Wrapper>
  );
};

export default TopSelling;
