import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const CreditCardOutlineIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 32 32", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} viewBox={viewBox} fill="none">
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H27C29.7614 0 32 2.23858 32 5V27C32 29.7614 29.7614 32 27 32H5C2.23858 32 0 29.7614 0 27V5Z"
        fill={COLORS.PrimaryNude}
      />
      <rect x="5.75" y="8.75" width="20.5" height="14.5" rx="1.25" stroke={color} strokeWidth="1.5" />
      <line x1="6" y1="13.25" x2="26" y2="13.25" stroke={color} strokeWidth="1.5" />
      <line x1="8.75" y1="17.25" x2="13.25" y2="17.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </SVG>
  );
};

export default CreditCardOutlineIcon;
