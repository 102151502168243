import { gql } from "@apollo/client";

export const SHIPPINGS = gql`
  query {
    shippings {
      iconURL
      disabled
      description
      maximumPrice
      maxDuration
      methodType
      minimumPrice
      setting
      calculateType
      isReceiveOrderOutsideDeliveryTime
      disabledDuration
      pricePerUnit
      minDuration
      fixedPrice
      disabledReason
      isDisabledSchedulePickUp
      isCODPayment
    }
  }
`;
