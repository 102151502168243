import { debounce } from "lodash";
import { useLayoutEffect, useState } from "react";

export enum OrientationType {
  Landscape = "landscape",
  Portrait = "portrait",
}

type ScreenSize = {
  width: number;
  height: number;
};

const useOrientation = () => {
  const checkOrientation = () =>
    window.innerWidth > window.innerHeight ? OrientationType.Landscape : OrientationType.Portrait;
  const [orientation, setOrientation] = useState(checkOrientation());
  const [screenSize, setScreenSize] = useState<ScreenSize>({ width: window.innerWidth, height: window.innerHeight });
  const DEBOUNCE_TIME = 100;

  useLayoutEffect(() => {
    const handleOrientationChange = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
      setOrientation(checkOrientation());
    };

    window.addEventListener("resize", debounce(handleOrientationChange, DEBOUNCE_TIME));
    return () => {
      window.removeEventListener("resize", debounce(handleOrientationChange, DEBOUNCE_TIME));
    };
  }, [orientation]);

  return { orientation, screenSize };
};

export default useOrientation;
