import { FC, useEffect, useState } from "react";

import { ProductSKU } from "types";

import { getProductStock } from "utilities/product/productStock";

import { ProductSKUWithVariableKeyType, ProductSKUsPropsType } from "./types";
import TwoVariantVariableTypeSelector from "./components/TwoVariantVariableTypeSelector";
import OneVariantVariableTypeSelector from "./components/OneVariantVariableTypeSelector";

const ProductSKUSelectors: FC<ProductSKUsPropsType> = ({ initialProductSKU, onChange, productSKUs, ...props }) => {
  const variableLength = productSKUs[0].variableTypes.length;
  const [primaryKey, setPrimaryKey] = useState("");
  const [secondaryKey, setSecondaryKey] = useState("");

  const productSKUWithVariableKey: ProductSKUWithVariableKeyType[] = productSKUs.map((productSKU) => {
    const variableKey = [""];
    for (let i = 0; i < variableLength; i += 1) {
      variableKey.push(productSKU.variableTypes[i].value);
    }
    return {
      ...productSKU,
      variableKey,
    };
  });

  const setActiveSKU = (sku: ProductSKU) => {
    if (sku?.variableTypes && sku?.variableTypes.length > 0) {
      setPrimaryKey(sku?.variableTypes[0].value || "");

      if (variableLength === 2) {
        setSecondaryKey(sku?.variableTypes[1].value || "");
      }
    }
  };

  useEffect(() => {
    if (initialProductSKU) {
      // set sku if in edit product mode
      setActiveSKU(initialProductSKU);
      const { amount = 0, reservedStock = 0, stock = 0 } = initialProductSKU;
      const availableAmount = stock - reservedStock < amount ? stock - reservedStock : amount;
      onChange(initialProductSKU, availableAmount);
    } else {
      const stock = getProductStock(productSKUs);

      if (stock === 0) {
        // set sku when all of sku is out of stock for disable checkout cart button
        onChange(productSKUs[0], 0);
      } else if (variableLength === 0) {
        // set default sku when have only 1 sku (not have choice to select sku)
        onChange(productSKUs[0], 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProductSKU, variableLength]);

  if (variableLength === 1) {
    return (
      <OneVariantVariableTypeSelector
        productSKUWithVariableKey={productSKUWithVariableKey}
        onChange={onChange}
        primaryKey={primaryKey}
        setPrimaryKey={setPrimaryKey}
        {...props}
      />
    );
  }

  if (variableLength === 2) {
    return (
      <TwoVariantVariableTypeSelector
        productSKUWithVariableKey={productSKUWithVariableKey}
        onChange={onChange}
        primaryKey={primaryKey}
        setPrimaryKey={setPrimaryKey}
        secondaryKey={secondaryKey}
        setSecondaryKey={setSecondaryKey}
        {...props}
      />
    );
  }

  return null;
};

export default ProductSKUSelectors;
