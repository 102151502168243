import { FC } from "react";

import { Wrapper, Button } from "./styled";

type StickyButtonPropTypes = {
  handleClick?: () => void;
};

export const StickyButton: FC<StickyButtonPropTypes> = ({ children, handleClick }) => {
  return (
    <Wrapper>
      <Button handleClick={handleClick}>{children}</Button>
    </Wrapper>
  );
};

StickyButton.displayName = "StickyButton";

export default StickyButton;
