export enum IconName {
  BACK_ARROW = "BACK_ARROW",
  BACK = "BACK",
  CART = "CART",
  CIRCLE_REMOVE = "CIRCLE_REMOVE",
  CLEAR = "CLEAR",
  CLOSE = "CLOSE",
  CREDIT_CARD = "CREDIT_CARD",
  CREDIT_CARD_JCB = "CREDIT_CARD_JCB",
  CREDIT_CARD_MASTER = "CREDIT_CARD_MASTER",
  CREDIT_CARD_VISA = "CREDIT_CARD_VISA",
  CREDIT_CARD_OUTLINE = "CREDIT_CARD_OUTLINE",
  EMPTY_CART = "EMPTY_CART",
  ERROR = "ERROR",
  HOME = "HOME",
  INFO = "INFO",
  INVERT_CART = "INVERT_CART",
  INVERT_MENU = "INVERT_MENU",
  INVERT_PROMOTION_DISCOUNT = "INVERT_PROMOTION_DISCOUNT",
  INVERT_TRASH = "INVERT_TRASH",
  MENU = "MENU",
  MINUS = "MINUS",
  NEXT = "NEXT",
  NOTIFICATION_ALERT = "NOTIFICATION_ALERT",
  PICKUP = "PICKUP",
  PLUS = "PLUS",
  PROMOTION_DISCOUNT = "PROMOTION_DISCOUNT",
  REMOVE = "REMOVE",
  SEARCH = "SEARCH",
  SELECTED = "SELECTED",
  DOWNLOAD = "DOWNLOAD",
  RELOAD = "RELOAD",
  WARNING = "WARNING",
}

export enum IconSize {
  EXTRA_SMALL = "extra-small",
  SMALL = "small",
  DEFAULT = "default",
  MEDIUM = "medium",
  LARGE = "large",
  EXTRA_LARGE = "extra-large",
}

export type SVGIconPropTypes = {
  color?: string;
  style?: Object;
  size?: IconSize;
  viewBox?: string;
  className?: string;
};
