import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { COLORS } from "constants/color";

export const Wrapper = styled(Grid)`
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
`;

export const Logo = styled.img`
  max-width: 207px;
  max-height: 207px;
  width: 100%;
`;

export const Button = styled.button`
  max-width: 134px;
  height: 30px;
  margin: 16px;
  width: 100%;
  font-size: 14px;
  color: ${COLORS.White};
  background-color: ${COLORS.Primary};
  box-shadow: none;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
`;

export const ErrorLogo = styled.img`
  width: 100%;
  max-width: ${(props) => props.width || 500}px;
`;

export const ErrorDescriptionText = styled.h3`
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.LightWhite};
  line-height: 24px;
`;

export const ErrorButtonText = styled.h3`
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.LightWhite};
  line-height: 24px;
`;
