import { FC } from "react";

import { useTranslation } from "react-i18next";

import Typography from "components/Typography";

import Icon from "components/Icon";

import { getProductSKUText } from "utilities/product/getProductSKUText";

import { SelectedProduct, FreeProduct } from "types/promotion";

import { IconName, IconSize } from "types/icon";

import { ImageThumbnail, PopupProductWrapper } from "./styled";

import { NO_IMG_AVAILABLE } from "constants/image";

interface ProductSKUListPropTypes {
  productSKUs: SelectedProduct[] | FreeProduct[];
  isIncludedAllProducts?: boolean;
}

const ProductSKUList: FC<ProductSKUListPropTypes> = ({ productSKUs, isIncludedAllProducts = false }) => {
  const { t } = useTranslation();

  if (isIncludedAllProducts) {
    return (
      <PopupProductWrapper key="included-promotions" item xs={12}>
        <Icon size={IconSize.MEDIUM} name={IconName.INVERT_PROMOTION_DISCOUNT} />
        <Typography variant="gray-body-2">{t("promotion.includeAllProduct.label")}</Typography>
      </PopupProductWrapper>
    );
  }

  return (
    <>
      {productSKUs.map((focusedProduct) => {
        const { productCode, image: mainProductSKUImage, product, variableTypes } = focusedProduct;
        const image = mainProductSKUImage || product.images?.[0].src || NO_IMG_AVAILABLE;
        const productSKUText = getProductSKUText(variableTypes);

        return (
          <PopupProductWrapper key={productCode} item xs={12}>
            <>
              <ImageThumbnail src={image} alt={image} />
              <Typography variant="dark-title-6">
                {product.name} {productSKUText}
              </Typography>
            </>
          </PopupProductWrapper>
        );
      })}
    </>
  );
};

export default ProductSKUList;
