// System
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5006";

// Sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "development";

// Line
export const LIFF_ID = process.env.REACT_APP_LIFF_ID;

// Google Analytic
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const GTM_AUTH = process.env.REACT_APP_GTM_AUTH;
export const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW;

// Development mode
export const DEEPLE_TOKEN = process.env.REACT_APP_DEEPLE_TOKEN;
export const IS_DEV = process.env.REACT_APP_ENV === "development";
export const IS_TEST = process.env.REACT_APP_ENV === "test";
export const IS_V_CONSOLE_ENABLED = process.env.REACT_APP_IS_V_CONSOLE_ENABLED === "true";
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

// other
export const IS_ALLOW_CREDIT_QR = process.env.REACT_APP_IS_ALLOW_CREDIT_QR === "true";

// GBPrime
export const GB_PRIME_BASE_URL = process.env.REACT_APP_GB_PRIME_BASE_URL || "https://api.globalprimepay.com";
export const GB_PRIME_PUBLIC_KEY = process.env.REACT_APP_GB_PRIME_PUBLIC_KEY;

// REACT_APP_GB_PRIME_BASE_URL=https://api.gbprimepay.com REACT_APP_GB_PRIME_PUBLIC_KEY=pREzVRK4SPIcWpybGvlBjmxaThJyeweL REACT_APP_API_BASE_URL=https://e-commerce-webview-service.deeple.ai REACT_APP_LIFF_ID=1651109655-PZ7mrj1o yarn build
