import React, { FC } from "react";

import Icon from "components/Icon";

import { prettyCardNumber, getCreditCardInformation } from "utilities/credit-card";

import { IconName, IconSize } from "types/icon";

import { CreditCardStyleField } from "./styed";

import { COLORS } from "constants/color";

interface CreditCardFieldPropsType {
  onChange?: (cardNumber: string) => void;
  value?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  placeholder?: string;
  name?: string;
}

const CreditCardField: FC<CreditCardFieldPropsType> = (props) => {
  const { onChange = () => {}, error, helperText, placeholder = "", fullWidth, value = "", ...otherProps } = props;
  const handleChangeCardNumber = (creditCardNumber: string) => {
    const {
      creditCardType,
      isValid: isFormatValid,
      cardNumberLengths,
      cardNumberGaps,
    } = getCreditCardInformation(creditCardNumber);

    const cardNumberWithoutSpace = creditCardNumber.replace(/\D+/g, "");
    const formattedCardNumber = prettyCardNumber(cardNumberWithoutSpace, creditCardType);
    const maxLength = cardNumberLengths.pop() || 16;
    const gapCount = cardNumberGaps.length || 3;

    return { formattedCardNumber, maxLength: maxLength > 16 ? 16 : maxLength, gapCount, isValid: isFormatValid };
  };
  const { formattedCardNumber: maskValue, isValid } = handleChangeCardNumber(value);

  const onClearValue = () => {
    onChange("");
  };
  return (
    <CreditCardStyleField
      {...otherProps}
      fullWidth={fullWidth}
      value={maskValue}
      error={error}
      isBoldText
      helperText={helperText}
      placeholder={placeholder}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = event.target;
        const valueWithoutMask = inputValue.replace(/\s/g, "");
        const inputLength = inputValue.toString().length;
        const { maxLength, gapCount } = handleChangeCardNumber(inputValue);
        if (inputLength <= maxLength + gapCount) {
          onChange(valueWithoutMask);
        }
      }}
      startAdornment={
        <Icon
          className="p-0 credit-card-jcb-type"
          name={IconName.CREDIT_CARD_OUTLINE}
          size={IconSize.LARGE}
          color={error ? COLORS.Error : COLORS.Primary}
        />
      }
      endAdornment={
        value &&
        (isValid ? (
          <Icon className="p-0" name={IconName.SELECTED} size={IconSize.MEDIUM} color={COLORS.SystemTag} />
        ) : (
          <div onClick={onClearValue}>
            <Icon className="p-0" name={IconName.CIRCLE_REMOVE} size={IconSize.MEDIUM} />
          </div>
        ))
      }
    />
  );
};

export default CreditCardField;
