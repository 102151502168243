import common from "./common/th";
import product from "./product/th";
import cart from "./cart/th";
import category from "./category/th";
import home from "./home/th";
import promotion from "./promotion/th";
import topSelling from "./top-selling/th";
import error from "./error/th";
import addCreditCard from "./add-credit-card/th";
import payment from "./payment/th";

const th = {
  home,
  common,
  product,
  cart,
  category,
  promotion,
  topSelling,
  error,
  addCreditCard,
  payment,
};

export default th;
