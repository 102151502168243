import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const InvertPromotionDiscountIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 11.515 20", color = COLORS.DarkLight } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        id="Path_352"
        fill={color}
        d="M97.245 90.477h.782v-4.909a.242.242 0 0 0-.242-.242h-2.13a.241.241 0 0 0-.228.163 3.333 3.333 0 0 1-6.314 0 .241.241 0 0 0-.229-.164h-2.13a.242.242 0 0 0-.242.242v4.909h.782a.315.315 0 0 1 .32.287.3.3 0 0 1-.3.319h-.8v14a.242.242 0 0 0 .242.242h2.13a.24.24 0 0 0 .228-.163 3.333 3.333 0 0 1 6.314 0 .241.241 0 0 0 .229.164h2.13a.242.242 0 0 0 .242-.242v-14h-.8a.3.3 0 0 1-.3-.319.315.315 0 0 1 .316-.287zm-5.51 0h1.085a.3.3 0 0 1 .3.32.315.315 0 0 1-.32.287h-1.082a.3.3 0 0 1-.3-.319.315.315 0 0 1 .317-.288zm-3.075.287a.314.314 0 0 1 .319-.287h1.068a.314.314 0 0 1 .319.287.3.3 0 0 1-.3.319h-1.1a.3.3 0 0 1-.306-.319zm1.65 3.35a1.4 1.4 0 1 1-1.4 1.4 1.4 1.4 0 0 1 1.4-1.4zm-.738 6.3a.3.3 0 0 1-.429-.429l5.619-5.616a.3.3 0 1 1 .427.43zm4.795.361A1.4 1.4 0 1 1 95.6 99.54a1.4 1.4 0 0 1-1.233 1.232zm1.207-9.688H94.49a.314.314 0 0 1-.32-.287.3.3 0 0 1 .3-.319h1.085a.315.315 0 0 1 .32.287.3.3 0 0 1-.301.316z"
        transform="translate(-86.511 -85.326)"
      />
      <path
        id="Path_353"
        fill={color}
        d="M124.63 203.548a.943.943 0 1 0-.89-.915.94.94 0 0 0 .89.915z"
        transform="translate(-120.858 -192.419)"
      />
      <path
        id="Path_354"
        fill={color}
        d="M172.96 249.558a.951.951 0 0 0-.95.978.961.961 0 0 0 .923.923.951.951 0 1 0 .027-1.9z"
        transform="translate(-165.257 -236.462)"
      />
    </SVG>
  );
};

export default InvertPromotionDiscountIcon;
