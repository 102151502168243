import { Grid } from "@material-ui/core";
import { FC } from "react";
import { ErrorCode } from "types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import httpStatus from "http-status";

import Typography, { TypographyVariant } from "components/Typography";

import { Wrapper, Button, ErrorLogo, ErrorButtonText } from "./styled";

import { ERROR_PAGE_LOGO, ERROR_DEVICE_NOT_SUPPORT, PAGE_NOT_AVAILABLE_LOGO } from "constants/image";

type ErrorPagePropTypes = {
  error?: ErrorCode | string | number; // to support other case, Todo: use when integrate with service
  handleClick?: () => void;
};

const Error: FC<ErrorPagePropTypes> = ({ handleClick = () => {}, error }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const hasPrevPage = window.location.pathname === "/error"; // check case come to this path by redirect

  const handleClickButton = () => {
    handleClick();

    if (hasPrevPage) {
      history.goBack();
      return;
    }

    window.location.reload();
  };
  let errorImage = ERROR_PAGE_LOGO;
  let errorImageWidth = 500;
  let errorDescription = "error.description.default";
  let errorSubDescription = "";
  let errorDescriptionVariant: TypographyVariant = "dark-title-4";
  let errorSubDescriptionVariant: TypographyVariant = "dark-title-4";
  let errorButtonLabel = hasPrevPage ? "error.button.back.label" : "error.button.retry.label";

  switch (error) {
    case httpStatus.BAD_REQUEST:
      errorImage = ERROR_DEVICE_NOT_SUPPORT;
      errorDescription = "error.description.badRequest";
      errorDescriptionVariant = "dark-title-2";
      errorButtonLabel = "";
      errorImageWidth = 500;
      break;
    case httpStatus.NOT_FOUND:
      errorImage = PAGE_NOT_AVAILABLE_LOGO;
      errorDescription = "error.description.notFound";
      errorDescriptionVariant = "dark-title-2";
      errorSubDescription = "error.subDescription.pleaseContactAdmin";
      errorSubDescriptionVariant = "dark-title-4";
      errorButtonLabel = "";
      errorImageWidth = 156;
      break;
    default:
      break;
  }

  return (
    <Wrapper container spacing={2} justify="center" alignItems="center">
      <Grid item xs={12}>
        <ErrorLogo src={errorImage} alt="error" width={errorImageWidth} />
        <div>
          <Typography variant={errorDescriptionVariant}>{t(errorDescription)}</Typography>
        </div>
        {!!errorSubDescription && (
          <div className="mt-2">
            <Typography variant={errorSubDescriptionVariant}>{t(errorSubDescription)}</Typography>
          </div>
        )}

        <div>
          {!!errorButtonLabel && (
            <Button onClick={handleClickButton}>
              <ErrorButtonText>{t(errorButtonLabel)}</ErrorButtonText>
            </Button>
          )}
        </div>
      </Grid>
    </Wrapper>
  );
};

export default Error;
