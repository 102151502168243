import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const NextIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG
      style={style}
      className={size}
      color={color}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
    >
      <path
        id="Fill"
        className="cls-1"
        d="M26,24l-8.47,8.47a2.07,2.07,0,1,0,2.92,2.93l9.94-9.94a2.06,2.06,0,0,0,0-2.92h0L20.49,12.6a2.07,2.07,0,0,0-2.92,2.93Z"
      />
    </SVG>
  );
};

export default NextIcon;
