import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";

import { COLORS } from "../../constants/color";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundColor: COLORS.Panel,
      color: COLORS.Primary,
      boxShadow: "none",
      height: "48px",
    },
    dialog: {
      backgroundColor: "transparent",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: COLORS.LightWhite,
    },
  }),
);

export const PopupWrapper = styled.div`
  position: fixed;
  bottom: 0;
  height: calc(100 * var(--vh, 1vh)) !important;
  width: 100vw;
  background-color: ${({ theme }) => theme.COLORS.Panel};

  &.halfScreen {
    max-height: 400px;
  }
`;

export const Popup = styled(Dialog)<{ backgroundColor?: string }>`
  &.halfScreen .MuiDialog-paperFullScreen {
    position: fixed;
    bottom: 0;
    max-height: 400px;
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.COLORS.White};
  }

  .MuiDialogContent-root {
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.COLORS.White};
  }

  .MuiDialogContent-root {
    padding: 0 24px;
    overflow-y: auto;
  }
`;

export const GridContainer = styled(Grid)<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.COLORS.White};
`;

export const GridItem = styled(Grid)`
  display: flex;
  align-items: center;

  &.close {
    justify-content: flex-end;
  }
`;

export const Content = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 16px;
`;
