import qs from "qs";
import get from "lodash/get";

import { DEEPLE_TOKEN, IS_DEV, IS_TEST, PROJECT_ID } from "config";

import { DefaultQueryString, LocationQueryString, Platform } from "types";

import { AUTHORIZATION_HEADER_KEY } from "../../constants/http";
import { TOKEN_LOCAL_STORAGE_KEY, PROJECT_ID_KEY } from "../../constants";

const getQueryString = (): LocationQueryString => {
  return qs.parse(window.location.search?.slice(1) || "");
};

const getFallbackQueryStringFromLiffState = (defaultQueryString: LocationQueryString): DefaultQueryString => {
  const liffState = get(defaultQueryString, "liff.state");
  if (!liffState) {
    return {};
  }

  return qs.parse(get(liffState.split("?"), "1"));
};

export const getPlatform = (): Platform | undefined => {
  const defaultQueryString = getQueryString();
  const platform = get(defaultQueryString, "platform");
  if (platform) {
    return platform as Platform;
  }

  // Fallback query string from liff.state
  const liffStateQueryString = getFallbackQueryStringFromLiffState(defaultQueryString);

  return get(liffStateQueryString, "platform") as Platform;
};

export const getToken = (): string | undefined => {
  const defaultQueryString = getQueryString();
  const token = get(defaultQueryString, "token");
  if (token) {
    return token;
  }

  const liffStateQueryString = getFallbackQueryStringFromLiffState(defaultQueryString);
  const fallbackToken = get(liffStateQueryString, "token");
  /**
   * Use default project id and default token if IS_DEV or IS_TEST
   * is enabled
   */
  if (!fallbackToken && (IS_DEV || IS_TEST)) {
    return DEEPLE_TOKEN;
  }

  return fallbackToken;
};

export const getProjectIdAndToken = (): { readonly projectId?: string; readonly token?: string } => {
  const defaultQueryString = getQueryString();
  const projectId = get(defaultQueryString, "projectId");
  const token = get(defaultQueryString, "token");
  if (projectId && token) {
    return { projectId, token };
  }

  const liffStateQueryString = getFallbackQueryStringFromLiffState(defaultQueryString);
  const fallbackProjectId = get(liffStateQueryString, "projectId");
  const fallbackToken = get(liffStateQueryString, "token");
  /**
   * Use default project id and default token if IS_DEV or IS_TEST
   * is enabled
   */
  if ((!fallbackProjectId || !fallbackToken) && (IS_DEV || IS_TEST)) {
    return { projectId: fallbackProjectId || PROJECT_ID, token: fallbackToken || DEEPLE_TOKEN };
  }

  return { projectId: fallbackProjectId, token: fallbackToken };
};

export const getAuthorizationHeader = () => {
  return localStorage.getItem(AUTHORIZATION_HEADER_KEY);
};

export const saveProjectId = (token: string) => {
  localStorage.setItem(PROJECT_ID_KEY, token);
};

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token);
};

export const saveAuthorizationHeader = (header: string) => {
  localStorage.setItem(AUTHORIZATION_HEADER_KEY, header);
};

export const removeProjectId = () => {
  localStorage.removeItem(PROJECT_ID_KEY);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
};

export const isTokenExpired = (token?: string) => {
  if (!token) {
    return true;
  }

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return Date.now() >= payload.exp * 1000;
  } catch (e) {
    return true; // treat token as expired if it's can't interpret
  }
};
