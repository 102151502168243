import { FC, useContext } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { StylesProvider, createMuiTheme } from "@material-ui/core/styles";

import ErrorPage from "containers/error";

import isNil from "lodash/isNil";

import { ProjectContext } from "context/project";

import { THEME_TYPE } from "constants/theme";
import { COLORS } from "constants/color";

export const ThemeProvider: FC = ({ children }) => {
  const project = useContext(ProjectContext);

  if (!project) {
    return <ErrorPage />;
  }

  const muiTheme = createMuiTheme();
  let themePrimary = COLORS.Primary;
  let secondaryPrimary = COLORS.White;

  if (!isNil(project?.configuration?.theme)) {
    themePrimary = project?.configuration?.theme?.color?.primary;
    secondaryPrimary = project?.configuration?.theme?.color?.secondary;
  }

  const color = {
    ...THEME_TYPE.default,
    Primary: themePrimary,
    Secondary: secondaryPrimary,
  };

  return (
    <StyledThemeProvider theme={{ COLORS: color, breakpoints: muiTheme.breakpoints }}>
      <StylesProvider injectFirst>{children}</StylesProvider>
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
