import styled from "styled-components";
import SwiperCore, { Virtual } from "swiper";
import { Swiper } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Virtual]);

export const Carousel = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-scrollbar {
    display: none;
  }
`;
