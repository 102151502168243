import { ShippingRule } from "./shipping";
import { Promotion } from "./promotion";

export enum DigitalProductInventoryStatus {
  AVAILABLE = "AVAILABLE",
  PURCHASED = "PURCHASED",
  REDEEMED = "REDEEMED",
  EXPIRED = "EXPIRED",
}

export enum ProductOutputType {
  STATIC = "STATIC",
  QR_CODE = "QR_CODE",
  CODE_IMPORTED = "CODE_IMPORTED",
  CODE_AUTO_GENERATED = "CODE_AUTO_GENERATED",
  CODE_GENERATED_FROM_OTHER = "CODE_GENERATED_FROM_OTHER",
}

export enum DigitalContentAvailabilityType {
  NO_EXPIRY = "NO_EXPIRY",
  DATE_RANGE = "DATE_RANGE",
  EXPIRY_DATE = "EXPIRY_DATE",
  PERIOD_AFTER_PURCHASED = "PERIOD_AFTER_PURCHASED",
}

export enum ProductType {
  SKU = "SKU",
  DIGITAL_CONTENT = "DIGITAL_CONTENT",
  SINGLE_SKU_WITH_OPTIONS = "  SINGLE_SKU_WITH_OPTIONS",
}

export type Image = {
  src?: string | null;
};

export type Categories = {
  id: string;
  name: string;
};

export type Product = {
  id: string;
  cfCode: string;
  name: string;
  projectId: string;
  type: string;
  brand: string;
  defaultPrice: number;

  minPrice?: number;
  maxPrice?: number;
  minDiscountedPrice?: number;
  maxDiscountedPrice?: number;

  unit: string | null;
  weight: number;
  dimension: string | null;
  outputType: string | null;
  contentType: string | null;
  images: ReadonlyArray<Image>;
  categories: ReadonlyArray<Categories>;
  promotions?: ReadonlyArray<Promotion>;
  productSKUs?: ReadonlyArray<ProductSKU>;
  isCustomSKU?: boolean;
  productCode?: string;
  description?: string;
  properties: ReadonlyArray<ProductProperty>;
  additionalDescription?: string;
};

export interface ProductConnection {
  results: ReadonlyArray<Product>;
  total: number;
}

export type VariableType = { key: string; value: string };

export type ProductImage = { src: string };

export type InputProductTypeKey = {
  id: number | string;
  key: string;
};

export type ProductSKUType = {
  id: number | string;
  value: string;
  key: InputProductTypeKey;
};

export type ProductProperty = {
  id: string;
  productId: string;
  name: string;
  value: string;
  createdAt: string;
  updatedAt: string;
};

export type ProductSKU = {
  id: string;
  productCode: string;
  cfCode: string | null;
  price: number;
  discountedPrice: number;
  isActive: boolean;
  isDeleted: boolean;
  isFree: boolean;
  variableTypes: ReadonlyArray<VariableType>;
  stock: number;
  reservedStock: number;
  productId: string;
  weight: number;
  dimension: ReadonlyArray<number | null> | null;
  createdAt: String;
  updatedAt: String;
  image: string;
  productImages: ReadonlyArray<ProductImage>;
  productName: string;
  options: ReadonlyArray<ProductSKUOption>;
  shippingRules: ReadonlyArray<ShippingRule>;
  digitalContentOptions: DigitalContentOptions | null;
  digitalProductInventories: DigitalProductInventory | null;
  productType: ReadonlyArray<ProductSKUType>;
  promotions?: ReadonlyArray<Promotion>;
};

export interface InitialProductSKU extends ProductSKU {
  amount: number;
}

export type ProductSKUChoice = {
  name: string;
  price: number;
  isActive: boolean;
  isDefault: boolean;
  isSelected: boolean;
};

export type ProductSKUOption = {
  name: string;
  isActive: boolean;
  isMultipleChoices: boolean;
  isRequired: boolean;
  maximum: number;
  choices: ReadonlyArray<ProductSKUChoice>;
};

export type DigitalContentOptions = {
  outputType: ProductOutputType;
  settings: DigitalContentSettings;
};

export type DigitalContentSettings = {
  availability: DigitalContentAvailability;
  content: string | null;
};

export type DigitalContentAvailability = {
  type: DigitalContentAvailabilityType;
  settings: DigitalContentAvailabilitySettings;
};

export type DigitalContentAvailabilitySettings = {
  unit: string;
  value: number;
  availableAt: string | null;
  expiredAt: string | null;
};

export type DigitalProductInventory = {
  status: DigitalProductInventoryStatus;
  inventory: number;
};
