import { FC } from "react";
import { Swiper } from "swiper/react";

import { isMobileDevice } from "utilities/common";

import { Carousel as DefaultCarousel } from "./styled";

export const Carousel: FC<Swiper> = ({ children, spaceBetween, ...carouselProps }) => {
  return (
    <DefaultCarousel
      spaceBetween={spaceBetween || 1}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      preloadImages
      cssMode={isMobileDevice}
      {...carouselProps}
    >
      {children}
    </DefaultCarousel>
  );
};

Carousel.displayName = "Carousel";

export default Carousel;
