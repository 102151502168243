import { FC } from "react";
import { DividerType } from "types";

import { Divider } from "./styled";

type DividerPropTypes = {
  type?: DividerType;
};

const DividerComponent: FC<DividerPropTypes> = ({ type = "dash", ...props }) => <Divider className={type} {...props} />;

DividerComponent.displayName = "Divider";

export default DividerComponent;
