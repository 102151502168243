import { ProductSKU } from "types/product";

export const calculateMinAndMaxPrice = (productSKUs: ReadonlyArray<ProductSKU> = []) => {
  const productSKUPrices = productSKUs.map((productSKU) => productSKU.price);
  if (!productSKUPrices.length) {
    return { minPrice: 0, maxPrice: 0 };
  }
  const minPrice = Math.min(...productSKUPrices);
  const maxPrice = Math.max(...productSKUPrices);
  return { minPrice, maxPrice };
};

export const formatPriceRangeText = (minPrice: number, maxPrice: number) => {
  const minPriceLocaleString = minPrice
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(".00", "");
  const maxPriceLocaleString = maxPrice
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(".00", "");
  return minPriceLocaleString === maxPriceLocaleString
    ? `${minPriceLocaleString}`
    : `${minPriceLocaleString} - ${maxPriceLocaleString}`;
};

export const formatPriceText = (price: number) => {
  return price
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(".00", "");
};
