import { FC } from "react";
import classNames from "classnames";

import { IconSize, SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

const CreditCardJcbIcon: FC<SVGIconPropTypes> = (props) => {
  const { size = IconSize.DEFAULT, style, viewBox = "0 0 24 24", className = "" } = props;
  const iconClass = classNames({ [size]: size, [className]: className });

  return (
    <SVG className={iconClass} style={style} viewBox={viewBox}>
      <g>
        <path
          d="M22.4991 3.75H1.50094C0.671993 3.75 0 4.43589 0 5.28197V19.468C0 20.3141 0.671993 21 1.50094 21H22.4991C23.328 21 24 20.3141 24 19.468V5.28197C24 4.43589 23.328 3.75 22.4991 3.75Z"
          fill="#0D4C96"
        />
        <path
          d="M19.0123 15.8324C19.0103 16.3987 18.7845 16.9412 18.3841 17.3416C17.9837 17.7421 17.4411 17.9679 16.8748 17.9699H5.25732V9.50988C5.25931 8.94428 5.48538 8.40253 5.88603 8.00329C6.28667 7.60405 6.82922 7.37988 7.39482 7.37988H19.0123V15.8324Z"
          fill="white"
        />
        <path
          d="M15.2246 13.6649H16.2221C16.3113 13.6442 16.3906 13.5934 16.4466 13.521C16.5027 13.4485 16.532 13.359 16.5296 13.2674C16.5289 13.1775 16.4983 13.0904 16.4427 13.0198C16.387 12.9492 16.3094 12.8991 16.2221 12.8774H15.2246V13.6799V13.6649Z"
          fill="#40A737"
        />
        <path
          d="M16.0123 8.09277C15.6145 8.09277 15.2329 8.25081 14.9516 8.53211C14.6703 8.81342 14.5123 9.19495 14.5123 9.59277V11.1828H16.8223C17.3098 11.1828 17.6698 11.4603 17.6698 11.8953C17.6678 12.0797 17.5937 12.2559 17.4633 12.3863C17.3329 12.5167 17.1567 12.5908 16.9723 12.5928C17.4673 12.5928 17.8498 12.9003 17.8498 13.3428C17.8498 13.7853 17.4298 14.0928 16.8748 14.0928H14.4673V17.2053H16.7173C17.1151 17.2053 17.4966 17.0472 17.7779 16.7659C18.0592 16.4846 18.2173 16.1031 18.2173 15.7053V8.09277H16.0123Z"
          fill="#40A737"
        />
        <path
          d="M16.4246 12.0372C16.427 11.9486 16.3959 11.8624 16.3375 11.7956C16.2791 11.7289 16.1978 11.6866 16.1096 11.6772H16.0271H15.2246V12.4272H16.1096C16.156 12.4226 16.201 12.4087 16.242 12.3866C16.283 12.3644 16.3192 12.3343 16.3485 12.298C16.3778 12.2617 16.3996 12.22 16.4127 12.1752C16.4258 12.1305 16.4298 12.0836 16.4246 12.0372Z"
          fill="#40A737"
        />
        <path
          d="M7.55242 8.09277C7.1546 8.09277 6.77307 8.25081 6.49176 8.53211C6.21046 8.81342 6.05242 9.19495 6.05242 9.59277V13.3428C6.46009 13.5576 6.91176 13.6757 7.37242 13.6878C7.47644 13.6972 7.58127 13.6847 7.6802 13.6513C7.77914 13.6178 7.86999 13.5641 7.94695 13.4935C8.02391 13.4229 8.08527 13.337 8.12711 13.2413C8.16895 13.1456 8.19035 13.0422 8.18992 12.9378V11.2053H9.49492V12.9753C9.49492 13.6653 9.06742 14.2278 7.61242 14.2278C7.08432 14.2279 6.55809 14.165 6.04492 14.0403V17.2578H8.29492C8.69275 17.2578 9.07428 17.0997 9.35558 16.8184C9.63689 16.5371 9.79492 16.1556 9.79492 15.7578V8.09277H7.55242Z"
          fill="#004E94"
        />
        <path
          d="M11.7824 8.09277C11.3846 8.09277 11.0031 8.25081 10.7218 8.53211C10.4405 8.81342 10.2824 9.19495 10.2824 9.59277V11.6103C10.9099 11.182 11.6765 11.0078 12.4274 11.1228C12.832 11.1466 13.2337 11.2069 13.6274 11.3028V11.9553C13.2608 11.7524 12.8533 11.6345 12.4349 11.6103C11.6099 11.5503 11.1149 11.9553 11.1149 12.6603C11.1149 13.3653 11.6099 13.7778 12.4349 13.7103C12.8418 13.6803 13.2378 13.5653 13.5974 13.3728V14.0253C13.2038 14.1218 12.8021 14.182 12.3974 14.2053C11.6465 14.3202 10.8799 14.146 10.2524 13.7178V17.2503H12.5024C12.9003 17.2503 13.2818 17.0922 13.5631 16.8109C13.8444 16.5296 14.0024 16.1481 14.0024 15.7503V8.09277H11.7824Z"
          fill="#BE1833"
        />
      </g>
    </SVG>
  );
};

export default CreditCardJcbIcon;
