import { FC } from "react";

import { useTranslation } from "react-i18next";

import Badge from "components/Badge";
import Typography from "components/Typography";

import { formatPriceRangeText } from "utilities/product/calculateMinAndMaxPrice";

import { Product } from "types/product";
import { Promotion } from "types/promotion";

import {
  ImageDisabled,
  ImageWrapper,
  ProductImage,
  ProductDetailWrapper,
  TextEllipsis,
  PromotionContainer,
  ProductPrice,
  PricePrefix,
} from "./styled";

import { NO_IMAGE } from "constants/image";

type ProductCardPropsType = {
  product: Product;
  isOutOfStock?: boolean;
};

const ProductCard: FC<ProductCardPropsType> = (props) => {
  const { t } = useTranslation();
  const { product, isOutOfStock = false } = props;
  const { name, images, minPrice = 0, maxPrice = 0, minDiscountedPrice = 0, maxDiscountedPrice = 0 } = product;
  const productImage = images?.[0]?.src || NO_IMAGE;

  const isShowDiscountPrice = minPrice !== minDiscountedPrice;
  const discountPrice = formatPriceRangeText(minDiscountedPrice, maxDiscountedPrice);
  const price = formatPriceRangeText(minPrice, maxPrice);
  return (
    <>
      <ImageWrapper>
        <ProductImage src={productImage} />
        <PromotionContainer>
          {(product?.promotions || []).slice(0, 1).map(({ shortName, id }: Promotion) => {
            return (
              <Badge key={id} size="M">
                {shortName}
              </Badge>
            );
          })}
        </PromotionContainer>
        {isOutOfStock && <ImageDisabled />}
      </ImageWrapper>

      <ProductDetailWrapper>
        <Typography variant="dark-title-5">
          <TextEllipsis>{name}</TextEllipsis>
        </Typography>
        {isShowDiscountPrice && !isOutOfStock ? (
          <>
            <Typography variant="price-title-6">
              <ProductPrice>
                <PricePrefix className="price-title-8" lineHeight="18px">
                  ฿
                </PricePrefix>
                {discountPrice}
              </ProductPrice>
            </Typography>
            <Typography variant="price-title-9">
              <ProductPrice strikeThrough>
                <PricePrefix className="price-title-11" lineHeight="12px">
                  ฿
                </PricePrefix>
                {price}
              </ProductPrice>
            </Typography>
          </>
        ) : (
          <Typography variant="price-title-6">
            {isOutOfStock ? (
              <Typography variant="accent-body-1">{t("common.outOfStock")}</Typography>
            ) : (
              <ProductPrice>
                <PricePrefix className="price-title-8" lineHeight="18px">
                  ฿
                </PricePrefix>
                {price}
              </ProductPrice>
            )}
          </Typography>
        )}
      </ProductDetailWrapper>
    </>
  );
};
export default ProductCard;
