import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const ReloadIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 41 40", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} viewBox={viewBox}>
      <rect x="0.5" width="40" height="40" rx="20" fill="#EEEEEE" />
      <path
        d="M29.5 19C29.2348 19 28.9804 19.1054 28.7929 19.2929C28.6054 19.4804 28.5 19.7348 28.5 20C28.5119 21.873 27.8703 23.6916 26.6857 25.1425C25.501 26.5933 23.8475 27.5857 22.01 27.9486C20.1724 28.3115 18.2658 28.0223 16.6185 27.1308C14.9712 26.2392 13.6864 24.8012 12.9853 23.0643C12.2842 21.3273 12.2108 19.4003 12.7776 17.6151C13.3444 15.8298 14.5161 14.2981 16.0907 13.2838C17.6653 12.2694 19.5444 11.8359 21.4043 12.0579C23.2641 12.2799 24.9884 13.1435 26.28 14.5H23.88C23.6148 14.5 23.3604 14.6054 23.1729 14.7929C22.9854 14.9804 22.88 15.2348 22.88 15.5C22.88 15.7652 22.9854 16.0196 23.1729 16.2071C23.3604 16.3946 23.6148 16.5 23.88 16.5H28.41C28.6752 16.5 28.9296 16.3946 29.1171 16.2071C29.3046 16.0196 29.41 15.7652 29.41 15.5V11C29.41 10.7348 29.3046 10.4804 29.1171 10.2929C28.9296 10.1054 28.6752 10 28.41 10C28.1448 10 27.8904 10.1054 27.7029 10.2929C27.5154 10.4804 27.41 10.7348 27.41 11V12.77C25.7447 11.1781 23.5808 10.21 21.284 10.0293C18.9873 9.84871 16.6986 10.4666 14.8049 11.7787C12.9112 13.0907 11.5286 15.0164 10.8908 17.2302C10.2531 19.444 10.3992 21.8101 11.3046 23.9285C12.21 26.047 13.8191 27.7878 15.8599 28.8568C17.9007 29.9258 20.248 30.2574 22.505 29.7955C24.7621 29.3336 26.7904 28.1065 28.2471 26.3217C29.7039 24.5369 30.4997 22.3038 30.5 20C30.5 19.7348 30.3946 19.4804 30.2071 19.2929C30.0196 19.1054 29.7652 19 29.5 19Z"
        fill={color}
      />
    </SVG>
  );
};

export default ReloadIcon;
