import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const SelectedIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 32 32", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM22.6382 11.4208C22.1558 10.9384 21.3736 10.9384 20.8912 11.4208L14.3529 17.9591L11.1088 14.7149C10.6264 14.2325 9.84422 14.2325 9.36181 14.7149C8.8794 15.1973 8.8794 15.9795 9.36181 16.4619L13.4795 20.5795C13.9619 21.0619 14.744 21.0619 15.2264 20.5795L22.6382 13.1678C23.1206 12.6854 23.1206 11.9032 22.6382 11.4208Z"
        fill={color}
      />
    </SVG>
  );
};

export default SelectedIcon;
