import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const EmptyCartIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 100 100", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path className="cls-1" d="M71.32,94.26a5.79,5.79,0,1,0,5.78-5.74A5.76,5.76,0,0,0,71.32,94.26Z" />
      <path
        className="cls-1"
        d="M92,82.64h-62a5.88,5.88,0,1,1,0-11.75H88.38l5.4-21.67A26.46,26.46,0,0,1,87,52.08L83.76,65H71.69l1.6-13a26.42,26.42,0,0,1-5.67-2.26L65.74,65H53.22L51,47.2H63.65a26.57,26.57,0,0,1-5.58-5.87h-7.8L48.06,23.7h5.81a26.39,26.39,0,0,1,1.34-5.88H23.38L19,0H3A2.94,2.94,0,0,0,0,2.94H0A3,3,0,0,0,3,5.88H14.33L29.13,65.1a11.72,11.72,0,0,0,.81,23.42h12a5.74,5.74,0,1,0,5.79,5.74,5.77,5.77,0,0,0-5.79-5.74H92a2.94,2.94,0,1,0,0-5.88ZM47.27,65H35.2L30.77,47.2H45.06ZM24.86,23.7H42.1l2.22,17.63h-15Z"
      />
      <path
        className="cls-1"
        d="M80,44.65A19.84,19.84,0,1,0,80,5h0A19.91,19.91,0,0,0,60,24.8v0A19.9,19.9,0,0,0,80,44.65ZM78.71,13.39H81.4A1.49,1.49,0,0,1,82.79,15L82,26.48a1.5,1.5,0,0,1-1.49,1.38h-1A1.49,1.49,0,0,1,78,26.48L77.23,15a.38.38,0,0,1,0-.1A1.49,1.49,0,0,1,78.71,13.39ZM80,30.63a2.8,2.8,0,1,1-2.82,2.8A2.81,2.81,0,0,1,80,30.63Z"
      />
    </SVG>
  );
};

export default EmptyCartIcon;
