import { useState, useEffect, FC, ReactElement } from "react";
import httpStatus from "http-status";
import { IS_DEV } from "config";
import ErrorPage from "containers/error";

import SuccessPayment from "containers/payment/SuccessPayment";

import messengerService from "services/MessengerService";

import { getIsPaymentSuccessPage } from "utilities/page";

const DELAY_TIMEOUT = 15000;

let timeCount: ReturnType<typeof setTimeout>;

const ChatProvider: FC<{ children: ReactElement }> = ({ children }): ReactElement | null => {
  const [isInitService, setIsInitService] = useState(false);
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const isSuccessPaymentPage = getIsPaymentSuccessPage(window.location.pathname);
  const [isBrowserNotSupported, setIsBrowserNotSupported] = useState(false);

  const _onMessengerSDKLoaded = async () => {
    const isMetaClient = await messengerService.isMetaClient();
    if (!isMetaClient) {
      setIsBrowserNotSupported(true);
    }

    clearTimeout(timeCount);
    setIsInitService(true);
  };

  useEffect(() => {
    const _initializeMessengerSDK = () => {
      timeCount = setTimeout(() => {
        setIsTimeout(true);
      }, DELAY_TIMEOUT); // waiting for 15sec

      ((d, s, id) => {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
        if (fjs?.parentNode) {
          fjs.parentNode.insertBefore(js, fjs);
        }
      })(document, "script", "Messenger");
      window.extAsyncInit = _onMessengerSDKLoaded;
    };

    const init = async () => {
      const isLineClient = await messengerService.isLineClient();
      if (isLineClient) {
        await messengerService.init();

        setIsInitService(true);
      } else {
        _initializeMessengerSDK();
      }
    };

    init();
  }, []);

  // This a bit hacky way to bypass public route
  if (isSuccessPaymentPage) return <SuccessPayment />;

  if (isBrowserNotSupported && !IS_DEV) {
    return <ErrorPage error={httpStatus.BAD_REQUEST} />;
  }

  if (isTimeout) {
    return <ErrorPage />;
  }

  if (!isInitService) {
    return null;
  }

  return children;
};

export default ChatProvider;
