import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const RemoveIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <g id="Remove">
        <path
          id="Union_3"
          data-name="Union 3"
          className="cls-1"
          d="M15.23,48A11.92,11.92,0,0,1,3,36.46v-23h-.5A2.39,2.39,0,0,1,0,11.21,2.38,2.38,0,0,1,2.46,8.9H14a1.43,1.43,0,0,1-.09-.41A8.43,8.43,0,0,1,22.27,0H25a9.12,9.12,0,0,1,9,8.9H45.54A2.38,2.38,0,0,1,48,11.21a2.39,2.39,0,0,1-2.46,2.3h-.73v23A11.93,11.93,0,0,1,32.53,48ZM7.87,36.46a7.15,7.15,0,0,0,7.36,6.92h17.3a7.18,7.18,0,0,0,7.37-6.92v-23h-32Zm11-28a2.71,2.71,0,0,1-.09.41h10.4A4.34,4.34,0,0,0,25,4.62H22.26A3.68,3.68,0,0,0,18.83,8.49Zm8.73,26V21.3a2.46,2.46,0,0,1,4.92,0V34.5a2.47,2.47,0,0,1-4.92,0Zm-12.28,0V21.3a2.46,2.46,0,0,1,4.92,0V34.5a2.47,2.47,0,0,1-4.92,0Z"
          transform="translate(0)"
        />
      </g>
    </SVG>
  );
};

export default RemoveIcon;
