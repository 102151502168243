import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import get from "lodash/get";

import { ORDER_SUMMARY } from "graphql/order/query";
import { GENERATE_CREDIT_CARD } from "graphql/payment/mutation";

const useDebitCreditCard = (orderId: string) => {
  const [isReadyToCharge, setIsReadyToCharge] = useState(false);
  const { data, loading: loadingOrder } = useQuery(ORDER_SUMMARY, { variables: { orderId } });
  const [generateCreditCardCharge, { data: creditCardChargeData }] = useMutation(GENERATE_CREDIT_CARD, {
    onError: () => console.log("error"),
    onCompleted: () => {
      setIsReadyToCharge(true);
    },
  });

  return {
    order: get(data, "order"),
    loadingOrder,
    generateCreditCardCharge,
    creditCardChargeData,
    isReadyToCharge,
  };
};

export default useDebitCreditCard;
