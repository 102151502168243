import { Grid, Checkbox, Radio, Box, FormControlLabel } from "@material-ui/core";
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import Typography from "components/Typography";

import { ProductSKUOption } from "types/product";

import { OptionsWrapper } from "./styled";

type FoodProductOptionsSelectorProps = {
  productSKUOptions: ProductSKUOption[];
  onSelectOption: (index: number, choiceIndex: number, checked: boolean, isMultipleChoices?: boolean) => void;
};

const FoodProductOptionsSelector: FC<FoodProductOptionsSelectorProps> = ({ productSKUOptions, onSelectOption }) => {
  const { t } = useTranslation();

  return (
    <div>
      {productSKUOptions.map((option, index) => {
        const { name, choices, maximum, isRequired, isMultipleChoices, isActive } = option;

        if (!isActive) {
          return null;
        }

        const maximumSelected = choices.length < maximum ? choices.length : maximum;
        const countSelectedChoice = choices.reduce((sum, choice) => {
          return choice.isSelected ? sum + 1 : sum;
        }, 0);
        const isReachLimit = isMultipleChoices && countSelectedChoice === maximumSelected;
        const maximumSelectedPrefix = isRequired
          ? t("product.productSKUsSelector.select")
          : t("product.productSKUsSelector.maximumSelected");

        return (
          <Box key={name} py={1}>
            <Box display="flex" pb={2} px={2} justifyContent="space-between" alignItems="center" id={name}>
              <Typography variant="gray-body-1">{`${name} : `}</Typography>
              <Typography variant="gray-body-1">{`${maximumSelectedPrefix} ${maximumSelected}`}</Typography>
            </Box>

            <OptionsWrapper>
              {choices.map((choice, choiceIndex) => {
                const { name: choiceName, price, isSelected, isActive: isActiveChoice } = choice;

                const choicePriceText = `${price < 0 ? "-" : "+"}฿${Math.abs(price)}`;

                return (
                  isActiveChoice && (
                    <Grid key={choiceName} container justify="space-between" alignItems="center">
                      <Grid item xs={9}>
                        {isMultipleChoices ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isSelected}
                                disabled={isReachLimit}
                                onClick={(event) => {
                                  onSelectOption(
                                    index,
                                    choiceIndex,
                                    (event.target as HTMLInputElement).checked,
                                    isMultipleChoices,
                                  );
                                }}
                              />
                            }
                            label={choiceName}
                          />
                        ) : (
                          <FormControlLabel
                            control={
                              <Radio
                                checked={isSelected}
                                disabled={isReachLimit}
                                onClick={(event) => {
                                  onSelectOption(
                                    index,
                                    choiceIndex,
                                    (event.target as HTMLInputElement).checked,
                                    isMultipleChoices,
                                  );
                                }}
                              />
                            }
                            label={choiceName}
                          />
                        )}
                      </Grid>
                      <Grid item xs={3} container alignContent="flex-end" justify="flex-end">
                        {choicePriceText}
                      </Grid>
                    </Grid>
                  )
                );
              })}
            </OptionsWrapper>
          </Box>
        );
      })}
    </div>
  );
};

export default memo(FoodProductOptionsSelector);
