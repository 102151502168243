import { Grid } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper } from "containers/error/styled";

import Typography from "components/Typography";

import { SupportMobileWrapper } from "./styled";

import { SUPPORT_MOBILE_DEVICE, DEEPLE_PAY } from "constants/image";

const SupportMobileDevice: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper container spacing={2} justify="center" alignItems="center">
      <Grid item xs={12}>
        <SupportMobileWrapper className="pb-5">
          <img className="logo" src={DEEPLE_PAY} alt="deeple-pay-logo" />
        </SupportMobileWrapper>
        <SupportMobileWrapper className="pt-3">
          <img className="banner" src={SUPPORT_MOBILE_DEVICE} alt="support-mobile" />
        </SupportMobileWrapper>
        <div className="pt-5 px-3">
          <Typography variant="dark-title-1">{t("payment.mobileBanking.error.notSupportedDesktop.title")}</Typography>
          <Typography className="pt-3" variant="dark-body-2">
            {t("payment.mobileBanking.error.notSupportedDesktop.description")}
          </Typography>
        </div>
      </Grid>
    </Wrapper>
  );
};

export default SupportMobileDevice;
