export enum OrderState {
  ABANDONED = "ABANDONED",
  CANCELLED = "CANCELLED",
  COD_PICKED_UP = "COD_PICKED_UP",
  COD_PREPARE_TO_SHIP = "COD_PREPARE_TO_SHIP",
  COD_REJECTED = "COD_REJECTED",
  COMPLETED = "COMPLETED",
  ORDER_PLACED = "ORDER_PLACED",
  PENDING_FOR_SHIPPING_COST = "PENDING_FOR_SHIPPING_COST",
  PREPARE_TO_SHIPPING = "PREPARE_TO_SHIPPING",
  SHIPPED = "SHIPPED",
  REFUNDED = "REFUNDED",
  VOIDED = "VOIDED",
  WAIT_FOR_PAYMENT_APPROVE = "WAIT_FOR_PAYMENT_APPROVE",
}

export enum GeneratePaymentStatus {
  SUCCESS = "SUCCESS",
  DEEPLE_PAY_ORDER_NOT_FOUND = "DEEPLE_PAY_ORDER_NOT_FOUND",
  DEEPLE_PAY_ORDER_STATE_NOT_ABLE_TO_GENERATE_PAYMENT = "DEEPLE_PAY_ORDER_STATE_NOT_ABLE_TO_GENERATE_PAYMENT",
}

export interface OrderSchema {
  id: string;
  projectId: string;
  shippingMethod: string;
  generatePaymentAmount: number;
  generatePaymentStatus: GeneratePaymentStatus;

  // Todo: remove comment when use this key
  // state: OrderState;
  // customerId: string;
  // shippingPrice: number;
  // shippingDuration: number;
  // receiverName: string;
  // phoneNumber: string;
  // note: string;
  // email: string;
  // trackingNumber: string;
  // paymentBillImageSrc: string;
  // paymentBankName: string;
  // paymentAt: string;
  // paymentAmount: number;
  // shippedAt: string;
  // firstName: string;
  // lastName: string;
  // streetAddress: string;
  // streetAddress2: string;
  // district: string;
  // subDistrict: string;
  // province: string;
  // postalCode: string;
  // orderNumber: string;
  // cancelReason: string;
  // cancelBy: string;
  // cancelDate: string;
  // vatOptions: string;
  // vatValue: number;
  // totalPrice: number;
  // vatPrice: number;
  // grandTotal: number;
  // discountTotalPrice: number;
  // discountShippingPrice: number;
  // paymentMethodType: string;
  // depositAmount: number;
  // remark: string;
  // depositBillImageSrc: string;
  // depositBankName: string;
  // depositedAt: string;
  // deliverySchedule: string;
  // shippingPriceNote: string;
  // totalWeight: number;
  // referenceCode: string;
  // adminsNote: string;
  // agent: string;
  // shippingCalculationType: string;
  // shippingDescription: string;
  // platform: string;
  // openedBy: string;
  // secondaryPayslipImages: string;
  // additionalDiscount: string;
  // shippingRules: string;
  // merchantId: string;
  // managementFee: string;
  // voidReason: string;
  // voidedBy: string;
  // voidedAt: string;
  // isShippingIncludedInVat: boolean;
  // largestDimension: number;
  // codInformation: JSON;
  // generatePaymentAmount: number;
}
