import { FC } from "react";

import { LoadingWrapper } from "./styled";

import { DEEPLE_PAY_LOADING } from "constants/image";

const DeeplePayLoading: FC = () => (
  <LoadingWrapper>
    <img src={DEEPLE_PAY_LOADING} alt="deeple-pay-loading" />
  </LoadingWrapper>
);

export default DeeplePayLoading;
