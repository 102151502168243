import { FC, useState } from "react";
import { Swiper } from "swiper";
import { SwiperSlide } from "swiper/react";

import { Carousel, TextWrapper, PageText, Wrapper, Image } from "./styled";
import ImagePopup from "./ImagePopup";

import { NO_IMAGE } from "constants/image";

export type Item = {
  url?: string;
  title?: string;
};

type ImageCarouselPropTypes = {
  items?: Item[];
  isShowPage?: boolean;
  onSwiper?: (swiper: Swiper) => void;
};

const ImageCarousel: FC<ImageCarouselPropTypes> = ({ items = [], onSwiper }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const hasItem = items?.length;

  const handleClickImage = (value: number) => {
    setPage(value);
    setIsOpen(true);
  };

  return (
    <>
      <ImagePopup page={page} setPage={setPage} items={items} isOpen={isOpen} handleClose={() => setIsOpen(false)} />
      <Wrapper>
        {hasItem ? (
          <Carousel onSwiper={onSwiper}>
            {items.map(({ url, title }, i) => {
              const page = i + 1;
              return (
                <SwiperSlide key={title}>
                  <Image src={url} alt={title} onClick={() => handleClickImage(i)} />
                  <TextWrapper>
                    <PageText>{`${page} / ${items.length}`}</PageText>
                  </TextWrapper>
                </SwiperSlide>
              );
            })}
          </Carousel>
        ) : (
          <Image src={NO_IMAGE} alt="no-carousel-image" />
        )}
      </Wrapper>
    </>
  );
};

ImageCarousel.displayName = "ImageCarousel";

export default ImageCarousel;
