import { VariableType, Product } from "types/product";
import { ShippingMethod } from "types/shipping";

export type Promotion = {
  id: string;
  createdAt: string;
  updatedAt: string;
  projectId: string;
  campaignId: string;
  name: string;
  description: string;
  shortName: string;
  type: string;
  setting: PromotionSetting;
  isActive: boolean;
  isDeleted: boolean;
  priority: number;
  freeProducts: FreeProduct[];
  selectedProducts: SelectedProduct[];
  startDate: string;
  endDate: string;
  isIncludedAllProducts: boolean;
};

export type CartPromotion = Promotion & { totalDiscount: number };

type PromotionAmountThreshold = {
  amount: number;
};

type PromotionPriceThreshold = {
  price: number;
};

type PromotionThreshold = PromotionAmountThreshold | PromotionPriceThreshold;

type PromotionAmountDiscount = {
  amount: number;
};

type PromotionFixedDiscount = {
  fixed: number;
};

type PromotionPercentageDiscount = {
  percent: number;
};

type PromotionDiscount = PromotionAmountDiscount | PromotionFixedDiscount | PromotionPercentageDiscount;

type PromotionSetting = {
  awardFromLowToHigh: boolean;
  type: PromotionSettingType;
  condition: PromotionSettingCondition;
  criteria?: ReadonlyArray<PromotionCriteria>;
  awardTotalPrice: boolean;
  isBundled: boolean;
  threshold: PromotionThreshold;
  discount: PromotionDiscount;
  shippingMethod: ShippingMethod;
};

enum PromotionSettingType {
  BUY_X_GET_Y = "BUY_X_GET_Y",
  FIXED_AMOUNT_DISCOUNT = "FIXED_AMOUNT_DISCOUNT",
  FIXED_PRICE_DISCOUNT = "FIXED_PRICE_DISCOUNT",
  FREE_GIVEAWAY = "FREE_GIVEAWAY",
  FREE_LOWEST_PRICE = "FREE_LOWEST_PRICE",
  FREE_SHIPPING = "FREE_SHIPPING",
  PERCENTAGE_DISCOUNT = "PERCENTAGE_DISCOUNT",
}

enum PromotionSettingCondition {
  BUY_ANY_PRODUCT = "BUY_ANY_PRODUCT",
  BUY_ANY_SELECTED_PRODUCT = "BUY_ANY_SELECTED_PRODUCT",
  BUY_X_PIECE_OF_ANY_PRODUCT = "BUY_X_PIECE_OF_ANY_PRODUCT",
  BUY_X_PIECE_OF_SELECTED_PRODUCT = "BUY_X_PIECE_OF_SELECTED_PRODUCT",
  BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE = "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE",
  BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT = "BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT",
  BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT = "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT",
  BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE = "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE",
}

enum PromotionOperator {
  AT_LEAST = "AT_LEAST",
  BETWEEN = "BETWEEN",
  EQUAL = "EQUAL",
}

type PromotionCriteria = {
  operator?: PromotionOperator;
  discount?: PromotionDiscount;
  thresholds: PromotionThreshold[];
  freeProducts?: FreeProduct[];
};

export type SelectedProduct = {
  id: string;
  createdAt: string;
  updatedAt: string;
  projectId: string;
  productId: string;
  productCode: string;
  isActive: boolean;
  isDeleted: boolean;
  isFree: boolean;
  image: string;
  price: number;
  variableTypes: VariableType[];
  weight: string;
  cfCode: string;
  stock: string;
  reservedStock: string;

  // width, height, depth
  dimension: number[];
  promotions: Promotion[];
  product: Product;
};

export type FreeProduct = {
  id: string;
  createdAt: string;
  updatedAt: string;
  projectId: string;
  productId: string;
  productCode: string;
  isActive: boolean;
  isDeleted: boolean;
  isFree: boolean;
  image: string;
  price: number;
  variableTypes: VariableType[];
  weight: string;
  cfCode: string;
  stock: string;
  reservedStock: string;

  // width, height, depth
  dimension: number[];
  promotions: Promotion[];
  amount: string;
  product: Product;
};
