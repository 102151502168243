import { FC, StrictMode } from "react";
import { ApolloProvider } from "@apollo/client";
import { I18nextProvider } from "react-i18next";
import i18n from "translations/i18n";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { ProjectContextProvider } from "context/project";

import ThemeProvider from "context/theme";

import GlobalStyle from "styles/globalStyle";

import apolloClient from "utilities/apollo";
import ChatProvider from "utilities/messenger/ChatProvider";

const ContextProvider: FC = ({ children }) => {
  return (
    <StrictMode>
      <GlobalStyle />

      <ChatProvider>
        <ApolloProvider client={apolloClient.getApolloClient()}>
          <ProjectContextProvider>
            <ThemeProvider>
              <I18nextProvider i18n={i18n}>
                <HelmetProvider>
                  <BrowserRouter>{children}</BrowserRouter>
                </HelmetProvider>
              </I18nextProvider>
            </ThemeProvider>
          </ProjectContextProvider>
        </ApolloProvider>
      </ChatProvider>
    </StrictMode>
  );
};

export default ContextProvider;
