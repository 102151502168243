import { useMutation } from "@apollo/client";

import get from "lodash/get";

import { GENERATE_MOBILE_BANKING } from "graphql/payment/mutation";

import { MobileBankingBank, MobileBankingSchema } from "types/payment";

import { PUBLIC_PAGES } from "constants/page";

const useMobileBanking = (orderId: string) => {
  const [generateMobileBanking, { loading: loadingGenerateMobileBanking }] = useMutation(GENERATE_MOBILE_BANKING, {
    onError: () => window.location.replace(PUBLIC_PAGES.FailedPayment),
  });

  const generateBankDetail = async (selectedBankingApp: MobileBankingBank) => {
    const data = await generateMobileBanking({
      variables: { bank: selectedBankingApp, orderId },
    });
    const bankingData: MobileBankingSchema = await get(data, "data.generateMobileBanking");

    return bankingData;
  };

  return {
    generateBankDetail,
    loadingGenerateMobileBanking,
  };
};

export default useMobileBanking;
