import { FC, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@material-ui/core/Grid";

import CreditCardCVVField from "components/Input/CreditCardCVVField";
import CreditCardExpiredDateField from "components/Input/CreditCardExpireField";
import CreditCardField from "components/Input/CreditCardField";
import Footer from "components/Footer";
import Icon from "components/Icon";
import TextField from "components/Input/TextField";
import Typography from "components/Typography";

import { getCreditCardInformation } from "utilities/credit-card";

import { IconName, IconSize } from "types/icon";

import schema from "./schema";
import { CreditCardIconsWrapper, BackButton, FooterButton } from "./style";

export interface CreditCardDataType {
  cardNumber: string;
  validDate: string;
  cvv: string;
  cardHolderName: string;
  cardType: string;
}

export interface creditCardFormPropsType {
  onSubmit: (value: Record<string, string>) => void;
  onClickBack: () => void;
  submitText: string;
}

export const CreditCardForm: FC<creditCardFormPropsType> = (props) => {
  const { onSubmit, onClickBack, submitText } = props;
  const { t } = useTranslation();
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<CreditCardDataType>({
    resolver: yupResolver(schema),
  });

  const watchCreditCardNumber = watch("cardNumber", "");
  const watchCardType = watch("cardType", "");

  useEffect(() => {
    const { creditCardType } = getCreditCardInformation(watchCreditCardNumber);
    setValue("cardType", creditCardType);
  }, [watchCreditCardNumber, setValue]);

  const onSubmitHandler = handleSubmit((value) => {
    onSubmit(value);
  });

  const handleClickBack = () => {
    onClickBack();
  };

  return (
    <Grid container className="p-4">
      <input {...register("cardType")} style={{ display: "none" }} />

      <Grid item container justify="space-between" alignItems="center" className="pt-2">
        <Typography variant="gray-body-2">{t("addCreditCard.form.label.cardNumber")}</Typography>
        <CreditCardIconsWrapper creditCardType={watchCardType} item>
          <Icon className="ml-1 credit-card-visa-type" name={IconName.CREDIT_CARD_VISA} size={IconSize.MEDIUM} />
          <Icon className="ml-1 credit-card-master-type" name={IconName.CREDIT_CARD_MASTER} size={IconSize.MEDIUM} />
          <Icon className="ml-1 credit-card-jcb-type" name={IconName.CREDIT_CARD_JCB} size={IconSize.MEDIUM} />
        </CreditCardIconsWrapper>
      </Grid>
      <Grid item xs={12} className="pt-2">
        <Controller
          name="cardNumber"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value, name } }) => {
            return (
              <CreditCardField
                onChange={onChange}
                value={value}
                name={name}
                error={Boolean(errors.cardNumber)}
                helperText={t(errors?.cardNumber?.message || "")}
                fullWidth
                placeholder={t("XXXX XXXX XXXX XXXX")}
              />
            );
          }}
        />
      </Grid>
      <Grid item container className="pt-3">
        <Grid item xs={6} className="pr-2">
          <Typography variant="gray-body-2" className="pb-2">
            {t("addCreditCard.form.label.validDate")}
          </Typography>
          <Controller
            name="validDate"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => {
              return (
                <CreditCardExpiredDateField
                  onChange={onChange}
                  value={value}
                  name={name}
                  fullWidth
                  placeholder={t("MM / YY")}
                  error={Boolean(errors.validDate)}
                  helperText={t(errors?.validDate?.message || "")}
                  tooltipText={t("addCreditCard.form.tooltip.validDate")}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6} className="pl-2">
          <Typography variant="gray-body-2" className="pb-2">
            {t("addCreditCard.form.label.cvv")}
          </Typography>

          <Controller
            name="cvv"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => {
              return (
                <CreditCardCVVField
                  onChange={onChange}
                  value={value}
                  name={name}
                  error={Boolean(errors.cvv)}
                  helperText={t(errors?.cvv?.message || "")}
                  tooltipText={t("addCreditCard.form.tooltip.cvv")}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} className="pt-4">
          <Typography variant="gray-body-2" className="pb-2">
            {t("addCreditCard.form.label.cardHolderName")}
          </Typography>
          <Controller
            name="cardHolderName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => {
              return (
                <TextField
                  onChange={onChange}
                  value={value}
                  name={name}
                  defaultValue=""
                  fullWidth
                  isOutlined
                  placeholder={t("addCreditCard.form.placeHolder.cardHolderName")}
                  error={Boolean(errors.cardHolderName)}
                  helperText={t(errors?.cardHolderName?.message || "")}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      <Footer>
        <BackButton handleClick={handleClickBack} data-testid="back-button-testid">
          <Icon name={IconName.BACK_ARROW} size={IconSize.MEDIUM} color="white" />
        </BackButton>
        <FooterButton handleClick={onSubmitHandler} disabled={false} data-testid="submit-button-testid">
          <Typography variant="white-title-2">{submitText}</Typography>
        </FooterButton>
      </Footer>
    </Grid>
  );
};

export default CreditCardForm;
