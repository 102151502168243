import styled, { FlattenSimpleInterpolation } from "styled-components";

export const SVG = styled.svg<{ defaultStyle?: FlattenSimpleInterpolation }>`
  display: inline-block;
  background-color: transparent;
  fill: ${({ color }) => color};
  margin: auto 0;

  &.extra-small {
    width: 10px;
    height: 10px;
  }

  &.small {
    width: 12px;
    height: 12px;
  }

  &.default {
    width: 16px;
    height: 16px;
  }

  &.medium {
    width: 20px;
    height: 20px;
  }

  &.large {
    width: 30px;
    height: 30px;
  }

  &.extra-large {
    width: 100px;
    height: 100px;
  }

  ${({ defaultStyle }) => defaultStyle}
`;

export const IconWrapper = styled.span`
  display: flex;
`;
