import { FC } from "react";

import qs from "qs";

import useIOSRender from "hooks/useIOSRender";

import { Language } from "types/language";

import Router from "./Router";

import ContextProvider from "./ContextProvider";
import { setLanguage } from "./utilities/common/language";

const App: FC = () => {
  const queryString = decodeURIComponent(window.location.search);
  const { lang } = qs.parse(queryString.replace("?", ""));
  const defaultLanguage = typeof lang === "string" ? lang.toLowerCase() : "th";
  setLanguage(defaultLanguage as Language);

  useIOSRender();

  return (
    <ContextProvider>
      <Router />
    </ContextProvider>
  );
};

export default App;
