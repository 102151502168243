export enum ButtonType {
  CONTAINED = "contained",
  OUTLINED = "outlined",
}

export enum ButtonColor {
  PRIMARY = "Primary",
  DISABLED = "Disabled",
  ERROR = "Accent",
}
