import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const MinusIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 32 32", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        fill={color}
        id="Rectangle_240"
        data-name="Rectangle 240"
        className="cls-2"
        d="M3.33,13H28.67A3.18,3.18,0,0,1,32,16h0a3.18,3.18,0,0,1-3.33,3H3.33A3.18,3.18,0,0,1,0,16H0A3.18,3.18,0,0,1,3.33,13Z"
      />
    </SVG>
  );
};

export default MinusIcon;
