import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const DownloadIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 19 19", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.12044 3.37959V11.2545L7.82151 9.9556C7.48036 9.61445 6.92172 9.61445 6.58057 9.9556C6.23616 10.3 6.23616 10.8554 6.58057 11.1998L9.37354 13.9926C9.53796 14.1636 9.76317 14.2579 10 14.2579C10.2312 14.2579 10.4569 14.1624 10.6205 13.9988L13.4195 11.1998C13.7639 10.8554 13.7639 10.3 13.4253 9.96152C13.2588 9.78863 13.0345 9.69648 12.799 9.69648C12.5694 9.69648 12.3441 9.79003 12.1786 9.9556L10.8796 11.2545V3.37959C10.8796 2.89237 10.4873 2.5 10 2.5C9.51281 2.5 9.12044 2.89237 9.12044 3.37959ZM16.3658 10.3248C16.3658 9.83759 16.7582 9.44523 17.2454 9.44523C17.732 9.44523 18.125 9.84023 18.125 10.3248V14.3308C18.125 16.1468 16.6485 17.6245 14.8314 17.6245H5.16864C3.35264 17.6245 1.875 16.148 1.875 14.3308V10.2596C1.875 9.77235 2.26737 9.37998 2.75459 9.37998C3.24181 9.37998 3.63418 9.77235 3.63418 10.2596V14.3308C3.63418 15.1758 4.32285 15.8653 5.16864 15.8653H14.8314C15.6764 15.8653 16.3658 15.1766 16.3658 14.3308V10.3248Z"
          fill="url(#paint0_linear_2181:8349)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2181:8349"
            x1="1.875"
            y1="17.6245"
            x2="18.125"
            y2="17.6245"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EC008C" />
            <stop stopColor="#EC008C" />
            <stop stopColor="#EC008C" />
            <stop offset="1" stopColor="#FF4E00" />
            <stop offset="1" stopColor="#FF4E00" />
          </linearGradient>
        </defs>
      </svg>
    </SVG>
  );
};

export default DownloadIcon;
