import ContentLoader from "react-content-loader";

interface ProductCardLoadingPropsType {
  uniqueKey?: string;
}

function ProductCardLoading({ uniqueKey }: ProductCardLoadingPropsType) {
  return (
    <ContentLoader viewBox="0 0 180 240" uniqueKey={uniqueKey}>
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="180" />
      <rect x="6" y="196" rx="2" ry="2" width="40%" height="12" />
      <rect x="6" y="220" rx="2" ry="2" width="60%" height="14" />
    </ContentLoader>
  );
}

export default ProductCardLoading;
