import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const HomeIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 20 20", color = COLORS.White } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <defs>
        <clipPath id="a60mzjzepa">
          <path fill="none" d="M0 0H20V20H0z" transform="translate(0.02 0.07)" />
        </clipPath>
      </defs>
      <g clipPath="url(#a60mzjzepa)" transform="translate(-0.02 -0.07)">
        <path
          fill={color}
          d="M19.894 10.639l-9.242-8.486a.84.84 0 0 0-1.138 0L.271 10.694a.84.84 0 1 0 1.143 1.231l.891-.819v8.464a.945.945 0 0 0 .987.9h3.655v-6.21a.991.991 0 0 1 .991-.991h4.3a.991.991 0 0 1 .991.991v6.213H16.9a.949.949 0 0 0 .991-.9v-8.497l.87.8a.84.84 0 1 0 1.134-1.235z"
          transform="translate(0.022 -1.079)"
        />
      </g>
    </SVG>
  );
};

export default HomeIcon;
