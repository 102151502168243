import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Link as DefaultLink } from "react-router-dom";

import DefualtTypography from "components/Typography";

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 16px 16px 24px;
`;

export const ButtonLink = styled(Button)`
  position: relative;
  padding: 14px;
  background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  color: ${({ theme }) => theme.COLORS.Dark};
  box-shadow: none;
  text-transform: none;
  text-decoration: none;
`;

export const ButtonIconWrapper = styled.div`
  position: absolute;
  right: 10px;
`;

export const GridWrapper = styled.div`
  padding: 0 16px;
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.Dark};
  :visited {
    color: ${({ theme }) => theme.COLORS.Dark};
  }
`;

export const Typography = styled(DefualtTypography)`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled(Typography)`
  flex: 0 0 70%;
`;
