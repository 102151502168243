import { FC } from "react";

import { IconSize, SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const ErrorIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, ...rest } = props;

  return (
    <SVG className={size} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69806 17.3019 2.39999 12 2.39999C6.69807 2.39999 2.4 6.69806 2.4 12C2.4 17.3019 6.69807 21.6 12 21.6ZM10.4485 8.75147C9.9799 8.28284 9.2201 8.28284 8.75147 8.75147C8.28284 9.22009 8.28284 9.97989 8.75147 10.4485L10.3029 12L8.75147 13.5515C8.28284 14.0201 8.28284 14.7799 8.75147 15.2485C9.2201 15.7172 9.9799 15.7172 10.4485 15.2485L12 13.697L13.5515 15.2485C14.0201 15.7172 14.7799 15.7172 15.2485 15.2485C15.7172 14.7799 15.7172 14.0201 15.2485 13.5515L13.6971 12L15.2485 10.4485C15.7172 9.97989 15.7172 9.22009 15.2485 8.75147C14.7799 8.28284 14.0201 8.28284 13.5515 8.75147L12 10.3029L10.4485 8.75147Z"
      />
    </SVG>
  );
};

ErrorIcon.displayName = "ErrorIcon";

ErrorIcon.defaultProps = {
  size: IconSize.DEFAULT,
  viewBox: "0 0 24 24",
  color: COLORS.Primary,
};

export default ErrorIcon;
