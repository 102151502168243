import { FC, useEffect } from "react";
import QRCodeGenerator from "qrcode";

import useOrientation from "../../hooks/useOrientation";

interface QRCodeProps {
  code: string;
}

const QRCode: FC<QRCodeProps> = ({ code }) => {
  const { screenSize } = useOrientation();

  useEffect(() => {
    if (code) {
      const canvas = document.getElementById("canvas");

      if (canvas) {
        QRCodeGenerator.toCanvas(canvas, code, { width: screenSize.width });
      }
    }
  }, [code, screenSize]);

  return <canvas id="canvas" />;
};

export default QRCode;
