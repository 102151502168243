import styled from "styled-components";

import Typography from "components/Typography";

import { TextFieldPropsType } from ".";

import { COLORS } from "constants/color";

export const StyledTextField = styled.input<TextFieldPropsType>`
  padding: 14px 16px;
  flex: 1;
  border: none;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  outline: none;
  color: ${(props) => (props.error ? COLORS.Error : COLORS.Primary)};
  font-weight: ${(props) => (props.isBoldText ? "500" : "400")};
  ::placeholder {
    color: ${(props) => (props.error ? COLORS.Error : COLORS.DarkLight)};
  }
`;

export const InputAdornment = styled.div`
  margin: 0 5px;
`;

export const InputContainer = styled.div<TextFieldPropsType>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  overflow: hidden;
  color: ${(props) => (props.error ? COLORS.Error : COLORS.Primary)};
  border: ${(props) => {
    return props.isOutlined || props.error ? `1px solid ${props.error ? COLORS.Error : COLORS.LightBlue}` : "none";
  }};
  &:focus-within {
    border: ${(props) => {
      return props.isOutlined || props.error ? `1px solid ${props.error ? COLORS.Error : COLORS.Primary}` : "none";
    }};
  }
`;

export const HelperText = styled(Typography)`
  color: ${COLORS.Error};
`;
