const th = {
  qrCode: {
    cash: {
      total: "ยอดชำระ ",
      amount: "฿{{amount}}",
      description: {
        default: "กรุณาสแกน QR code เพื่อโอนเงิน",
        detail: "กรุณาบันทึกภาพหน้าจอ เพื่อชำระเงินผ่าน แอปพลิเคชันของธนาคาร",
        retry: "เกิดข้อผิดพลาดในการขอ QR code โปรดลองใหม่อีกครั้ง",
        process: "กำลังสร้าง QR code กรุณารอสักครู่",
      },
      retry: {
        label: "แตะเพื่อลองอีกครั้ง",
      },
      popup: {
        saveQRSuccess: "บันทึกรูปภาพลงในอุปกรณ์ของคุณแล้ว",
      },
      footer: {
        label: "กลับไปหน้าแชท",
      },
    },
  },
  mobileBanking: {
    type: {
      kPlus: {
        title: "KPLUS",
        description: "3-minute verification after pay",
      },
      kma: {
        title: "KMA",
        description: "3-minute verification after pay",
      },
      scbEasy: {
        title: "SCB easy",
        description: "3-minute verification after pay",
      },
    },
    footer: {
      button: {
        default: "CONTINUE",
        continue: "CONTINUE - ฿{{amount}}",
      },
    },
    error: {
      notSupportedDesktop: {
        title: "ไม่พร้อมใช้งานบนเดสก์ท็อป",
        description: "คุณสามารถเปิดหน้านี้ผ่าน Messenger เวอร์ชันสมาร์ทโฟนและแท็บเล็ต",
      },
    },
  },
  order: {
    unavialable: {
      label: "ไม่มีคำสั่งซื้อนี้",
      description: "คุณไม่สามารถทำการชำระเงินได้เนื่องจากไม่มีคำสั่งซื้อนี้",
    },
    expired: {
      label: "คำสั่งซื้อของท่านหมดอายุ",
      description: "กรุณาทำรายการใหม่ เพื่อดำเนินการชำระเงินให้สำเร็จ",
    },
  },
  success: {
    label: "ท่านได้ชำระเงินเรียบร้อย",
  },
  fail: {
    label: "การชำระเงินไม่สำเร็จ",
    description: "กรุณาลองใหม่อีกครั้ง",
  },
};

export default th;
