import { Badge as BadgeStyled, PromotionText } from "./styled";

interface BadgePropsType {
  children?: string | Element;
  size?: "S" | "M";
}

const Badge = ({ children, size = "M" }: BadgePropsType) => {
  return (
    <BadgeStyled size={size}>
      <PromotionText variant="white-title-5">{children}</PromotionText>
    </BadgeStyled>
  );
};

Badge.displayName = "Badge";

export default Badge;
