import { FC, InputHTMLAttributes, ReactNode } from "react";

import { StyledTextField, InputContainer, InputAdornment, HelperText } from "./styled";

export interface TextFieldPropsType extends InputHTMLAttributes<HTMLInputElement> {
  isOutlined?: boolean;
  isBoldText?: boolean;
  isSuccess?: boolean;
  normalColor?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  name?: string;
}

const TextField: FC<TextFieldPropsType> = (props) => {
  const { startAdornment, endAdornment, helperText, name, error, ...rest } = props;
  return (
    <>
      <InputContainer {...rest} error={error} className="mb-1">
        {startAdornment && <InputAdornment>{startAdornment}</InputAdornment>}
        <StyledTextField {...rest} error={error} data-testid={`${name}-textField-input-testid`} />
        {endAdornment && <InputAdornment>{endAdornment}</InputAdornment>}
      </InputContainer>
      <HelperText variant="accent-body-1" className="m-0" data-testid={`${name}-textField-helperText-testid`}>
        {helperText}
      </HelperText>
    </>
  );
};

export default TextField;
