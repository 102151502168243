import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const SearchIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 48 48", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <path
        className="cls-1"
        d="M38.61,35.13l8.6,8.61a2.46,2.46,0,1,1-3.34,3.61l-.13-.14-8.61-8.6a2.46,2.46,0,0,1,3.48-3.48Z"
      />
      <path
        className="cls-1"
        d="M19.66,0A19.67,19.67,0,1,0,39.33,19.66,19.66,19.66,0,0,0,19.66,0Zm0,34.41A14.75,14.75,0,1,1,34.41,19.66,14.74,14.74,0,0,1,19.66,34.41Z"
      />
    </SVG>
  );
};

export default SearchIcon;
