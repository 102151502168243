import { Grid } from "@material-ui/core";

import styled from "styled-components";

import Button from "components/Button";

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const Body = styled.div`
  height: calc((100 * var(--vh, 1vh)) - 63px);
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  height: calc((100 * var(--vh, 1vh)) - 63px);
  padding: 16px;
  overflow-y: auto;
`;

export const SuccessImage = styled.img`
  width: 100%;
  max-width: 200px;
`;

export const ItemWrapper = styled(Grid)`
  height: 70px;
  width: 100%;
  display: inline-flex;
  border: 1px solid rgb(224, 224, 224, 0.8);
  box-sizing: border-box;
  border-radius: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-clip: padding-box;

  img {
    width: 32px;
    height: 32px;
  }

  &.selected {
    background: linear-gradient(white, white) padding-box, linear-gradient(to right, #ff007a, #f4af46) border-box;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 8px;
  }
`;

export const FooterButton = styled(Button)`
  min-height: 40px;
`;

export const LogoWrapper = styled(Grid)`
  border-right: 1px solid rgb(224, 224, 224, 0.8);
`;

export const CheckImage = styled.img`
  width: 20px !important;
`;

export const SubmitButton = styled.button`
  display: none;
`;
