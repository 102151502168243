export enum ShippingMethod {
  EXPRESS = "EXPRESS",
  STANDARD = "STANDARD",
  ALL = "ALL",
  PICK_UP = "PICK_UP",
}

export enum ShippingMethodType {
  PICK_UP = "PICK_UP",
  DELIVERY = "DELIVERY",
}

export enum ShippingCalculateType {
  DISABLE = "DISABLE",
  DISTANCE_BASE_PRICE = "DISTANCE_BASE_PRICE",
  FIXED_PRICE = "FIXED_PRICE",
  LALAMOVE = "LALAMOVE",
  POSTAL_CODE_BASED_PRICE = "POSTAL_CODE_BASED_PRICE",
  SHIPPOP = "SHIPPOP",
  UNIT_PRICE = "UNIT_PRICE",
}

export enum ShippingRuleType {
  FIXED_PRICE = "FIXED_PRICE",
  PER_UNIT_PRICE = "PER_UNIT_PRICE",
  UNIT_PRICE = "UNIT_PRICE",
}

export type ShippingRuleSelectedProduct = {
  id: string;
};

export type ShippingRuleSettings = {
  amountThreshold: number;
  basePrice: number;
  fixedPrice: number;
  pricePerUnit: number;
};

export type ShippingRule = {
  id: string;
  projectId: string;
  method: ShippingMethod;
  type: ShippingRuleType;
  settings: ShippingRuleSettings;
  selectedProducts: ShippingRuleSelectedProduct[];
};

export type Shipping = {
  iconURL: string;
  description: string;
  maximumPrice: number;
  maxDuration: number;
  methodType: ShippingMethodType;
  minimumPrice: number;
  disabled: boolean;
  setting: string;
  calculateType: ShippingCalculateType;
  isReceiveOrderOutsideDeliveryTime: boolean;
  disabledDuration: boolean;
  pricePerUnit: number;
  minDuration: number;
  fixedPrice: number;
  method: ShippingMethod;
  disabledReason: string;
  isDisabledSchedulePickUp: boolean;
  isCODPayment: boolean;
};
