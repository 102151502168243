import { Alert as MUIAlert, AlertTitle as MUIAlertTitle } from "@material-ui/lab";
import styled from "styled-components";

export const StyledAlert = styled(MUIAlert)`
  &.MuiAlert-outlinedSuccess {
    border-color: ${(props) => props.theme.COLORS.Success};
    background-color: ${(props) => props.theme.COLORS.NudeGreen};
  }

  &.MuiAlert-outlinedWarning {
    border-color: ${(props) => props.theme.COLORS.InfoYellow};
    background-color: ${(props) => props.theme.COLORS.NudeOrange};
  }

  &.MuiAlert-outlinedInfo {
    border-color: ${(props) => props.theme.COLORS.DarkBlue};
    background-color: ${(props) => props.theme.COLORS.NudeDarkBlue};
  }

  &.MuiAlert-outlinedError {
    border-color: ${(props) => props.theme.COLORS.Red};
    background-color: ${(props) => props.theme.COLORS.NudeRed};
  }
`;

export const StyledAlertTitle = styled(MUIAlertTitle)`
  color: ${(props) => props.theme.COLORS.Dark};
  font-weight: 600;
`;
