import { FC } from "react";

import WarningIcon from "components/Icon/Icons/WarningIcon";

import CheckIcon from "components/Icon/Icons/CheckIcon";

import InfoIcon from "components/Icon/Icons/InfoIcon";

import ErrorIcon from "components/Icon/Icons/ErrorIcon";

import { IconSize } from "types/icon";

import { StyledAlert, StyledAlertTitle } from "./styled";

import { COLORS } from "constants/index";

interface AlertProps {
  severity?: "success" | "info" | "warning" | "error";
}

const iconMapping = {
  success: <CheckIcon color={COLORS.Success} size={IconSize.MEDIUM} />,
  warning: <WarningIcon color={COLORS.InfoYellow} size={IconSize.MEDIUM} />,
  info: <InfoIcon color={COLORS.DarkBlue} size={IconSize.MEDIUM} />,
  error: <ErrorIcon color={COLORS.Red} size={IconSize.MEDIUM} />,
};

export const Alert: FC<AlertProps> = (props) => {
  const { severity, children } = props;

  return (
    <StyledAlert
      className="w-100 flex align-items-center"
      variant="outlined"
      severity={severity}
      iconMapping={iconMapping}
    >
      <StyledAlertTitle className="m-0">{children}</StyledAlertTitle>
    </StyledAlert>
  );
};

Alert.displayName = "Alert";

Alert.defaultProps = {
  severity: "warning",
};

export default Alert;
