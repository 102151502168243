import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const CircleRemoveIcon: FC<SVGIconPropTypes> = (props) => {
  const { size, style, viewBox = "0 0 32 32", color = COLORS.Primary } = props;

  return (
    <SVG className={size} style={style} color={color} viewBox={viewBox}>
      <circle cx="16" cy="16" r="16" fill="#EFF1F5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0212 10.9787C20.6052 10.5628 19.9308 10.5628 19.5148 10.9787L16.0065 14.487L12.4982 10.9787C12.0802 10.5749 11.4157 10.5807 11.0048 10.9917C10.5938 11.4026 10.588 12.0671 10.9918 12.4851L14.5001 15.9934L10.9918 19.5017C10.7148 19.7692 10.6038 20.1653 10.7013 20.5378C10.7988 20.9103 11.0896 21.2012 11.4621 21.2987C11.8346 21.3962 12.2307 21.2851 12.4982 21.0081L16.0065 17.4999L19.5148 21.0081C19.9328 21.4119 20.5973 21.4061 21.0083 20.9952C21.4192 20.5842 21.425 19.9197 21.0212 19.5017L17.513 15.9934L21.0212 12.4851C21.4371 12.0691 21.4371 11.3947 21.0212 10.9787Z"
        fill={color}
      />
    </SVG>
  );
};

export default CircleRemoveIcon;
