import { ReactChild, FC } from "react";

import { HeaderWrapper } from "./styled";

type HeaderPropTypes = {
  children: ReactChild;
  withShadow?: boolean;
};

const Header: FC<HeaderPropTypes> = ({ children, withShadow = false }) => (
  <HeaderWrapper shadow={withShadow}>{children}</HeaderWrapper>
);

Header.displayName = "Header";

export default Header;
