import { FC } from "react";

import { SVGIconPropTypes } from "types/icon";

import { SVG } from "../styled";

import { COLORS } from "constants/index";

const PickupIcon: FC<SVGIconPropTypes> = ({ color = COLORS.Primary, size, style }) => {
  const defaultStyle = {
    width: "32px",
    height: "32px",
    backgroundColor: COLORS.PrimaryNude,
  };

  return (
    <SVG
      style={{ ...defaultStyle, ...style }}
      className={size}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <rect fill={COLORS.PrimaryNude} width="32" height="32" />
      <g>
        <rect fill="none" x="4" y="4" width="24" height="24" />
        <path
          className="cls-3"
          fill={color}
          d="M15.19,27.68a1.18,1.18,0,0,0,1.54,0c2.75-2.37,9-8.36,9-13.9a9.75,9.75,0,0,0-19.5,0C6.21,19.32,12.44,25.31,15.19,27.68ZM9.74,17.09a7.06,7.06,0,0,1,9.51-9.5,6,6,0,0,1,2.93,2.93A7.06,7.06,0,0,1,12.68,20,6.09,6.09,0,0,1,9.74,17.09Z"
        />
        <path
          className="cls-3"
          fill={color}
          d="M17,16.27a2.33,2.33,0,0,1,1.75-1.75,2.38,2.38,0,0,1,1.53.17V11a.73.73,0,0,0-.73-.73H12.1a.73.73,0,0,0-.72.73v6a.73.73,0,0,0,.73.73h5A2.38,2.38,0,0,1,17,16.27Zm-.58-3.37h-1.2a.24.24,0,0,1-.24-.24v-2h1.68v2A.24.24,0,0,1,16.42,12.9Z"
        />
        <path
          className="cls-3"
          fill={color}
          d="M20.21,15.23a1.83,1.83,0,1,0,.89,1.57A1.82,1.82,0,0,0,20.21,15.23Zm-.07,1.2-1,1a.17.17,0,0,1-.23,0l-.54-.54a.16.16,0,1,1,.23-.23l.42.43.85-.86a.17.17,0,0,1,.23,0A.15.15,0,0,1,20.14,16.43Z"
        />
      </g>
    </SVG>
  );
};

export default PickupIcon;
