import styled from "styled-components";

import { Grid } from "@material-ui/core";

import Button from "components/Button";

export const QRBox = styled(Grid)`
  width: 700px;
  height: auto;
  max-width: 700px;
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  box-sizing: border-box;
  border-radius: 10px;
`;

export const Header = styled.div`
  width: 100%;
  height: 55px;
  background-color: ${({ theme }) => theme.COLORS.ChathamsBlue};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  canvas {
    width: 400px !important;
    height: 400px !important;

    @media only screen and (max-width: 600px) {
      width: 225px !important;
      height: 225px !important;
    }

    @media only screen and (max-width: 320px) {
      width: 180px !important;
      height: 180px !important;
    }
  }
`;

export const QRWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RetryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  border-radius: 10px;

  height: 400px !important;
  width: 400px !important;

  @media only screen and (max-width: 600px) {
    height: 225px !important;
    width: 225px !important;
  }

  @media only screen and (max-width: 320px) {
    height: 180px !important;
    width: 100% !important;
  }
`;

export const FooterButton = styled(Button)`
  min-height: 40px;
`;

export const TotalWrapper = styled.div`
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 24px;
    line-height: 36px;
  }

  p + h1 {
    padding-left: 5px;
  }
`;

export const DownloadButton = styled(Button)`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.COLORS.Primary};
  position: fixed;
  right: 16px;
  bottom: 85px;
`;

export const PopupDetail = styled.div`
  text-align: center;

  img {
    width: 32px;
    height: 32px;
  }
`;

export const Retry = styled.div`
  cursor: pointer;
`;
