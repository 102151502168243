import { gql } from "@apollo/client";

export const PROJECT = gql`
  query {
    project {
      id
      name
      configuration
      facebookPageId
      facebookPageToken
      lineChannelId
      lineChannelSecret
      lineChannelAccessToken
      instagramBusinessId
      instagramBusinessToken
    }
  }
`;
