import styled from "styled-components";

import { Grid } from "@material-ui/core";

import Button from "components/Button";
import DefaultFooter from "components/Footer";
import Typography from "components/Typography";

export const Wrapper = styled(Grid)`
  padding: 16px;
  flex-wrap: nowrap;

  div:nth-child(2) {
    text-align: right;
  }
`;

export const CartTitleWrapper = styled(Grid)`
  div {
    display: flex;
    align-items: center;
  }

  div + h1 {
    margin-left: 14px;
  }
`;

export const ItemBox = styled(Grid)`
  padding: 16px;
  flex-wrap: nowrap;
  background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  border-top: 1px solid rgba(224, 224, 224, 0.5);
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 0;
    cursor: pointer;
  }

  &.hidden {
    display: none;
  }

  &.hidden {
    display: none;
  }
`;

export const Body = styled(Grid)<{ header: number; footer: number }>`
  position: relative;
  height: calc(
    100 * var(--vh, 1vh) - ${({ header, footer }) => header + 40 + footer}px
  ); // remove header , footer and add item bar
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Footer = styled(DefaultFooter)`
  height: 100%;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  padding: 16px;
  max-height: 44px;

  svg + h2 {
    margin-left: 10px;
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
`;

export const FooterDetail = styled(Grid)`
  height: 100%;

  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div:nth-child(1) {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    h4 + p {
      margin-left: 5px;
    }
  }

  div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const IconText = styled(Typography)`
  text-align: center;
  margin-top: 16px;
`;

export const EmptyCartWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalWrapper = styled(Grid)`
  &.hidden {
    display: none !important;
  }
`;

export const StickyWrapper = styled(Grid)`
  width: 100%;

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div:first-child {
    display: flex;
    justify-content: flex-start;
  }

  div:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Price = styled(Typography)`
  color: ${({ theme }) => theme.COLORS.LightWhite};
`;
