import { useState } from "react";

// This custom hook is for return some data from ref element
// you can add more return data
/* eslint-disable @typescript-eslint/no-explicit-any */
const useRefElement = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const refElement = (element: any) => {
    if (element) {
      setHeight(element?.clientHeight);
      setWidth(element?.clientWidth);
    }
  };
  return { refElement, width, height };
};

export default useRefElement;
