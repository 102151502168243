import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { GB_PRIME_PUBLIC_KEY, GB_PRIME_BASE_URL } from "config";

import { CreditCardForm } from "components/add-creditcard";

import MessengerService from "services/MessengerService";
import { getGBPrimeToken } from "services/GbprimeService";

import { isMobileDevice } from "utilities/common";

import useDebitCreditCard from "./hooks/useDebitCreditCard";

import DeeplePayLoading from "../DeeplePayLoading";
import ErrorOrder from "../ErrorOrder";
import SupportMobileDevice from "../SupportMobileDevice";

export const DebitCreditCard = () => {
  const { t } = useTranslation();
  const { orderId = "" } = useParams<{ orderId: string }>();
  const [isInitialPayment, setIsInitialPayment] = useState<boolean>(true);
  const { order, generateCreditCardCharge, creditCardChargeData, isReadyToCharge } = useDebitCreditCard(orderId);

  useEffect(() => {
    setTimeout(async () => {
      setIsInitialPayment(false);
    }, 2000);
  }, []);

  const isErrorOrder = order?.state && order.state !== "ORDER_PLACED";

  useEffect(() => {
    if (isReadyToCharge) {
      creditCardCharge();
    }
  }, [isReadyToCharge]);

  const handleClickBack = () => {
    MessengerService.closeWindow();
  };

  const creditCardCharge = () => {
    // eslint-disable-next-line
    // @ts-ignore
    document.getElementById("form-charge")?.submit();
  };

  const handleSubmit = async (value: Record<string, string>) => {
    const [month, year] = value?.validDate?.split(" / ");
    const body = {
      rememberCard: false,
      card: {
        number: value.cardNumber,
        expirationMonth: month,
        expirationYear: year,
        securityCode: value.cvv,
        name: value.cardHolderName,
      },
    };
    try {
      const result = await getGBPrimeToken(body);
      await generateCreditCardCharge({ variables: { token: result.data.card.token, orderId } });
    } catch (error) {
      console.log(error);
    }
  };

  if (isInitialPayment) {
    return <DeeplePayLoading />;
  }

  if (!isMobileDevice) {
    return <SupportMobileDevice />;
  }

  if (isErrorOrder) return <ErrorOrder />;

  return (
    <div>
      <CreditCardForm
        onSubmit={handleSubmit}
        onClickBack={handleClickBack}
        submitText={t("addCreditCard.form.label.save", { totalPrice: order?.generatePaymentAmount })}
      />
      <form
        name="form"
        action={`${GB_PRIME_BASE_URL}/v2/tokens/3d_secured`}
        method="POST"
        style={{ display: "none" }}
        id="form-charge"
      >
        publicKey: <input type="text" name="publicKey" value={GB_PRIME_PUBLIC_KEY} />
        <br />
        gbpReferenceNo:{" "}
        <input
          type="text"
          name="gbpReferenceNo"
          value={creditCardChargeData?.generateCreditCardCharge?.gbpReferenceNo}
        />
      </form>
    </div>
  );
};

export default DebitCreditCard;
