import { FC } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Category from "containers/category";
import Cart from "containers/cart";
import Error from "containers/error";
import Home from "containers/home";

import Product from "containers/product";
import Promotion from "containers/promotion";
import TopSelling from "containers/top-selling";
import QRCodePayment from "containers/payment/QRCode";
import CashQRPayment from "containers/payment/QRCode/CashQRPayment";

import { useTranslation } from "react-i18next";

import ErrorFallback from "containers/error/ErrorBoundary";

import { ErrorBoundary } from "react-error-boundary";

import { MobileBanking } from "containers/payment/MobileBanking";
import { DebitCreditCard } from "containers/payment/DebitCreditCard";

import EWallet from "containers/payment/EWallet";

import TrueMoneyWallet from "containers/payment/EWallet/TrueMoneyWallet";

import { isAllowCreditQR } from "utilities/page";

import { PAGE } from "constants/page";

const Router: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [, page, section] = location.pathname.split("/");

  let pageTitlePath = page;
  const sectionData = ["mobileBanking", "qrCode", "creditCard", "eWallet"];

  if (sectionData.includes(section)) {
    pageTitlePath = `${page}.${section}`;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Helmet>
        <title>{t(`common.page.${pageTitlePath || "category"}.title.label`)}</title>
      </Helmet>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path={PAGE.Product} component={Product} exact />
        <Route path={PAGE.EditProduct} exact>
          <Product isEditProduct />
        </Route>
        <Route path={PAGE.Cart} component={Cart} exact />
        <Route path={PAGE.Category} component={Category} exact />
        <Route path={PAGE.Home} component={Home} exact />
        <Route path={PAGE.Promotion} component={Promotion} exact />
        <Route path={PAGE.TopSelling} component={TopSelling} exact />
        <Route path={PAGE.QRCode} exact component={isAllowCreditQR ? QRCodePayment : CashQRPayment} />
        <Route path={PAGE.MobileBanking} exact component={MobileBanking} />
        <Route path={PAGE.DebitCreditCard} exact component={DebitCreditCard} />
        <Route path={PAGE.EWallet} exact component={EWallet} />
        <Route path={PAGE.TrueMoneyWallet} exact component={TrueMoneyWallet} />
        <Route path="*" component={Error} exact />
      </Switch>
    </ErrorBoundary>
  );
};

export default Router;
