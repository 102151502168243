import styled from "styled-components";
import MuiButton from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

export const Button = styled(MuiButton)`
  padding: 12px;
  max-height: 44px;
  border-radius: 5px;
  border: solid 0.8px ${({ theme }) => theme.COLORS.DarkLight};
  background-color: ${({ theme }) => theme.COLORS.LightWhite};
  text-transform: unset;

  &.active {
    border: solid 1px ${({ theme }) => theme.COLORS.Primary};
    background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
  }
`;

export const Container = styled(Grid)`
  margin-bottom: 8px;
`;
