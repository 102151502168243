import { useMutation } from "@apollo/client";

import get from "lodash/get";

import { GENERATE_TRUE_MONEY_WALLET } from "graphql/payment/mutation";

import { TrueMoneyWalletSchema } from "types/payment";

const useTrueMoneyWallet = (orderId: string) => {
  const [generateTrueWallet, { loading: loadingGenerateTrueMoneyWallet }] = useMutation(GENERATE_TRUE_MONEY_WALLET, {
    onError: (err) => console.log(err),
  });

  const generateTrueMoneyWallet = async () => {
    const data = await generateTrueWallet({
      variables: { orderId },
    });
    const result: TrueMoneyWalletSchema = await get(data, "data.generateTrueMoneyWallet");

    return result;
  };

  return {
    generateTrueMoneyWallet,
    loadingGenerateTrueMoneyWallet,
  };
};

export default useTrueMoneyWallet;
